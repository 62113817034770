import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from './locales/en.json'
import KO from './locales/ko.json'

Vue.use(VueI18n)

// var path = window.location.pathname.split('/');
// let lang = 'EN'
// let langs = ['EN', 'KO']
// if(path[1] !== ''){
//     langs.forEach((code)=> {
//         if(code === path[1]){
//             lang = path[1];
//         }
//     })
// }

export default new VueI18n({
    locale: 'EN',
    fallbackLocale: 'KO',
    messages: { EN, KO }
})