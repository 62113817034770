import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAos from 'vue-aos'
import AOS from 'aos'
import 'aos/dist/aos.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import './assets/css/reset.css'
import './assets/css/header.css'
import './assets/css/etc.css'
import './assets/css/style.css'
import './assets/css/footer.css'
import 'swiper/dist/css/swiper.css'

Vue.prototype.$Axios = axios;
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAos)

Vue.mixin({
  data() {
      return {
          ipCheckUrl: 'https://api.ipify.org?format=json',
          host: "https://media.myworld.network:10443/teaserserver/api/v1",
          subscribe_host: "https://www.myworld.network:5443/metawareWallet/api/v1",
          cdnUrl: "https://media.myworld.network:10443/teaserserver/upload",
          headers: {
              headers: {
                  Pragma: "no-cache",
                  "Cache-Control": "no-cache",
                  "Access-Control-Allow-Origin": "*",
                  "Content-type": "application/json",
              },
          },
      };
  },
  methods: {
    async getClientIP(ipCallback = null, errCallback = null){
      this.$Axios.get(this.ipCheckUrl, {}, this.headers).then(res => {
          // console.log(res.data);
          if(ipCallback) ipCallback(res.data.ip);
      })
      .catch((error) => {
        if(errCallback) errCallback(error);
      });
    }
  }
});

window.addEventListener('load', AOS.refresh);

new Vue({
  router,
  i18n,
  mounted(){
    AOS.init({
      offset:50,
      startEvent: 'load',
    })
  },
  render: h => h(App),
}).$mount('#app')
