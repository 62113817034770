import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/MintMarble.vue'
import TermsKo from '../views/TermsKo.vue'
import PrivacyKo from '../views/PrivacyKo.vue'
import TermsEn from '../views/TermsEn.vue'
import PrivacyEn from '../views/PrivacyEn.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload();
    });
};

Vue.use(VueRouter);

const routes = [
    {path:'*',          name: '404',            redirect: "/"},
    {path:'/',          name:'Index',           component: Index},
    {path:'/:section',  name:'IndexSection',    component: Index},
    {path:'/ko/policy/terms',  name:'TermsKo',    component: TermsKo},
    {path:'/ko/policy/privacy',  name:'PrivacyKo',    component: PrivacyKo},
    {path:'/en/policy/terms',  name:'TermsEn',    component: TermsEn},
    {path:'/en/policy/privacy',  name:'PrivacyEn',    component: PrivacyEn},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior () {
    //     return { x: 0, y: 0 }
    // }
});

export default router;