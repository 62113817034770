<template>
  <div id="app " :class="[{'en' : this.$i18n.locale === 'EN'},{'ko' : this.$i18n.locale === 'KO'}]">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
</style>
