<template>
  <div class="doc-content c14">
    <h1 class="c20" id="h.ea9an5k74l2o">
      <span class="c11">Terms of Service</span>
    </h1>
    <p class="c0">
      <span class="c4"
        >These Terms of Use (&ldquo;Terms&rdquo;) govern your access and use of
        Mintmarble App, and related programs, software and more
        (&ldquo;Services&rdquo;) of Metaware PTE, Ltd. (the
        &ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or
        &ldquo;our&rdquo;), and any information, text, graphics, photos or other
        materials uploaded, downloaded or appearing on the Service (collectively
        referred as &quot;Content&quot;) referencing these Terms. You may not
        use Mintmarble unless you agree to them, so please read carefully.
        Before using the Service, you are required to read, understand and agree
        to these terms. You may only access the Service after reading and
        accepting these Terms of Use. Mintmarble &nbsp;is not for children. Do
        not attempt to access the app if you are a child under the age of 13 or
        the age of consent in your respective country.</span
      >
    </p>
    <h3 class="c6" id="h.blnonz3bmbyc">
      <span class="c7">1. Definition of Terms</span>
    </h3>
    <p class="c10">
      <span>The Terms herein shall be defined as follows :</span>
    </p>
    <p class="c10">
      <span class="c4"
        >1) &ldquo;User&rdquo; refers to a person who is using the Service with
        the procedures required by the Company.</span
      >
    </p>
    <p class="c10">
      <span>2)</span
      ><span class="c15"
        >&ldquo;Digital Asset&rdquo; refers not limited to blockchain technology
        but all blockchain based items or data including cryptocurrency, game
        token, coin, NFT and other derivative digital assets etc.</span
      >
    </p>
    <p class="c10">
      <span
        >3) &ldquo;NFT(Non-fungible Token)&rdquo; refers to any digital asset on
        blockchain that is not mutually interchangeable with other tokens.
        Within the Service, certain in-game items are provided as NFT.</span
      >
    </p>
    <p class="c10">
      <span class="c8"
        >4) &ldquo;Cryptocurrency&rdquo; refers to coin (cryptocurrency issued
        based on independent blockchain network mainnet) and token
        (cryptocurrency issued on mainnet such as open public chain without
        independent blockchain network mainnet).</span
      >
    </p>
    <p class="c10">
      <span class="c8"
        >5) &ldquo;Decentralized wallet&rdquo; refers to a digital asset wallet
        in which there is no centralized entity managing the digital asset
        wallet, and the individual wallet owner manages the wallet. Only the
        owner of the decentralized wallet can sign the transaction of the
        blockchain network with a private key.</span
      >
    </p>
    <p class="c10">
      <span class="c8"
        >6) &ldquo;Private Key&rdquo; refers to a code (random combination of
        number and text) that is 1:1 matched with the Decentralized
        Wallet&rsquo;s address and it is an important key to process all
        signatures of the Decentralized Wallet. Since the private key has a
        power of signature, it can be used by the third party when exposed
        externally. Extra care is required for the management.</span
      >
    </p>
    <p class="c10">
      <span class="c15"
        >7) &ldquo;Wallet authentication&rdquo; refers to an authentication
        method through a decentralized wallet managed by an individual to
        specify the subject requesting the transfer of digital assets used
        within the Mintmarble App to the blockchain network. Since the Company
        does not manage the Decentralized wallet information, the Company links
        the game account information one-to-one with a decentralized wallet
        managed by the Users.</span
      >
    </p>
    <h3 class="c6" id="h.fqnc3etnnxj">
      <span class="c7">2. &nbsp;Your Agreement with the Company</span>
    </h3>
    <p class="c0">
      <span>1) Governing Law and Jurisdiction:</span
      ><span class="c8"
        >These Terms of Service shall be governed by Singapore law and shall be
        interpreted, governed and construed accordingly. User consents to
        designate the courts of Singapore as the competent court for any
        disputes or lawsuits arising in relation to these Terms of Service or
        Privacy Policy.</span
      >
    </p>
    <p class="c0">
      <span
        >2) Disclaimer: You and the Company each disclaim the U.N. Convention on
        Contracts for International Sale of Goods. We both agree it does not
        apply to the Services.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >3) Any information that you provide to the Company is subject to our
        Privacy Policy, which governs our collection and use of your
        information. You understand that through your use of the Services you
        consent to collection and use (as set forth in the Privacy Policy
        pursuant to Applicable Law) of this information. As part of providing
        the Services, we may need to provide you with certain communications,
        such as service announcements and administrative messages. These
        communications are considered as part of the Services, which you may not
        be able to opt-out from receiving.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) If it is not appropriate for the Company to accept signing up due to
        reasons such as mechanical access to the Mintmarble service system or
        account theft, provision of false information, and verification of
        &#39;service&#39; abusing details, the Company may suspend or reject the
        &#39;applicant&#39; signing up or terminate the service contract after
        death and may restrict the use of the service for a certain period of
        time if necessary.</span
      >
    </p>
    <h3 class="c6" id="h.8cdcfokmq0v">
      <span class="c7">3. Posting and Amending Terms</span>
    </h3>
    <p class="c0">
      <span class="c2"
        >The Company, if deemed necessary, can modify these Terms of Service to
        the extent that it does not violate the relevant laws and regulations.
        Any modified contents shall be announced or notified by Company via
        electronic methods, including, but not limited to : email address,
        electronic notice in the Service or pop-up message. Please note that a
        user acknowledges and accepts that these Terms of Service and/or the
        Service may be amended, modified, or altered at any time without a
        notice at discretion of the Company.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >Continued use of the Service after any modifications of these Terms of
        Service shall constitute the users consent and acceptance of any such
        changes, modifications, or alterations. If a user does not agree to the
        revised Terms of Service, the user may terminate the Service Agreement
        through discontinuation of use of Service at any time. The date of the
        most recent modifications will be indicated at the top of these Terms of
        Service.</span
      >
    </p>
    <h3 class="c6" id="h.va5jruh69482">
      <span class="c7">4. User&rsquo;s Consent and Negligence</span>
    </h3>
    <p class="c16">
      <span class="c3"
        >By using the Company&rsquo;s Service, user agree with following
        conditions under the applicable law :</span
      >
    </p>
    <ol class="c9 lst-kix_nxknj8st7osf-0 start" start="1">
      <li class="c5 c18 li-bullet-0">
        <span class="c2"
          >User has all necessary knowledge to deal with digital items and/or
          Blockchain-based systems, have a full understanding of their
          framework, are aware of all the merits, risks and any restrictions
          associated with digital assets.</span
        >
      </li>
      <li class="c18 c5 li-bullet-0">
        <span class="c2"
          >User &nbsp;will neither use the Service for any illegal activity nor
          shall be engaged in any illegal activity.</span
        >
      </li>
      <li class="c18 c5 li-bullet-0">
        <span class="c2"
          >A user solely controls their credentials (email address, password or
          other information provided for the purpose of the Service use) and
          does not act on behalf of any third party.</span
        >
      </li>
      <li class="c18 c5 li-bullet-0">
        <span class="c2"
          >To provide better Service, the Company may display in the Service
          Page or send out via email various information including notices,
          admin messages and other advertisements regarding use of the Service.
          If a user does not wish to receive such email, please contact our
          customer service at help@metaware.ltd.</span
        >
      </li>
    </ol>
    <h3 class="c6" id="h.hkjmknbc5i9f"><span class="c7">5. Warranty</span></h3>
    <p class="c12">
      <span class="c2"
        >THE SERVICE MAY CONTAIN CONTENTS OF THE COMPANY&rsquo;S PARTNERS,
        VENDORS, GAME DEVELOPERS, ETC. AND/OR LINKS TO THIRD-PARTY WEBSITES AND
        SERVICES. SUCH SERVICES AND/OR LINKS ARE PROVIDED FOR USERS&rsquo;
        CONVENIENCE, AND THE COMPANY DOES NOT GUARANTEE, SUGGEST OR IMPLY THE
        SAFETY OF ANY THIRD-PARTY WEBSITE OR THE LEGALITY OR CONFORMITY OF ANY
        SUCH THIRD-PARTY SERVICE.</span
      >
    </p>
    <p class="c12">
      <span class="c3"
        >THE COMPANY IS NOT RESPONSIBLE FOR MAINTAINING ANY MATERIALS REFERENCED
        FROM ANOTHER SITE, AND MAKES NO WARRANTIES, NOR ENDORSEMENT FOR THAT
        SITE OR RESPECTIVE SERVICE. THE COMPANY ASSUMES NO OBLIGATIONS IN THE
        EVENT OF ANY DAMAGE OR LOSS, OR ANY OTHER IMPACT, DIRECTLY OR INDIRECTLY
        RESULTING FROM THE USE OF ANY CONTENT, GOODS OR SERVICES AVAILABLE ON OR
        THROUGH ANY SUCH THIRD-PARTY SERVICES AND RESOURCES. PLEASE ALSO NOTE
        THAT THESE SERVICES MAY HAVE THEIR OWN POLICIES. THE COMPANY IS NOT
        RESPONSIBLE NOR LIABLE IN ANY WAY TO YOU OR ANY THIRD PARTY IN RESPECT
        OF THOSE POLICIES.</span
      >
    </p>
    <h3 class="c6" id="h.gsos2fpq3eus"><span class="c7">6. Updates</span></h3>
    <p class="c12">
      <span class="c2"
        >The Company may deploy or provide patches, updates, upgrades, content
        or other modifications to the Services for reasonable purposes. These
        updates may result in the Services being temporarily unavailable. In
        certain circumstances, we may suspend, withdraw or disable the Service
        for longer periods while we deploy these updates.</span
      >
    </p>
    <p class="c12">
      <span class="c3"
        >Service suspension due to any of the causes shall be notified or
        announced through email or pop-up message from the Service. Any causes
        that the Company is unable to predict, nor control shall be an exception
        of prior notification or announcement. However, even for such
        exceptional causes, the Company shall exert its utmost effort to recover
        the Service at the earliest possible time.</span
      >
    </p>
    <h3 class="c6" id="h.vquvtspaniok">
      <span class="c7">7. Mintmarble IP Content and User Content</span>
    </h3>
    <p class="c0">
      <span class="c4"
        >1) &quot;Mintmarble IP(Intellectual Property) Content&quot; includes
        all works (world, avatar, item, video, image, game, text, story,
        graphics, music, live stream, etc.) created by the company to constitute
        and operate the Service. (intended for visually verifiable materials and
        excludes programs and software which are used directly or indirectly in
        information processing devices such as computers). The copyright,
        trademark, trade dress, patent, and/or other intellectual property
        rights of any materials contained in the Service, including the
        selection and arrangement of the &quot;Mintmarble IP Content&quot;, are
        owned by the Company and are protected by applicable laws.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >2) The Services consist of interactive features and areas which allow
        users to create, post, transmit, and/or store content, including but not
        limited to photos, videos, text, graphics, items, or other materials
        (collectively, &quot;User Content&quot;) using &ldquo;Mint Marble IP
        Content&rdquo;. The Company may suggest or recommend
        &ldquo;Contents&rdquo; or features that users may be interested in based
        on activities in Mint Marble, in order to promote active mutual
        interaction among users. We may also suggest certain ways to experience
        Mint Marble for users. But of course, users can restrict access to their
        &ldquo;User Content&rdquo; to certain specific people in their personal
        settings.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >3) You also understand that your User Content may be viewable by others
        and that you have the ability to control who can access such content by
        adjusting your privacy settings. If User Content has been uploaded to
        Mint Marble, our staff is able to see it. We endeavor to keep your
        private content confidential, but please keep in mind that no technology
        is 100% secure, and it is possible that unauthorized third parties can
        violate our security measures and access your private contents. You also
        agree to abide by our Community Guidelines, which may be updated from
        time to time.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) All copyrights and other intellectual property rights for &quot;user
        content&quot; belong to the creator. However, &quot;user content&quot;
        must be created or produced based on &quot;Mint Marble IP content&quot;,
        and must be used without violating the company&#39;s guidelines.
        Especially when &quot;user content&quot; is used outside of the Mint
        Marble platform, please make sure it adheres to the &quot;Mint Marble
        User Generated Content Usage Guideline.&quot; Copyrights and other
        intellectual property rights for &quot;Mint Marble IP content&quot;
        still belong to the company, and all data usage fees from the services
        are the user&#39;s responsibility.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >5) When posting &quot;user content&quot; on Mint Marble, the user is
        granting the Company the rights to use the content (including face
        images, photos, videos for machine learning and R&amp;D purposes for
        Metaware Corp itself or with affiliates) in all current and future media
        and distribution platforms. Similarly, the user grants other users the
        right to use &quot;user content&quot; on the Mint Marble platform
        internally and externally as long as the usage is in accordance with the
        guidelines provided by the Company. In this case, users can limit the
        visibility of the user content with the privacy settings.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >6) The user is solely responsible for the &quot;user content&quot; and
        any claims arising therefrom, and the Company is not responsible at all.
        Although not obligated, the company may review, manage and delete User
        content at any time at its sole discretion.</span
      >
    </p>
    <h3 class="c6" id="h.vx4i4rmgpkrg">
      <span class="c7">8. Prohibited Activities</span>
    </h3>
    <p class="c0">
      <span
        >You agree to refrain from behave in following manner along with
        restrictions included in this Terms of Use. Otherwise explicitly allowed
        by in this Terms of Use, using Mint Marble IP Contents and services
        without prior agreement from the Company is strictly prohibited and if
        there are such cases, the rights to use the license which was given from
        this Terms of Use terminates.</span
      >
    </p>
    <ul class="c9 lst-kix_17xqm2t3a9ux-0 start">
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Use the Services for any purpose that is illegal, beyond the scope of
          their intended use, or otherwise prohibited in these Terms or terms of
          any third party which govern a particular Service;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Use the Services in any manner that could interfere with, disrupt,
          negatively affect, or inhibit other users from fully enjoying the
          Services, or that could damage, disable, overburden, or impair the
          functioning of the Services in any manner;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4">Compromise the security of the Services;</span>
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Send any unsolicited or unauthorized advertising, spam,
          solicitations, or promotional materials;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Use any robot, spider, crawler, scraper, or other automated means or
          interface not provided by us to access the Services or to extract
          data;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Reverse engineer any aspect of the Services or do anything that might
          lead to the discovery of source code or bypass or circumvent measures
          applied to prevent or limit access to any area, content, or code of
          the Services;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Use or attempt to use another user&rsquo;s account without
          authorization;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Attempt to circumvent any content-filtering techniques we apply, or
          attempt to access areas or features of the Services that you are not
          authorized to access;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Downloading (other than page caching) any portion of the Services,
          Mint Marble IP Content, or any information contained therein, except
          as expressly permitted on the Services;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Accessing the Mint Marble API with an unauthorized or third-party
          client;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Attempt to indicate in any manner that you have a relationship with
          us or that we have endorsed you or any products or services without
          our express written consent to do so;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Engage in any harassing, intimidating, predatory conduct, or
          stalking;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Impersonate any person or entity or otherwise misrepresent your
          affiliation with a person or entity;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Violate publicity, privacy, or data-protection rights of others,
          including by taking pictures of another individual without receiving
          their consent;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Infringe any patent, trademark, trade secret, copyright, or other
          intellectual or proprietary right of any party;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Buy, sell, rent, lease, or otherwise offer in exchange for any
          compensation, access to your Mint Marble account, messages, a Mint
          Marble username, or a friend link without Mint Marble&rsquo;s prior
          written consent;</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Develop any third-party applications which interact with User Content
          or the Services without our prior written consent; and</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Use the Services for any illegal or unauthorized purpose or engage
          in, encourage, or promote any activity that violates these
          Terms.</span
        >
      </li>
    </ul>
    <h3 class="c6" id="h.3p6g5khjj25r">
      <span class="c7">9. Your License to use the Services</span>
    </h3>
    <p class="c0">
      <span
        >The Company gives you a personal, worldwide, royalty-free,
        non-assignable and non-exclusive license to use the software which is
        provided to you by the Company as part of the Services. This license is
        for the sole purpose of enabling you to use and enjoy the benefit of the
        Services as provided by the Company, in a manner permitted by these
        Terms.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >1) Account Information. Before accessing or using the Service, you must
        create an account or login through third party platforms with the
        procedures required by the Company. It is your responsibility to keep
        your login credentials secure and you are held responsible by the
        Company for all activities that occur via your account.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >You agree that you will not copy, reproduce, republish, frame,
        download, transmit, modify, display, reverse engineer, sell, or
        participate in any sale, rent, lease, loan, assign, distribute, license,
        sublicense, or exploit in any way, in whole or in part of Mint Marble
        Services, or any related program and software.</span
      >
    </p>
    <h3 class="c6" id="h.lxe568ae8l0h">
      <span class="c7">10. Community Guidelines</span>
    </h3>
    <p class="c0">
      <span class="c4"
        >Our goal in creating these rules is to accommodate the broadest range
        of self-expression while balancing the need for Mint Marble users to be
        able to use our service safely and enjoyably.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Do not send messages to people who do not want to receive messages
        which are especially mean.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Be mindful of what you send in a message and who you send it to. It is
        okay with us if someone takes a screenshot, but we cannot speak for you
        or your friends. Mint Marble attempts to detect screenshots and notify
        the sender, but it does not always work perfectly - and your friends can
        always capture an image with a camera.</span
      >
    </p>
    <p class="c0">
      <span
        >Keep it legal. Do not use Mint Marble for any illegal shenanigans, and
        if you are messaging with someone who might be, you should not view or
        send the following contents.</span
      >
    </p>
    <ul class="c9 lst-kix_b8h2xkbwvt0r-0 start">
      <li class="c0 c5 li-bullet-0"><span class="c4">Pornography</span></li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Nudity or sexually suggestive content involving minors (people under
          18 years old)</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4"
          >Minors engaged in activities that are physically dangerous and
          harmful</span
        >
      </li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4">Invasions of privacy</span>
      </li>
      <li class="c0 c5 li-bullet-0"><span class="c4">Threats</span></li>
      <li class="c0 c5 li-bullet-0">
        <span class="c4">Harassment or Bullying</span>
      </li>
      <li class="c0 c5 li-bullet-0"><span class="c4">Impersonation</span></li>
      <li class="c0 c5 li-bullet-0"><span class="c4">Self-Harm</span></li>
    </ul>
    <p class="c0">
      <span class="c4"
        >Violating these rules may result in the removal of User Content,
        suspension of your account and being prohibited from using the Service
        in the future. Please take these rules seriously and respect them in the
        spirit in which they are intended. The rules will change and evolve
        along with the service user community. We will do our best to enforce
        them consistently and fairly, and ultimately we will try to do what we
        think is best in each situation, at our own discretion.</span
      >
    </p>
    <h3 class="c6" id="h.b19d0348zze4"><span class="c7">11. Feedback</span></h3>
    <p class="c0">
      <span
        >You agree that any feedback, suggestions, ideas, or other information
        or materials regarding the Company or the Services which you provide,
        whether by email or otherwise (&quot;Feedback&quot;), are
        non-confidential and shall become sole property of the Company. We will
        be entitled to unrestricted use and dissemination of such Feedback for
        any purpose, commercial or otherwise, without acknowledging or
        compensating you. You waive any rights you may have to Feedback
        (including any copyrights or moral rights). We like hearing from
        users.</span
      >
    </p>
    <h3 class="c6" id="h.soptcm3sfpgi">
      <span class="c7">12. The Company Rights</span>
    </h3>
    <p class="c0">
      <span
        >All right and title, to the Services and interest in (excluding Content
        provided by users) is and will remain exclusive property of the Company
        and its licensors. The Services are protected by copyright, trademark,
        and other laws. Nothing in the Terms gives you the right to use the name
        of the Company or any of the Company trademarks, logos, domain names, or
        other distinctive brand features. Any feedback, comments, or suggestions
        you may provide regarding the Company, or the Services is entirely
        voluntary and we will be free to use such feedback, comments or
        suggestions as we consider to be appropriate without having any
        obligation to inform you.</span
      >
    </p>
    <h3 class="c6" id="h.druwnemj7c2b">
      <span class="c7">13. Publishing Advertisements</span>
    </h3>
    <p class="c0">
      <span
        >In association with the Service operation of the Company, we may place
        advertisements directly on the Service webpage, through affiliated third
        parties.</span
      ><span class="c4">.</span>
    </p>
    <h3 class="c6" id="h.5ffyrogqkvhv">
      <span class="c7">14. Copyright Policy</span>
    </h3>
    <p class="c0">
      <span class="c4"
        >1) If your content contains information which violates applicable laws,
        you may request suspension or deletion of relevant &quot;post&quot; in
        accordance with procedures determined by applicable law, and the Company
        will take measures in accordance with applicable law.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >2) We may take provisional measures against the contents in accordance
        with related law if there is a reason for recognition of an infringement
        of rights or if there is a violation of another company&rsquo;s policies
        and related laws, even without the request by right holder pursuant to
        the preceding paragraph, and without any liability. In appropriate
        circumstances, the Company will also terminate user&rsquo;s account if
        the user is determined to infringe repeatedly.</span
      >
    </p>
    <p class="c0">
      <span
        >3) Detailed procedures under this Article will be subject to request of
        posting suspension service provided by the Company within the scope
        applicable law.</span
      >
    </p>
    <h3 class="c6" id="h.skjyzyro2hdq">
      <span class="c7">15. Our Disclaimer for Warranties</span>
    </h3>
    <p class="c0">
      <span class="c4"
        >1) You acknowledge and agree that by accessing or using the Services,
        you may be exposed to materials from others which you may consider
        offensive, indecent, or otherwise objectionable, and agree to accept
        such risk. Views expressed on our website or through our Services do not
        necessarily reflect our views. We do not support or endorse certain
        content posted by you or other users.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >2) Although we do what we can to provide security measures to protect
        your content, we are not liable for any damages resulting from
        disclosure of your Content.</span
      >
    </p>
    <p class="c0">
      <span
        >3) Disclaimer for Warranties: YOU EXPRESSLY UNDERSTAND AND AGREE THAT,
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES,
        WEBSITE, APPLICATION AND CONTENT AND THE COMPANY MATERIALS ARE PROVIDED
        TO YOU &ldquo;AS IS,&rdquo; WITHOUT WARRANTY OF ANY KIND, EXPRESS,
        IMPLIED, STATUTORY, OR OTHERWISE. FOR EXAMPLE, WE MAKE NO WARRANTY THAT
        (a) THE SERVICES OR MATERIALS WILL MEET YOUR REQUIREMENTS OR WILL BE
        CONSTANTLY AVAILABLE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (b)
        THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE, SERVICES, OR
        MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; OR THAT (c) ANY
        ERRORS OR DEFECTS IN THE SITE, SERVICES, OR MATERIALS WILL BE
        CORRECTED.</span
      >
    </p>
    <h3 class="c6" id="h.9sdd80bnjcnt">
      <span class="c7">16. Our Limitation on Liability</span>
    </h3>
    <p class="c0">
      <span
        >Except where it is prohibited by law, in no event will the Company OR
        ITS SUPPLIERS, OR THEIR RESPECTIVE EXECUTIVES, DIRECTORS, EMPLOYEES, OR
        AGENTS (The &ldquo;Company Parties&rdquo;) be liable for any indirect,
        special, punitive, incidental, exemplary, or consequential damages which
        result from (a) the use of, or inability to use, the Services; (b)
        provision of the Services or any materials available therein; or (c) the
        conduct of other users of the Services.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >2) The Company is not responsible when users did not earn expected
        profit or loss while using the Service.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >3) The Company is not responsible for the price fluctuations of digital
        assets etc. of the user.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) The Company is not responsible for issues occurring with personal
        information management such as 3rd party platform login information,
        private key, recovery phrase etc. due to negligence of the user.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >5) &#48660;&#47197;&#52404;&#51064; &#48143; &#46356;&#51648;&#53560;
        &#51088;&#49328; &#44288;&#47144; &#44508;&#51228;&#50752;
        &#51221;&#52293;&#51008; &#54788;&#51116; &#48520;&#50756;&#51204;
        &#48143; &#48520;&#54869;&#49892;&#54616;&#47728;, &#44288;&#47144;
        &#44508;&#51228;&#45208; &#51221;&#52293;&#51032;
        &#48320;&#46041;&#51060; &#54924;&#49324;&#44032;
        &#51228;&#44277;&#54616;&#45716; &#49436;&#48708;&#49828;&#50640;
        &#50689;&#54693;&#51012; &#48120;&#52832; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.s9bklvx9bty3">
      <span class="c7">17. Service Termination</span>
    </h3>
    <p class="c0">
      <span class="c4">1) You can stop using our Services at any time.</span>
    </p>
    <p class="c0">
      <span class="c4"
        >2) Upon withdrawal, all information related to the Service is deleted
        and cannot be recovered. However, due to technological characteristics
        of Blockchain, data related to Blockchain cannot be deleted.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >3) We may add, modify, or remove features or functionalities, and we
        may temporarily or permanently suspend or stop the Service. We may also
        add or create new limits to our Services at any time.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) In addition, termination of your account does not free you from any
        obligations of payment.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) If the Service is terminated or discontinued, then we will make a
        reasonable effort to notify you and provide an opportunity to retrieve
        your content.</span
      >
    </p>
    <h3 class="c6" id="h.913d3tab1h49">
      <span class="c7">18. General Terms</span>
    </h3>
    <p class="c0">
      <span>1) Language:</span
      ><span class="c2"
        >Users agree that even in the event that the translated version of the
        English Terms of Service is provided, the translated version is provided
        only for convenience of the user, and solely the English Terms of
        Service shall apply to the relation between the user and the Company. In
        the event of discrepancies between the English Terms of Service and any
        translated version of the Terms of Service, the English Terms of Service
        shall be prioritized.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >2) Severability: Even if a court finds any section of the Terms invalid
        or unenforceable, the rest of the Terms still apply.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >3) No Waiver: Even if we do not enforce (or we delay enforcement) of
        the Terms against you, we have not waived our enforcement rights.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >4) Assignment or Transfer: You cannot assign or transfer your rights or
        obligations under this agreement to someone else without the
        Company&rsquo;s written permission. We can transfer our rights and
        obligations to you without your permission unless we are acquired by or
        merge with another company, sell one of the Services, or
        otherwise.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >5) Complete Agreement. These Terms are an entire, exclusive agreement
        between the Company and you regarding the Services, and these Terms
        supersede and replace any prior agreements between the Company and you
        regarding the Services. We may revise these Terms from time to time. If
        the revision in our sole discretion is material, we will notify you via
        banner notice across its page or other provided appropriate means. By
        continuing to access or use the Services after those revisions become
        effective, you agree to be bound by revised Terms.</span
      >
    </p>
    <h4 class="c13" id="h.6gq89lngpneo"><span class="c1">Addendum</span></h4>
    <p class="c0">
      <span>&#9312; These Terms will be effective from July 12th, 2023.</span>
    </p>
    <p class="c12 c17"><span class="c4"></span></p>
    <p class="c12 c17"><span class="c19"></span></p>
    <p class="c12 c17"><span class="c19"></span></p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ol.lst-kix_nxknj8st7osf-2 {
  list-style-type: none;
}
.lst-kix_17xqm2t3a9ux-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_17xqm2t3a9ux-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_nxknj8st7osf-3 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-0 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-2.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-2 0;
}
ol.lst-kix_nxknj8st7osf-1 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-6 {
  list-style-type: none;
}
.lst-kix_17xqm2t3a9ux-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_17xqm2t3a9ux-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_17xqm2t3a9ux-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_nxknj8st7osf-7 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-4 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-5 {
  list-style-type: none;
}
.lst-kix_17xqm2t3a9ux-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_17xqm2t3a9ux-6 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_nxknj8st7osf-8 {
  list-style-type: none;
}
.lst-kix_k5p9mtk303is-8 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-8;
}
ol.lst-kix_nxknj8st7osf-5.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-5 0;
}
.lst-kix_nxknj8st7osf-1 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-1;
}
.lst-kix_k5p9mtk303is-7 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-7;
}
.lst-kix_nxknj8st7osf-3 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-3;
}
.lst-kix_17xqm2t3a9ux-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_17xqm2t3a9ux-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_17xqm2t3a9ux-0 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-1 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-2 {
  list-style-type: none;
}
.lst-kix_nxknj8st7osf-7 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-7, lower-latin) ". ";
}
.lst-kix_k5p9mtk303is-5 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-5;
}
ol.lst-kix_k5p9mtk303is-8.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-8 0;
}
.lst-kix_nxknj8st7osf-6 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-6, decimal) ". ";
}
.lst-kix_nxknj8st7osf-3 > li:before {
  content: "(" counter(lst-ctn-kix_nxknj8st7osf-3, decimal) ") ";
}
.lst-kix_nxknj8st7osf-5 > li:before {
  content: "(" counter(lst-ctn-kix_nxknj8st7osf-5, lower-roman) ") ";
}
.lst-kix_nxknj8st7osf-4 > li:before {
  content: "(" counter(lst-ctn-kix_nxknj8st7osf-4, lower-latin) ") ";
}
.lst-kix_nxknj8st7osf-0 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-0;
}
.lst-kix_nxknj8st7osf-1 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-1, lower-latin) ") ";
}
.lst-kix_nxknj8st7osf-2 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-2, lower-roman) ") ";
}
.lst-kix_nxknj8st7osf-4 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-4;
}
.lst-kix_nxknj8st7osf-0 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-0, decimal) ") ";
}
.lst-kix_phowhkxbgav0-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_phowhkxbgav0-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_phowhkxbgav0-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_phowhkxbgav0-3 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_nxknj8st7osf-4.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-4 0;
}
ol.lst-kix_k5p9mtk303is-3.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-3 0;
}
ul.lst-kix_phowhkxbgav0-8 {
  list-style-type: none;
}
.lst-kix_b8h2xkbwvt0r-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_phowhkxbgav0-1 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-2 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-0 {
  list-style-type: none;
}
.lst-kix_k5p9mtk303is-2 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-2;
}
ol.lst-kix_k5p9mtk303is-3 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-3 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-0 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-2 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-1 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-5 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-6 {
  list-style-type: none;
}
.lst-kix_nxknj8st7osf-8 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-8;
}
ul.lst-kix_phowhkxbgav0-4 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-7 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-7 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-4 {
  list-style-type: none;
}
ul.lst-kix_phowhkxbgav0-6 {
  list-style-type: none;
}
ol.lst-kix_k5p9mtk303is-5 {
  list-style-type: none;
}
.lst-kix_phowhkxbgav0-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_phowhkxbgav0-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_k5p9mtk303is-8 {
  list-style-type: none;
}
.lst-kix_phowhkxbgav0-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_phowhkxbgav0-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_phowhkxbgav0-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_b8h2xkbwvt0r-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_nxknj8st7osf-7 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-7;
}
ol.lst-kix_k5p9mtk303is-7.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-7 0;
}
.lst-kix_k5p9mtk303is-1 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-1, lower-latin) ". ";
}
.lst-kix_k5p9mtk303is-2 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-2, lower-roman) ". ";
}
.lst-kix_k5p9mtk303is-1 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-1;
}
ol.lst-kix_k5p9mtk303is-2.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-2 0;
}
.lst-kix_k5p9mtk303is-0 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-0, decimal) ". ";
}
ol.lst-kix_k5p9mtk303is-4.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-4 0;
}
ol.lst-kix_nxknj8st7osf-3.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-3 0;
}
.lst-kix_b8h2xkbwvt0r-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_k5p9mtk303is-4 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-4;
}
ul.lst-kix_b8h2xkbwvt0r-6 {
  list-style-type: none;
}
.lst-kix_nxknj8st7osf-6 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-6;
}
.lst-kix_b8h2xkbwvt0r-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_b8h2xkbwvt0r-5 {
  list-style-type: none;
}
.lst-kix_b8h2xkbwvt0r-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_b8h2xkbwvt0r-8 {
  list-style-type: none;
}
ul.lst-kix_b8h2xkbwvt0r-7 {
  list-style-type: none;
}
ul.lst-kix_b8h2xkbwvt0r-2 {
  list-style-type: none;
}
ul.lst-kix_b8h2xkbwvt0r-1 {
  list-style-type: none;
}
.lst-kix_b8h2xkbwvt0r-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_b8h2xkbwvt0r-4 {
  list-style-type: none;
}
ol.lst-kix_nxknj8st7osf-6.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-6 0;
}
ul.lst-kix_b8h2xkbwvt0r-3 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-7 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-8 {
  list-style-type: none;
}
.lst-kix_b8h2xkbwvt0r-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_b8h2xkbwvt0r-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_b8h2xkbwvt0r-0 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-3 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-4 {
  list-style-type: none;
}
.lst-kix_b8h2xkbwvt0r-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_17xqm2t3a9ux-5 {
  list-style-type: none;
}
ul.lst-kix_17xqm2t3a9ux-6 {
  list-style-type: none;
}
.lst-kix_nxknj8st7osf-8 > li:before {
  content: "" counter(lst-ctn-kix_nxknj8st7osf-8, lower-roman) ". ";
}
.lst-kix_nxknj8st7osf-5 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-5;
}
ol.lst-kix_k5p9mtk303is-1.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-1 0;
}
ol.lst-kix_nxknj8st7osf-7.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-7 0;
}
ol.lst-kix_k5p9mtk303is-5.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-5 0;
}
ol.lst-kix_nxknj8st7osf-0.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-0 0;
}
.lst-kix_k5p9mtk303is-3 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-3, decimal) ". ";
}
ol.lst-kix_nxknj8st7osf-8.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-8 0;
}
.lst-kix_k5p9mtk303is-4 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-4, lower-latin) ". ";
}
.lst-kix_k5p9mtk303is-7 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-7, lower-latin) ". ";
}
.lst-kix_k5p9mtk303is-5 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-5, lower-roman) ". ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_k5p9mtk303is-6 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-6, decimal) ". ";
}
.lst-kix_k5p9mtk303is-6 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-6;
}
.lst-kix_k5p9mtk303is-3 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-3;
}
ol.lst-kix_k5p9mtk303is-0.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-0 0;
}
ol.lst-kix_k5p9mtk303is-6.start {
  counter-reset: lst-ctn-kix_k5p9mtk303is-6 0;
}
.lst-kix_k5p9mtk303is-8 > li:before {
  content: "" counter(lst-ctn-kix_k5p9mtk303is-8, lower-roman) ". ";
}
.lst-kix_nxknj8st7osf-2 > li {
  counter-increment: lst-ctn-kix_nxknj8st7osf-2;
}
ol.lst-kix_nxknj8st7osf-1.start {
  counter-reset: lst-ctn-kix_nxknj8st7osf-1 0;
}
.lst-kix_k5p9mtk303is-0 > li {
  counter-increment: lst-ctn-kix_k5p9mtk303is-0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c16 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c18 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c19 {
  background-color: #fff;
  color: #000c20;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: Arial;
  font-style: normal;
}
.c4 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c1 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c21 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20pt;
  font-family: Arial;
  font-style: normal;
}
.c7 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: Arial;
  font-style: normal;
}
.c8 {
  color: #161519;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c2 {
  color: #161519;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: Arial;
  font-style: normal;
}
.c6 {
  padding-top: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c12 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c0 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  padding-top: 12pt;
  padding-bottom: 2pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c10 {
  padding-top: 14pt;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c20 {
  padding-top: 24pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c14 {
  background-color: #fff;
  max-width: 800pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c9 {
  padding: 0;
  margin: 0;
}
.c11 {
  font-size: 23pt;
  font-weight: 700;
}
.c5 {
  margin-left: 36pt;
  padding-left: 0;
}
.c3 {
  color: #161519;
  font-size: 10.5pt;
}
.c15 {
  color: #161519;
}
.c17 {
  height: 11pt;
}
.title {
  padding-top: 0;
  color: #000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0;
  color: #666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
p {
  margin: 0;
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
h1 {
  padding-top: 20pt;
  color: #000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>