<template>
  <div class="c20 doc-content">
    <h1 class="c19" id="h.ea9an5k74l2o">
      <span class="c21">&#51060;&#50857;&#50557;&#44288;</span>
    </h1>
    <p class="c3 c18"><span class="c2"></span></p>
    <p class="c3">
      <span>&#48376; &#51060;&#50857;&#50557;&#44288;&#51008;</span
      ><span class="c8">Metaware PTE, Ltd.</span
      ><span class="c2"
        >(&#51060;&#54616; &ldquo;&#54924;&#49324;&rdquo;)&#51032; Mintmarble
        App &#48143; &#44288;&#47144;&#54616;&#50668;
        &#51228;&#44277;&#54616;&#45716; &#54532;&#47196;&#44536;&#47016;,
        &#49548;&#54532;&#53944;&#50920;&#50612; &#46321;(&#51060;&#54616;
        &quot;&#49436;&#48708;&#49828;&quot;) &#48143; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51032; &#51201;&#50857;&#51012;
        &#48155;&#51004;&#47728;
        &#49436;&#48708;&#49828;&#50640;&#49436;&#51032;
        &#50629;&#47196;&#46300; &#48143; &#45796;&#50868;&#47196;&#46300;,
        &#44277;&#50976;&#46104;&#45716; &#47784;&#46304; &#51221;&#48372;
        &#48143; &#53581;&#49828;&#53944;, &#44536;&#47000;&#54589;,
        &#49324;&#51652;, &#44592;&#53440; &#51088;&#47308;(&#51060;&#54616;
        &#53685;&#52845; &quot;&#53080;&#53584;&#52768;&quot;)&#50640;
        &#45824;&#54620; &#51217;&#44540; &#48143; &#51060;&#50857;&#50640;
        &#44288;&#54620; &#49324;&#54637;&#51012;
        &#44508;&#51221;&#54633;&#45768;&#45796;. &#48376;
        &#51060;&#50857;&#50557;&#44288;&#50640;
        &#46041;&#51032;&#54616;&#51648; &#50506;&#45716; &#44221;&#50864;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54624; &#49688;
        &#50630;&#51004;&#48064;&#47196;, &#49324;&#50857; &#51204;
        &#49888;&#51473;&#54616;&#44172; &#54869;&#51064;&#54620; &#54980;
        &#46041;&#51032;&#54616;&#50668; &#51452;&#49884;&#44592;
        &#48148;&#46989;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.blnonz3bmbyc">
      <span class="c5">1. &#50857;&#50612;&#51032; &#51221;&#51032;</span>
    </h3>
    <p class="c16">
      <span class="c2"
        >&#48376; &#50557;&#44288;&#50640;&#49436;
        &#49324;&#50857;&#54616;&#45716; &#50857;&#50612;&#51032;
        &#51221;&#51032;&#45716; &#45796;&#51020;&#44284;
        &#44057;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span class="c2"
        >1) &ldquo;&#51060;&#50857;&#51088;&quot;&#51060;&#46976;,
        &#54924;&#49324;&#44032; &#50836;&#44396;&#54616;&#45716;
        &#51208;&#52264;&#50640; &#46384;&#46972; Mintmarble App &#48143;
        &#54924;&#49324;&#44032; &#51228;&#44277;&#54616;&#45716;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#45716;
        &#49324;&#46988;&#51012; &#51032;&#48120;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span class="c2"
        >2) &ldquo;&#46356;&#51648;&#53560;
        &#51088;&#49328;&quot;&#51060;&#46976;, &#48660;&#47197;&#52404;&#51064;
        &#44592;&#49696;&#49104;&#47564; &#50500;&#45768;&#46972;
        &#50516;&#54840;&#54868;&#54224;, &#44172;&#51076;&#53664;&#53360;,
        &#53076;&#51064;, NFT &#48143; &#44536;&#47196;&#48512;&#53552;
        &#54028;&#49373;&#46108; &#46356;&#51648;&#53560; &#51088;&#49328;
        &#46321;&#51012; &#54252;&#54632;&#54620; &#46356;&#51648;&#53560;
        &#54805;&#49885;&#51032; &#47784;&#46304;
        &#48660;&#47197;&#52404;&#51064; &#44592;&#48152;&#51032;
        &#50500;&#51060;&#53596; &#46608;&#45716;
        &#45936;&#51060;&#53552;&#47484;
        &#51032;&#48120;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span>3)</span
      ><span class="c13"
        >&ldquo;NFT(Non-fungible Token)&rdquo;&#51060;&#46976;,
        &#54616;&#45208;&#51032; &#53664;&#53360;&#51012; &#45796;&#47480;
        &#53664;&#53360;&#51004;&#47196; &#45824;&#52404;&#54616;&#45716;
        &#44163;&#51060; &#48520;&#44032;&#45733;&#54620;
        &#48660;&#47197;&#52404;&#51064; &#49345;&#51032;
        &#46356;&#51648;&#53560; &#51088;&#49328;&#51012;
        &#51032;&#48120;&#54616;&#47728;,</span
      ><span>&#49436;&#48708;&#49828;</span
      ><span class="c11"
        >&#45236; &#51068;&#48512; &#50500;&#51060;&#53596; &#46321;&#51012;
        NFT&#47196; &#51228;&#44277;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span class="c11"
        >4) &ldquo;&#50516;&#54840;&#54868;&#54224;&rdquo;&#46976;
        &#48660;&#47197;&#52404;&#51064; &#44592;&#49696;&#51012;
        &#51201;&#50857;&#54616;&#50668; &#48156;&#54665;&#46108;
        &#53076;&#51064;(&#46021;&#47549;&#46108;
        &#48660;&#47197;&#52404;&#51064; &#45348;&#53944;&#50892;&#53356;
        &#47700;&#51064;&#45367;&#51012; &#44592;&#48152;&#51004;&#47196;
        &#48156;&#54665;&#46108; &#50516;&#54840;&#54868;&#54224;)&#50752;
        &#53664;&#53360;(&#46021;&#47549;&#46108;
        &#48660;&#47197;&#52404;&#51064; &#45348;&#53944;&#50892;&#53356;
        &#47700;&#51064;&#45367;&#51060; &#50630;&#51060;
        &#44277;&#44060;&#46108; &#54140;&#48660;&#47533; &#52404;&#51064;
        &#46321; &#47700;&#51064;&#45367;&#50640;&#49436;
        &#48156;&#54665;&#46108; &#50516;&#54840;&#54868;&#54224;)&#47484;
        &#47568;&#54633;&#45768;&#45796;</span
      >
    </p>
    <p class="c16">
      <span class="c11"
        >5) &ldquo;&#53448;&#51473;&#50521;&#54868;
        &#50900;&#47131;&quot;&#51060;&#46976;, &#46356;&#51648;&#53560;
        &#51088;&#49328; &#51648;&#44049;&#51012;
        &#44288;&#47532;&#54616;&#45716; &#51473;&#50521;&#54868;&#46108;
        &#51452;&#52404;&#44032; &#50630;&#44256;, &#51648;&#44049;
        &#49548;&#50976;&#51088; &#44060;&#51064;&#51060;
        &#51648;&#44049;&#51012; &#44288;&#47532;&#54616;&#45716;
        &#48169;&#49885;&#51032; &#46356;&#51648;&#53560; &#51088;&#49328;
        &#51648;&#44049;&#51012; &#51032;&#48120;&#54633;&#45768;&#45796;.
        &#50724;&#51649; &#53448;&#51473;&#50521;&#54868;
        &#51648;&#44049;&#51032; &#49548;&#50976;&#51088;&#47564;&#51060;
        &#54532;&#46972;&#51060;&#48727;&#53412;&#47196;
        &#48660;&#47197;&#52404;&#51064;
        &#45348;&#53944;&#50892;&#53356;&#51032;
        &#53944;&#47004;&#51117;&#49496;&#50640; &#49436;&#47749;&#51012;
        &#51652;&#54665;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span class="c11"
        >6) &ldquo;&#54532;&#46972;&#51060;&#48727; &#53412;&quot;&#46976;,
        &#53448;&#51473;&#50521;&#54868; &#50900;&#47131;
        &#51452;&#49548;&#50752; 1:1&#47196; &#47588;&#52845;&#46104;&#45716;
        &#53076;&#46300;(&#49707;&#51088;&#50752; &#47928;&#51088;&#47484;
        &#51076;&#51032;&#47196; &#51312;&#54633;&#54616;&#50668;
        &#49373;&#49457;)&#47484; &#51032;&#48120;&#54616;&#47728;,
        &#53448;&#51473;&#50521;&#54868; &#50900;&#47131;&#51032;
        &#47784;&#46304; &#49436;&#47749;&#51012;
        &#51652;&#54665;&#54616;&#44592; &#50948;&#54620;
        &#51473;&#50836;&#54620; &#53412;&#51077;&#45768;&#45796;.
        &#54532;&#46972;&#51060;&#48727; &#53412;&#45716; &#49436;&#47749;
        &#44428;&#54620;&#51060; &#51080;&#51004;&#48064;&#47196;
        &#50808;&#48512;&#50640; &#45432;&#52636;&#46108; &#44221;&#50864;
        &#53440;&#51064;&#51060; &#53664;&#53360;&#51032; &#51204;&#49569;
        &#46321; &#50976;&#54952;&#54620; &#49436;&#47749;&#51012; &#54624;
        &#49688; &#51080;&#44172; &#46121;&#45768;&#45796;.
        &#52712;&#44553;&#50640; &#44033;&#48324;&#54620;
        &#51452;&#51032;&#44032; &#54596;&#50836;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c16">
      <span class="c13"
        >7) &ldquo;&#50900;&#47131;
        &#51064;&#51613;&quot;&#51060;&#46976;,</span
      ><span
        >Mintmarble App&#45236;&#50640;&#49436; &#49324;&#50857;&#54616;&#45912;
        &#46356;&#51648;&#53560; &#51088;&#49328;&#51012;
        &#48660;&#47197;&#52404;&#51064;
        &#45348;&#53944;&#50892;&#53356;&#47196; &#51204;&#49569;&#51012;
        &#50836;&#52397;&#54616;&#45716; &#51452;&#52404;&#47484;
        &#53945;&#51221;&#54616;&#44592; &#50948;&#54644;
        &#44060;&#51064;&#51060; &#44288;&#47532;&#54616;&#45716;
        &#53448;&#51473;&#50521;&#54868;&#50900;&#47131;&#51012;
        &#53685;&#54620; &#51064;&#51613;
        &#48169;&#48277;&#51077;&#45768;&#45796;. &#54924;&#49324;&#45716;
        &#44060;&#51064;&#51012; &#53945;&#51221;&#54616;&#45716;
        &#48324;&#46020;&#51032; &#44060;&#51064;&#51221;&#48372;&#47484;
        &#52712;&#44553;&#54616;&#51648; &#50506;&#44592;
        &#46412;&#47928;&#50640; &#44172;&#51076; &#44228;&#51221;
        &#51221;&#48372;&#50752; &#44060;&#51064;&#51060;
        &#44288;&#47532;&#54616;&#45716; &#53448;&#51473;&#50521;&#54868;
        &#50900;&#47131;&#51012; 1:1
        &#47588;&#52845;&#54633;&#45768;&#45796;.</span
      ><span class="c7">&nbsp;</span>
    </p>
    <h3 class="c6" id="h.fqnc3etnnxj">
      <span class="c5"
        >2. &#54924;&#49324;&#50752;&#51032; &#44228;&#50557;</span
      >
    </h3>
    <p class="c3">
      <span>1) &#51116;&#54032;&#44428; &#48143; &#51456;&#44144;&#48277;:</span
      ><span class="c0"
        >&#48376; &#51060;&#50857;&#50557;&#44288;&#51008;
        &#49905;&#44032;&#54252;&#47476; &#48277;&#51012;
        &#51456;&#44144;&#48277;&#51004;&#47196; &#54616;&#47728;
        &#51060;&#50640; &#46384;&#46972; &#54644;&#49437;, &#53685;&#51228;,
        &#51060;&#54644;&#46121;&#45768;&#45796;.
        &#49436;&#48708;&#49828;&#51032; &#51060;&#50857;&#44284;
        &#44288;&#47144;&#54616;&#50668; &#51060;&#50857;&#51088;
        &#46608;&#45716; &#49324;&#50857;&#51088;&#44284; &#54924;&#49324;
        &#44036;&#51032; &#48516;&#51137;&#51060;
        &#48156;&#49373;&#54616;&#45716; &#44221;&#50864;
        &#45817;&#49324;&#51088; &#49324;&#51060;&#51032;
        &#54644;&#44208;&#51012; &#50948;&#54616;&#50668;
        &#49345;&#54840;&#44036; &#45432;&#47141;&#54616;&#44256;,
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#45716; help@metaware.ltd&#51012;
        &#53685;&#54644; &#48516;&#51137; &#54644;&#44208;&#51012;
        &#50836;&#52397;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#44536;&#47100;&#50640;&#46020; &#48520;&#44396;&#54616;&#44256;
        &#54644;&#44208;&#46104;&#51648; &#50500;&#45768;&#54616;&#47732;
        &#49548;&#47484; &#51228;&#44592;&#54624; &#51080;&#51004;&#47728;,
        &#49905;&#44032;&#54252;&#47476;&#51032; &#48277;&#50896;&#51012;
        &#44288;&#54624; &#48277;&#50896;&#51004;&#47196;
        &#51648;&#51221;&#54632;&#50640; &#45824;&#54644;
        &#51060;&#50857;&#51088; &#48143; &#49324;&#50857;&#51088;&#45716;
        &#46041;&#51032;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span
        >2) &#47732;&#52293; &#51312;&#54637;: &#54924;&#49324;&#50752;
        &#49324;&#50857;&#51088;&#45716; &#44397;&#51228; &#47932;&#54408;
        &#47588;&#47588; &#44228;&#50557;&#50640; &#44288;&#54620;
        &#50976;&#50644; &#54801;&#50557;&#49345; &#52293;&#51076;&#44284;
        &#44428;&#47532;&#47484; &#48512;&#51064;&#54616;&#47728;, &#48376;
        &#49436;&#48708;&#49828;&#51032; &#49324;&#50857;&#50640;
        &#51080;&#50612; &#51060;&#47484; &#51201;&#50857;&#54616;&#51648;
        &#50506;&#44592;&#47196; &#54633;&#51032;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span>3</span
      ><span class="c2"
        >) &#54924;&#49324;&#50640; &#51228;&#44277;&#54620; &#47784;&#46304;
        &#51221;&#48372;&#45716; &#44060;&#51064;&#51221;&#48372;&#51032;
        &#49688;&#51665; &#48143; &#51060;&#50857;&#50640; &#44288;&#54620;
        &#54924;&#49324;&#51032; &#44060;&#51064;&#51221;&#48372;
        &#52712;&#44553;&#48169;&#52840;&#51032; &#51201;&#50857;&#51012;
        &#48155;&#49845;&#45768;&#45796;. &#49324;&#50857;&#51088;&#45716;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#45716;
        &#44163;&#51004;&#47196; &#44288;&#47144; &#48277;&#47456;&#49345;
        &#44060;&#51064;&#51221;&#48372; &#48372;&#54840;
        &#51221;&#52293;&#50640; &#46384;&#46972;
        &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#51032; &#49688;&#51665; &#48143;
        &#51060;&#50857;&#50640; &#46041;&#51032;&#54633;&#45768;&#45796;.
        &#48376; &#49436;&#48708;&#49828;&#51032;
        &#51068;&#54872;&#51004;&#47196; &#54924;&#49324;&#45716;
        &#49324;&#50857;&#51088;&#50640;&#44172; &#49436;&#48708;&#49828;
        &#50508;&#47548; &#48143; &#51221;&#48372;&#49457;
        &#47700;&#49884;&#51648;&#47484; &#51204;&#49569;&#54624; &#49688;
        &#51080;&#51004;&#47728;, &#51060;&#45716;
        &#49436;&#48708;&#49828;&#51032;
        &#51068;&#48512;&#51060;&#48064;&#47196; &#49688;&#49888;
        &#44144;&#48512;&#54624; &#49688;
        &#50630;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;
        &#49884;&#49828;&#53596;&#50640;&#51032; &#44592;&#44228;&#51201;
        &#51217;&#44540; &#46608;&#45716; &#44228;&#51221;&#46020;&#50857;,
        &#44144;&#51667;&#46108; &#51221;&#48372;&#51032; &#51228;&#44277;
        &#48143; &#49324;&#50857;&#51088;&#51032; &#49436;&#48708;&#49828;
        &#50612;&#48624;&#51669; &#45236;&#50669; &#54869;&#51064;
        &#46321;&#51032; &#49324;&#50976;&#44032; &#51080;&#50612;
        &#54924;&#49324;&#44032; &#49436;&#48708;&#49828;
        &#49324;&#50857;&#51032; &#49888;&#52397;&#51012;
        &#49849;&#45209;&#54616;&#45716; &#44163;&#51060;
        &#51201;&#51208;&#54616;&#51648; &#50506;&#51008; &#44221;&#50864;
        &#44536; &#49849;&#45209;&#51012; &#50976;&#48372; &#46608;&#45716;
        &#44144;&#48512;&#54616;&#44144;&#45208;, &#49324;&#54980;&#50640;
        &#51060;&#50857;&#44228;&#50557;&#51012; &#54644;&#51648;&#54624;
        &#49688; &#51080;&#44256;, &#54596;&#50836;&#54620; &#44221;&#50864;
        &#51068;&#51221;&#44592;&#44036; &#46041;&#50504;
        &#49436;&#48708;&#49828; &#51060;&#50857;&#51012;
        &#51228;&#54620;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.8cdcfokmq0v">
      <span class="c5"
        >3. &#50557;&#44288;&#51032; &#44172;&#49884;&#50752;
        &#44060;&#51221;</span
      >
    </h3>
    <p class="c3">
      <span class="c8"
        >&#54924;&#49324;&#45716; &#54596;&#50836;&#54616;&#45796;&#44256;
        &#51064;&#51221;&#46104;&#45716; &#44221;&#50864;, &#44288;&#47144;
        &#48277;&#47456; &#48143; &#44508;&#51221;&#51012;
        &#50948;&#48152;&#54616;&#51648; &#50506;&#45716; &#48276;&#50948;
        &#45236;&#50640;&#49436; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51012; &#44060;&#51221;&#54624;
        &#49688; &#51080;&#49845;&#45768;&#45796;. &#44060;&#51221;&#46108;
        &#51060;&#50857;&#50557;&#44288;&#51008; &#48120;&#47532;
        &#51060;&#47700;&#51068; &#51452;&#49548; &#46608;&#45716;
        &#49436;&#48708;&#49828; &#45236; &#51204;&#51088; &#53685;&#51648;
        &#46608;&#45716; &#54045;&#50629; &#47700;&#49884;&#51648;
        &#46321;&#44284; &#44057;&#51008; &#51204;&#51088;&#51201;
        &#48169;&#52293;&#51012; &#53685;&#54644; &#44277;&#51648;
        &#46608;&#45716; &#53685;&#51648;&#46121;&#45768;&#45796;.
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#45716; &#54924;&#49324;&#51032;
        &#51116;&#47049;&#50640; &#46384;&#46972; &#48376;
        &#51060;&#50857;&#50557;&#44288; &#48143;/&#46608;&#45716;
        &#49436;&#48708;&#49828;&#44032; &#50616;&#51228;&#46304;&#51648;
        &#49688;&#51221; &#46608;&#45716; &#48320;&#44221;&#46112; &#49688;
        &#51080;&#51020;&#51012; &#51064;&#51221;&#54616;&#44256;
        &#46041;&#51032;&#54616;&#45716; &#44163;&#51076;&#51012;
        &#50976;&#51032;&#54633;&#45768;&#45796;. &#44060;&#51221;&#46108;
        &#51060;&#50857;&#50557;&#44288;&#50640;
        &#46041;&#51032;&#54616;&#51648; &#50506;&#51012; &#44221;&#50864;,
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#45716; &#50616;&#51228;&#46304;
        &#49436;&#48708;&#49828; &#51060;&#50857;&#51012;
        &#51473;&#45800;&#54616;&#44144;&#45208;
        &#53448;&#53748;&#54632;&#51004;&#47196;&#50024;
        &#44228;&#50557;&#51012; &#54028;&#44592;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51060; &#44060;&#51221;&#46108;
        &#54980;&#50640;&#46020; &#53448;&#53748;&#54616;&#51648;
        &#50506;&#44256; &#49436;&#48708;&#49828;&#47484;
        &#44228;&#49549;&#54644;&#49436; &#51060;&#50857;&#54616;&#45716;
        &#44221;&#50864;, &#48320;&#44221;, &#49688;&#51221; &#46608;&#45716;
        &#45824;&#52404;&#46108; &#49324;&#54637;&#50640; &#45824;&#54644;
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#51060; &#46041;&#51032;&#54620;
        &#44163;&#51004;&#47196; &#44036;&#51452;&#54633;&#45768;&#45796;.
        &#44032;&#51109; &#52572;&#44540; &#49688;&#51221;
        &#45216;&#51676;&#45716; &#51060; &#50557;&#44288;&#51032;
        &#49345;&#45800;&#50640; &#54364;&#49884;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.va5jruh69482">
      <span class="c5"
        >4. &#49324;&#50857;&#51088;&#51032; &#46041;&#51032; &#48143;
        &#51452;&#51032;&#51032;&#47924;</span
      >
    </h3>
    <p class="c15">
      <span class="c0"
        >&#54924;&#49324;&#51032; &#49436;&#48708;&#49828;&#47484;
        &#51060;&#50857;&#54632;&#51004;&#47196;&#50024;
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#45716; &#45796;&#51020;
        &#45236;&#50857;&#50640; &#46041;&#51032;&#54633;&#45768;&#45796;</span
      >
    </p>
    <ol class="c14 lst-kix_twpgf765a21o-0 start" start="1">
      <li class="c9 li-bullet-0">
        <span class="c0"
          >&#49324;&#50857;&#51088;&#45716; &#46356;&#51648;&#53560;
          &#51088;&#49328; &#48143;/&#46608;&#45716;
          &#48660;&#47197;&#52404;&#51064; &#44592;&#48152;
          &#49884;&#49828;&#53596;&#51012;
          &#51060;&#50857;&#54616;&#45716;&#45936; &#54596;&#50836;&#54620;
          &#52649;&#48516;&#54620; &#51648;&#49885;&#51012;
          &#44054;&#52628;&#50612;&#50556; &#54616;&#47728;,
          &#52649;&#48516;&#55176; &#51060;&#54644;&#54616;&#44256;
          &#53945;&#55176; &#46356;&#51648;&#53560; &#51088;&#49328;&#44284;
          &#44288;&#47144;&#46108; &#47784;&#46304; &#51109;&#51216;,
          &#50948;&#54744; &#48143; &#51228;&#54620; &#49324;&#54637;&#50640;
          &#45824;&#54644; &#52649;&#48516;&#55176; &#51064;&#51648;&#54620;
          &#54980; &#49436;&#48708;&#49828;&#47484;
          &#51060;&#50857;&#54644;&#50556; &#54633;&#45768;&#45796;.</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >&#49324;&#50857;&#51088;&#45716; &#54924;&#49324;&#51032;
          &#49436;&#48708;&#49828;&#47484; &#48520;&#48277;&#51201;&#51064;
          &#54876;&#46041; &#47785;&#51201;&#51004;&#47196;
          &#51060;&#50857;&#54616;&#51648; &#50506;&#51004;&#47728;,
          &#50612;&#46496;&#54620;
          &#48520;&#48277;&#54876;&#46041;&#50640;&#46020;
          &#44288;&#50668;&#54644;&#49436;&#45716; &#50504;
          &#46121;&#45768;&#45796;.</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >&#51060;&#50857;&#51088; &#46608;&#45716;
          &#49324;&#50857;&#51088;&#45716; &#51088;&#49888;&#51032;
          &#51088;&#44201; &#51613;&#47749;(&#51060;&#47700;&#51068;
          &#51452;&#49548;, &#50516;&#54840; &#46608;&#45716;
          &#49436;&#48708;&#49828; &#49324;&#50857;&#51012; &#50948;&#54644;
          &#51228;&#44277;&#46108; &#44592;&#53440; &#51221;&#48372;)&#51012;
          &#49828;&#49828;&#47196; &#53685;&#51228;&#54616;&#47728;
          &#51228;3&#51088;&#47484; &#45824;&#49888;&#54616;&#50668;
          &#54876;&#46041;&#54616;&#51648;
          &#50506;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >&#45908; &#45208;&#51008; &#49436;&#48708;&#49828;&#47484;
          &#51228;&#44277;&#54616;&#44592; &#50948;&#54644;
          &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#50640;
          &#54364;&#49884;&#54616;&#44144;&#45208; &#50508;&#47548;,
          &#44288;&#47532; &#47700;&#49884;&#51648; &#48143;
          &#49436;&#48708;&#49828; &#49324;&#50857;&#50640; &#44288;&#54620;
          &#44592;&#53440; &#44305;&#44256;&#47484; &#54252;&#54632;&#54620;
          &#45796;&#50577;&#54620; &#51221;&#48372;&#47484;
          &#51204;&#51088;&#51201; &#44256;&#51648; &#49688;&#45800;&#51012;
          &#53685;&#54644; &#48156;&#49569;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;. &#49345;&#50629;&#50857;
          &#51060;&#47700;&#51068;&#51012; &#48155;&#51648;
          &#50506;&#44592;&#47484; &#50896;&#54616;&#49888;&#45796;&#47732;
          help@metaware.ltd &#46608;&#45716; &#49436;&#48708;&#49828;&#51032;
          &#44256;&#44061;&#49468;&#53552;&#47196;
          &#47928;&#51032;&#54616;&#50668; &#51452;&#49884;&#44592;
          &#48148;&#46989;&#45768;&#45796;.</span
        >
      </li>
    </ol>
    <h3 class="c6" id="h.hkjmknbc5i9f">
      <span class="c5">5. &#48372;&#51613;</span>
    </h3>
    <p class="c17">
      <span class="c8"
        >&#49436;&#48708;&#49828;&#45716; &#54924;&#49324;&#51032;
        &#54028;&#53944;&#45320;, &#50629;&#52404;, &#44172;&#51076;
        &#44060;&#48156;&#51088; &#46321;&#51032; &#45236;&#50857;
        &#48143;/&#46608;&#45716; &#51228;3&#51088;
        &#50937;&#49324;&#51060;&#53944; &#48143;
        &#49436;&#48708;&#49828;&#50640; &#45824;&#54620;
        &#47553;&#53356;&#47484; &#54252;&#54632;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#51060;&#47084;&#54620;
        &#49436;&#48708;&#49828; &#46608;&#45716; &#47553;&#53356;&#45716;
        &#51060;&#50857;&#51088; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#51032; &#54200;&#51032;&#47484;
        &#50948;&#54644; &#51228;&#44277;&#46104;&#47728;,
        &#54924;&#49324;&#45716; &#51228;3&#51088;
        &#50937;&#49324;&#51060;&#53944;&#51032; &#50504;&#51204;
        &#46608;&#45716; &#51201;&#54633;&#49457;&#51012; &#48372;&#51109;,
        &#51228;&#50504; &#46608;&#45716; &#51032;&#48120;&#54616;&#51648;
        &#50506;&#49845;&#45768;&#45796;. &#46608;&#54620;
        &#54924;&#49324;&#45716; &#50808;&#48512;
        &#49324;&#51060;&#53944;&#50640;&#49436; &#52280;&#51312;&#54620;
        &#51088;&#47308;&#47484; &#50976;&#51648;&#54624;
        &#52293;&#51076;&#51060; &#50630;&#51004;&#47728;, &#54644;&#45817;
        &#49324;&#51060;&#53944;&#45208; &#54644;&#45817;
        &#49436;&#48708;&#49828;&#50640; &#45824;&#54644;
        &#48372;&#51613;&#54616;&#44144;&#45208;
        &#48372;&#51109;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#51060;&#47084;&#54620; &#51228;3&#51088;
        &#49436;&#48708;&#49828; &#48143; &#51088;&#50896;&#51012;
        &#53685;&#54644; &#51060;&#50857; &#44032;&#45733;&#54620;
        &#52968;&#53584;&#52768;, &#49345;&#54408; &#46608;&#45716;
        &#49436;&#48708;&#49828;&#47484;
        &#51649;/&#44036;&#51217;&#51201;&#51004;&#47196;
        &#49324;&#50857;&#54632;&#51004;&#47196;&#50024;
        &#49552;&#49345;&#51060;&#45208; &#49552;&#49892; &#46608;&#45716;
        &#44592;&#53440; &#50689;&#54693;&#51060; &#48156;&#49373;&#54620;
        &#44221;&#50864;, &#50612;&#46496;&#54620; &#52293;&#51076;&#46020;
        &#51656; &#51032;&#47924;&#44032; &#50630;&#49845;&#45768;&#45796;.
        &#51060;&#47084;&#54620; &#49436;&#48708;&#49828;&#50640;&#45716;
        &#51088;&#52404; &#51221;&#52293;&#51060; &#51080;&#51012; &#49688;
        &#51080;&#51004;&#47728;, &#54924;&#49324;&#45716; &#54644;&#45817;
        &#51221;&#52293;&#50640; &#45824;&#54644; &#50612;&#46496;&#54620;
        &#52293;&#51076;&#46020; &#51648;&#51648;
        &#50506;&#45716;&#45796;&#45716; &#51216;&#50640;
        &#50976;&#51032;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <h3 class="c6" id="h.gsos2fpq3eus">
      <span class="c5">6. &#50629;&#45936;&#51060;&#53944;</span>
    </h3>
    <p class="c17">
      <span class="c8"
        >&#54924;&#49324;&#45716; &#54633;&#47532;&#51201;&#51064;
        &#47785;&#51201;&#51004;&#47196; &#49436;&#48708;&#49828;
        &#51228;&#44277;&#44284; &#44288;&#47144;&#46108; &#54056;&#52824;,
        &#50629;&#45936;&#51060;&#53944;,
        &#50629;&#44536;&#47112;&#51060;&#46300;, &#52968;&#53584;&#52768;
        &#46608;&#45716; &#44592;&#53440;
        &#49688;&#51221;&#49324;&#54637;&#51012;
        &#48176;&#54252;&#54616;&#44144;&#45208; &#51228;&#44277;&#54624;
        &#49688; &#51080;&#49845;&#45768;&#45796;. &#51060;&#47084;&#54620;
        &#50629;&#45936;&#51060;&#53944;&#47196; &#51064;&#54644;
        &#49436;&#48708;&#49828;&#44032;
        &#51068;&#49884;&#51201;&#51004;&#47196; &#49324;&#50857;&#54624;
        &#49688; &#50630;&#44172; &#46112; &#49688;&#46020;
        &#51080;&#49845;&#45768;&#45796;. &#53945;&#51221;
        &#49345;&#54889;&#50640;&#49436;&#45716;
        &#50629;&#45936;&#51060;&#53944;&#47484;
        &#48176;&#54252;&#54616;&#45716; &#46041;&#50504;
        &#49436;&#48708;&#49828;&#47484; &#51109;&#44592;&#44036;
        &#51068;&#49884; &#51473;&#45800;, &#52384;&#54924;, &#46608;&#45716;
        &#48708;&#54876;&#49457;&#54868; &#54624; &#49688;&#46020;
        &#51080;&#49845;&#45768;&#45796;. &#50612;&#46496;&#54620;
        &#50896;&#51064;&#51004;&#47196; &#51064;&#54620;
        &#49436;&#48708;&#49828; &#51473;&#45800;&#51008;
        &#49436;&#48708;&#49828; &#45236;&#51032;
        &#44277;&#51648;&#49324;&#54637;, &#54045;&#50629;
        &#47700;&#49884;&#51648; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#51032; &#51060;&#47700;&#51068;&#51012;
        &#53685;&#54644; &#44277;&#51648;&#54616;&#44144;&#45208;
        &#48156;&#54364;&#46121;&#45768;&#45796;. &#54924;&#49324;&#44032;
        &#50696;&#52769;&#54624; &#49688; &#50630;&#44144;&#45208;
        &#53685;&#51228;&#54624; &#49688; &#50630;&#45716;
        &#49324;&#50976;&#45716; &#49324;&#51204; &#53685;&#51648;
        &#46608;&#45716; &#44277;&#51648;&#49324;&#54637;&#50640;&#49436;
        &#50696;&#50808;&#44032; &#46112; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#45800;, &#51060;&#47084;&#54620;
        &#50696;&#50808;&#51201;&#51064; &#51060;&#50976;&#46972;&#46020;
        &#54924;&#49324;&#45716; &#44032;&#45733;&#54620;
        &#52572;&#45824;&#54620; &#48736;&#47480; &#49884;&#51068;
        &#45236;&#50640; &#49436;&#48708;&#49828; &#48373;&#44396;&#50640;
        &#52572;&#49440;&#51012; &#45796;&#54624;
        &#44163;&#51077;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.vquvtspaniok">
      <span class="c5"
        >7. &#48124;&#53944;&#47560;&#48660; &#53080;&#53584;&#52768; &#48143;
        &#49324;&#50857;&#51088; &#53080;&#53584;&#52768;</span
      >
    </h3>
    <p class="c3">
      <span class="c2"
        >1) &quot;&#48124;&#53944;&#47560;&#48660; IP(Intellectual Property)
        &#53080;&#53584;&#52768;&quot;&#46976;, &#49436;&#48708;&#49828;&#47484;
        &#44396;&#49457;&#54616;&#44256; &#50868;&#50689;&#54616;&#44592;
        &#50948;&#54644; &#54924;&#49324;&#44032; &#51228;&#51089;&#54620;
        &#47784;&#46304; &#51200;&#51089;&#47932;(&#50900;&#46300;,
        &#50500;&#48148;&#53440;, &#50500;&#51060;&#53596;, &#50689;&#49345;,
        &#51060;&#48120;&#51648;, &#44172;&#51076;, &#53581;&#49828;&#53944;,
        &#49828;&#53664;&#47532;, &#44536;&#47000;&#54589;, &#51020;&#50501;,
        &#46972;&#51060;&#48652; &#48169;&#49569;, &#47928;&#44396;
        &#46321;)&#51012;
        &#51032;&#48120;&#54633;&#45768;&#45796;(&#49884;&#44033;&#51201;&#51004;&#47196;
        &#54869;&#51064; &#44032;&#45733;&#54620;
        &#51088;&#47308;&#46308;&#51012; &#45824;&#49345;&#51004;&#47196;
        &#54616;&#47728;, &#52980;&#54504;&#53552; &#46321;
        &#51221;&#48372;&#52376;&#47532;&#51109;&#52824;
        &#45236;&#50640;&#49436; &#51649;,
        &#44036;&#51217;&#51201;&#51004;&#47196;
        &#49324;&#50857;&#46104;&#45716; &#54532;&#47196;&#44536;&#47016;,
        &#49548;&#54532;&#53944;&#50920;&#50612; &#46321;&#51008;
        &#51228;&#50808;). &ldquo;&#48124;&#53944;&#47560;&#48660; IP
        &#53080;&#53584;&#52768;&rdquo;&#51032; &#49440;&#53469;&#44284;
        &#48176;&#52824; &#46321;&#51012; &#48708;&#47215;&#54616;&#50668;
        &#49436;&#48708;&#49828;&#50640; &#54252;&#54632;&#46108;
        &#47784;&#46304; &#51088;&#47308;&#45716; &#51200;&#51089;&#44428;,
        &#49345;&#54364;&#44428;, &#53944;&#47112;&#51060;&#46300;
        &#46300;&#47112;&#49828;, &#53945;&#54728;&#44428;
        &#48143;/&#46608;&#45716; &#44592;&#53440; &#51648;&#51201;
        &#51116;&#49328;&#44428;&#51008; &#54924;&#49324;&#50640;&#44172;
        &#51080;&#51004;&#47728;, &#44288;&#47144; &#48277;&#47456;&#50640;
        &#51032;&#54644; &#48372;&#54840;&#48155;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >2) &#49324;&#50857;&#51088;&#45716;
        &ldquo;&#48124;&#53944;&#47560;&#48660; IP
        &#53080;&#53584;&#52768;&rdquo;&#47484; &#51060;&#50857;&#54616;&#50668;
        &#49324;&#51652;, &#46041;&#50689;&#49345;, &#53581;&#49828;&#53944;,
        &#44536;&#47000;&#54589;, &#50500;&#51060;&#53596; &#48143;
        &#44592;&#53440; &#51088;&#47308;(&#51060;&#54616;
        &ldquo;&#49324;&#50857;&#51088; &#53080;&#53584;&#52768;&rdquo;)&#47484;
        &#51228;&#51089;&#54616;&#44256; &#44172;&#49884;&#54616;&#47728;,
        &#51204;&#49569; &#48143; &#51200;&#51109;&#54616;&#45716; &#46321;
        &#51201;&#44537;&#51201;&#51064;
        &#49345;&#54840;&#51089;&#50857;&#51012; &#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#54924;&#49324;&#45716;
        &#49324;&#50857;&#51088; &#44036;&#51032;
        &#51201;&#44537;&#51201;&#51064;
        &#49345;&#54840;&#51089;&#50857;&#51012;
        &#51109;&#47140;&#54616;&#44592; &#50948;&#54616;&#50668;
        &#49436;&#48708;&#49828;&#50640;&#49436; &#54616;&#45716;
        &#54876;&#46041;&#50640; &#44592;&#52488;&#54616;&#50668;
        &#49324;&#50857;&#51088;&#44032; &#44288;&#49900;&#51012;
        &#44032;&#51656;&#47564;&#54620; &#39;&#53080;&#53584;&#52768;&#39;,
        &#44592;&#45733;&#51012; &#51228;&#50504;&#54616;&#44144;&#45208;
        &#52628;&#52380;&#54624; &#49688; &#51080;&#51004;&#47728;,
        &#49324;&#50857;&#51088;&#50640;&#44172;
        &#49436;&#48708;&#49828;&#47484; &#44221;&#54744;&#54616;&#45716;
        &#48169;&#48277;&#51012; &#51228;&#50504;&#54616;&#44592;&#46020;
        &#54633;&#45768;&#45796;. &#47932;&#47200;,
        &#49324;&#50857;&#51088;&#45716; &#44060;&#51064;&#51221;&#48372;
        &#49444;&#51221;&#50640; &#46384;&#46972; &#48376;&#51064;&#51032;
        &ldquo;&#49324;&#50857;&#51088; &#53080;&#53584;&#52768;&rdquo;&#47484;
        &#48380; &#49688; &#51080;&#45716; &#45824;&#49345;&#51012;
        &#51228;&#54620;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#49324;&#50857;&#51088;&#44032; &#51228;&#51089;&#54620;
        &ldquo;&#49324;&#50857;&#51088; &#53080;&#53584;&#52768;&rdquo;&#45716;
        &#53440;&#51064;&#51060; &#51312;&#54924;&#54624; &#49688;
        &#51080;&#51004;&#47728;, &#49324;&#50857;&#51088;&#45716;
        &#44060;&#51064;&#51221;&#48372; &#49444;&#51221;
        &#44592;&#45733;&#51012; &#53685;&#54644;
        &#53080;&#53584;&#52768;&#50640; &#45824;&#54620;
        &#51217;&#44540;&#51012; &#44288;&#47532;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#54924;&#49324;&#51032;
        &#51649;&#50896;&#51008; &#49436;&#48708;&#49828;&#50640;
        &#50629;&#47196;&#46300; &#46108; &quot;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&quot;&#47484; &#54869;&#51064;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#54924;&#49324;&#45716;
        &#49324;&#50857;&#51088;&#51032; &#44060;&#51064;
        &#53080;&#53584;&#52768;&#47484; &#50504;&#51204;&#54616;&#44172;
        &#48372;&#54840;&#54616;&#44592; &#50948;&#54644;
        &#54633;&#45817;&#54620; &#51312;&#52824;&#47484;
        &#52712;&#54616;&#44256; &#51080;&#51004;&#45208;, 100%
        &#50504;&#51204;&#51008; &#48372;&#51109;&#54624; &#49688;
        &#50630;&#51004;&#47728;, &#51228;3&#51088;&#44032;
        &#47924;&#45800;&#51004;&#47196; &#48372;&#50504;&#51012;
        &#46763;&#44256; &#44060;&#51064; &#53080;&#53584;&#52768;&#50640;
        &#51217;&#44540;&#54616;&#45716; &#49345;&#54889;&#51060;
        &#48156;&#49373;&#54624; &#49688;&#46020; &#51080;&#51020;&#51012;
        &#50577;&#54644;&#54616;&#50668; &#51452;&#49884;&#44592;
        &#48148;&#46989;&#45768;&#45796;. &#51060;&#50752;
        &#45908;&#48520;&#50612; &#46412;&#46412;&#47196;
        &#50629;&#45936;&#51060;&#53944;&#46104;&#45716;
        &#54924;&#49324;&#51032; &#52964;&#48036;&#45768;&#54000;
        &#44032;&#51060;&#46300;&#46972;&#51064;&#46020;
        &#48152;&#46300;&#49884; &#51456;&#49688;&#54644;&#50556;
        &#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &ldquo;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&rdquo;&#50640; &#45824;&#54620;
        &#47784;&#46304; &#51200;&#51089;&#44428; &#48143; &#44592;&#53440;
        &#51648;&#51201;&#51116;&#49328;&#44428;&#51008;
        &#48376;&#51064;&#50640;&#44172; &#51080;&#49845;&#45768;&#45796;.
        &#45796;&#47564;, &ldquo;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&rdquo;&#45716;
        &ldquo;&#48124;&#53944;&#47560;&#48660; IP
        &#53080;&#53584;&#52768;&rdquo;&#47484; &#44592;&#52488;&#47196;
        &#52285;&#51089; &#46608;&#45716; &#51228;&#51089;&#46108;
        &#44163;&#51004;&#47196; &#54924;&#49324;&#44032; &#51221;&#54620;
        &#44032;&#51060;&#46300;&#46972;&#51064;&#50640;
        &#50948;&#48152;&#46104;&#51648; &#50506;&#44172;
        &#49324;&#50857;&#46104;&#50612;&#50556; &#54616;&#47728;,
        &#53945;&#55176; &#51060;&#47484; &#48124;&#53944;&#47560;&#48660;
        &#54540;&#47019;&#54268; &#50808;&#50640;&#49436;
        &#49324;&#50857;&#54616;&#45716; &#44221;&#50864;&#50640;&#45716;
        &#54924;&#49324;&#50640;&#49436; &#51221;&#54620;
        &ldquo;&#48124;&#53944;&#47560;&#48660; &#54876;&#50857;
        &#53080;&#53584;&#52768;(UGC) &#51060;&#50857;
        &#44032;&#51060;&#46300;&#46972;&#51064;&rdquo;&#51012;
        &#51456;&#49688;&#54616;&#50668; &#51452;&#49884;&#44592;
        &#48148;&#46989;&#45768;&#45796;.
        &ldquo;&#48124;&#53944;&#47560;&#48660; IP
        &#53080;&#53584;&#52768;&rdquo;&#50640; &#45824;&#54620;
        &#51200;&#51089;&#44428; &#44592;&#53440;
        &#51648;&#51201;&#51116;&#49328;&#44428;&#51008;
        &#50668;&#51204;&#55176; &#54924;&#49324;&#50640;
        &#51080;&#51004;&#47728;, &#48376; &#49436;&#48708;&#49828;
        &#51060;&#50857;&#51004;&#47196; &#51064;&#54644;
        &#48156;&#49373;&#54616;&#45716; &#47784;&#46304;
        &#45936;&#51060;&#53552; &#49324;&#50857;&#47308;&#45716;
        &#49324;&#50857;&#51088;&#51032;
        &#48512;&#45812;&#51077;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >5) &ldquo;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&rdquo;&#47484;
        &#48124;&#53944;&#47560;&#48660;&#50640;
        &#44172;&#49884;&#54632;&#51004;&#47196;&#50024;
        &#49324;&#50857;&#51088;&#45716; &#54788;&#51116; &#46608;&#45716;
        &#52628;&#54980; &#44060;&#48156;&#46104;&#45716; &#47784;&#46304;
        &#48120;&#46356;&#50612; &#48143; &#48176;&#54252;
        &#47588;&#52404;&#50640; &#54644;&#45817; &#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&#47484; &#49324;&#50857;(&#44592;&#44228;
        &#54617;&#49845; &#48143; &#54924;&#49324; &#45800;&#46021;
        &#46608;&#45716; &#44228;&#50676;&#49324;&#50752; &#54633;&#51089;
        R&amp;D &#47785;&#51201;&#51004;&#47196; &#50620;&#44404;
        &#51060;&#48120;&#51648;, &#49324;&#51652;,
        &#46041;&#50689;&#49345;&#51012; &#51060;&#50857;&#54616;&#45716;
        &#44163;&#46020; &#54252;&#54632;) &#44428;&#54620;&#51012;
        &#54924;&#49324;&#50640; &#48512;&#50668;&#54620;
        &#44163;&#51004;&#47196; &#44036;&#51452;&#46104;&#47728;,
        &#47560;&#52268;&#44032;&#51648;&#47196; &#45796;&#47480;
        &#49324;&#50857;&#51088;&#44032; &ldquo;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&rdquo;&#47484; &#48124;&#53944;&#47560;&#48660;
        &#54540;&#47019;&#54268; &#45236;&#48512; &#48143;
        &#50808;&#48512;&#50640;&#49436; &#54924;&#49324;&#44032;
        &#51228;&#44277;&#54616;&#45716;
        &#44032;&#51060;&#46300;&#46972;&#51064;&#50640; &#46384;&#46972;
        &#49324;&#50857;&#54616;&#45716; &#44163;&#50640;
        &#46041;&#51032;&#54620; &#44163;&#51004;&#47196;
        &#48389;&#45768;&#45796;. &#51060;&#46412;,
        &#49324;&#50857;&#51088;&#45716; &#44060;&#51064;&#51221;&#48372;
        &#49444;&#51221;&#50640; &#46384;&#46972; &#48376;&#51064;&#51032;
        &#49324;&#50857;&#51088; &#53080;&#53584;&#52768;&#47484; &#48380;
        &#49688; &#51080;&#45716; &#45824;&#49345;&#51012;
        &#51228;&#54620;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >6) &#49324;&#50857;&#51088;&#45716; &ldquo;&#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&rdquo; &#48143; &#44536;&#47196;
        &#51064;&#54644; &#48156;&#49373;&#54616;&#45716;
        &#51068;&#52404;&#51032; &#49552;&#54644;&#48176;&#49345;&#50640;
        &#45824;&#54644; &#51204;&#51201;&#51004;&#47196;
        &#52293;&#51076;&#51012; &#48512;&#45812;&#54616;&#47728;,
        &#54924;&#49324;&#45716; &#51060;&#50640; &#45824;&#54644;
        &#50612;&#46496;&#54620; &#52293;&#51076;&#46020; &#51648;&#51648;
        &#50506;&#49845;&#45768;&#45796;. &#48708;&#47197;
        &#51032;&#47924;&#45716; &#50500;&#45768;&#45208;,
        &#54924;&#49324;&#45716; &#46021;&#51088;&#51201;
        &#44208;&#51221;&#50640; &#46384;&#46972;
        &#50616;&#51228;&#46304;&#51648; &#49324;&#50857;&#51088;
        &#53080;&#53584;&#52768;&#47484; &#44160;&#53664;, &#44048;&#46021;
        &#48143; &#49325;&#51228;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.vx4i4rmgpkrg">
      <span class="c5">8. &#44552;&#51648; &#54665;&#50948;</span>
    </h3>
    <p class="c3">
      <span class="c2"
        >&#49324;&#50857;&#51088;&#45716; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#50640;&#49436;
        &#47749;&#49884;&#54620; &#51228;&#54620; &#49324;&#54637;&#44284;
        &#45908;&#48520;&#50612; &#45796;&#51020;&#51032;
        &#54665;&#50948;&#47484; &#49340;&#44032;&#45716; &#45936;
        &#46041;&#51032;&#54633;&#45768;&#45796;. &#48376;
        &#51060;&#50857;&#50557;&#44288;&#50640;&#49436;
        &#47749;&#49884;&#51201;&#51004;&#47196;
        &#54728;&#50857;&#54616;&#45716; &#44221;&#50864;&#47484;
        &#51228;&#50808;&#54616;&#44256;, &#54924;&#49324;&#51032;
        &#49324;&#51204; &#49436;&#47732; &#46041;&#51032; &#50630;&#51060;
        &#48124;&#53944;&#47560;&#48660; IP &#53080;&#53584;&#52768; &#48143;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#45716;
        &#54665;&#50948;&#45716; &#50628;&#44201;&#55176;
        &#44552;&#51648;&#46104;&#47728;, &#51060;&#47084;&#54620;
        &#54665;&#50948;&#44032; &#48156;&#49373;&#54624; &#44221;&#50864;,
        &#48376; &#51060;&#50857;&#50557;&#44288;&#50640;&#49436;
        &#48512;&#50668;&#54620; &#46972;&#51060;&#49440;&#49828;
        &#49324;&#50857; &#44428;&#54620;&#51060;
        &#51333;&#47308;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c14 lst-kix_7ijqy4tra808-0 start">
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#48520;&#48277; &#46608;&#45716; &#48376;&#47000;
          &#51060;&#50857;&#48276;&#50948;&#47484; &#48279;&#50612;&#45212;
          &#47785;&#51201;&#51004;&#47196; &#46608;&#45716; &#48376;
          &#51060;&#50857;&#50557;&#44288; &#48143; &#53945;&#51221;
          &#49436;&#48708;&#49828;&#50640; &#44288;&#54620; &#51228;3&#51088;
          &#51060;&#50857;&#50557;&#44288;&#51032; &#44552;&#51648;
          &#51312;&#54637;&#51012; &#50948;&#48152;&#54616;&#50668;
          &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#45796;&#47480; &#49324;&#50857;&#51088;&#51032;
          &#49436;&#48708;&#49828; &#51060;&#50857;&#51012; &#48169;&#54644;,
          &#51473;&#45800; &#48143; &#51228;&#54620;&#54616;&#44256;,
          &#51060;&#50640; &#48512;&#51221;&#51201;&#51064;
          &#50689;&#54693;&#51012; &#51452;&#44144;&#45208;,
          &#49436;&#48708;&#49828; &#44592;&#45733;&#50640; &#49552;&#49345;,
          &#51109;&#50528;, &#44284;&#48512;&#54616;, &#54028;&#49552;&#51012;
          &#52488;&#47000;&#54616;&#45716; &#48169;&#49885;&#51004;&#47196;
          &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#49436;&#48708;&#49828; &#48372;&#50504;&#51012;
          &#55036;&#49552;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#50896;&#52824; &#50506;&#45716; &#44305;&#44256;, &#49828;&#54072;,
          &#44428;&#50976;, &#54032;&#52489; &#51088;&#47308;&#47484;
          &#46041;&#51032; &#50630;&#51060; &#51204;&#49569;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#54924;&#49324;&#50640;&#49436; &#51228;&#44277;&#54616;&#51648;
          &#50506;&#51008; &#47196;&#48391;, &#49828;&#54028;&#51060;&#45908;,
          &#53356;&#47204;&#47084;, &#49828;&#53356;&#47000;&#54140; &#48143;
          &#44592;&#53440; &#51088;&#46041;&#54868;&#46108;
          &#49688;&#45800;&#51060;&#45208;
          &#51064;&#53552;&#54168;&#51060;&#49828;&#47484;
          &#49324;&#50857;&#54616;&#50668; &#49436;&#48708;&#49828;&#50640;
          &#51217;&#44540;&#54616;&#44144;&#45208;
          &#45936;&#51060;&#53552;&#47484; &#52628;&#52636;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#49436;&#48708;&#49828; &#51068;&#48512;&#47484;
          &#50669;&#49444;&#44228;&#54616;&#44144;&#45208;
          &#49436;&#48708;&#49828;&#51032; &#50689;&#50669;,
          &#53080;&#53584;&#52768;, &#53076;&#46300;&#50640; &#45824;&#54620;
          &#51217;&#44540;&#51012; &#48169;&#51648;&#54616;&#44256;
          &#51228;&#54620;&#54616;&#44592; &#50948;&#54644;
          &#49444;&#44228;&#46108; &#51109;&#52824;&#47484;
          &#50864;&#54924;&#54616;&#44144;&#45208;
          &#54924;&#54588;&#54616;&#44256;, &#49548;&#49828;
          &#53076;&#46300;&#47484; &#52286;&#50500;&#45236;&#47140;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#45796;&#47480; &#49324;&#50857;&#51088;&#51032;
          &#44228;&#51221;&#51012; &#47924;&#45800;&#51004;&#47196;
          &#49324;&#50857;&#54616;&#44144;&#45208; &#51060;&#47484;
          &#49884;&#46020;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#54924;&#49324;&#50640;&#49436; &#49324;&#50857;&#54616;&#45716;
          &#53080;&#53584;&#52768; &#54596;&#53552;&#47553;
          &#44592;&#49696;&#51012; &#50864;&#54924;&#54616;&#44144;&#45208;
          &#51217;&#44540; &#44428;&#54620;&#51060; &#50630;&#45716;
          &#49436;&#48708;&#49828;&#51032; &#50689;&#50669; &#48143;
          &#44592;&#45733;&#50640; &#51217;&#44540;&#51012;
          &#49884;&#46020;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#49436;&#48708;&#49828;&#50640;&#49436;
          &#47749;&#49884;&#51201;&#51004;&#47196;
          &#54728;&#50857;&#54616;&#45716; &#44221;&#50864;&#47484;
          &#51228;&#50808;&#54616;&#44256;, &#48124;&#53944;&#47560;&#48660; IP
          &#53080;&#53584;&#52768; &#48143; &#49436;&#48708;&#49828;,
          &#44536;&#50640; &#54252;&#54632;&#46108; &#51068;&#52404;&#51032;
          &#51221;&#48372;&#47484;
          &#45796;&#50868;&#47196;&#46300;&#54616;&#45716;
          &#54665;&#50948;(&#54168;&#51060;&#51648; &#52880;&#49905;
          &#51228;&#50808;)</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#47924;&#45800;&#51004;&#47196; &#46608;&#45716; &#51228;3&#51088;
          &#44256;&#44061;&#44284; &#54632;&#44760;
          &#48124;&#53944;&#47560;&#48660; API&#50640;
          &#51217;&#44540;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#54924;&#49324;&#50752;&#51032; &#44288;&#44228; &#47749;&#49884;,
          &#54924;&#49324;&#51032; &#47749;&#49884;&#51201;&#51064;
          &#49436;&#47732; &#46041;&#51032; &#50630;&#51060; &#51228;&#54408;
          &#48143; &#49436;&#48708;&#49828;&#47484;
          &#54861;&#48372;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#44340;&#47213;&#55192;, &#54801;&#48149;, &#50948;&#54801; &#48143;
          &#49828;&#53664;&#53433; &#46321;&#50640;
          &#44032;&#45812;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#53440;&#51064;&#51060;&#45208; &#44592;&#44288; &#46608;&#45716;
          &#44536; &#44288;&#44228;&#47484; &#49324;&#52845;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#46041;&#51032; &#50630;&#51060; &#49324;&#51652;&#51012;
          &#52524;&#50689;&#54616;&#45716; &#46321; &#53440;&#51064;&#51032;
          &#52488;&#49345;&#44428;, &#49324;&#49373;&#54876;,
          &#51221;&#48372;&#48372;&#54840; &#44428;&#47532;&#47484;
          &#52840;&#54644;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#53440;&#51064;&#51032; &#53945;&#54728;&#44428;,
          &#49345;&#54364;&#44428;, &#50689;&#50629; &#48708;&#48128;,
          &#51200;&#51089;&#44428;, &#44592;&#53440; &#51648;&#51201;
          &#51116;&#49328;&#44428;, &#49548;&#50976;&#44428;&#51012;
          &#52840;&#54644;&#54616;&#45716; &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#44552;&#51204;&#51012; &#45824;&#44032;&#47196;
          &#48124;&#53944;&#47560;&#48660;&#51032; &#49324;&#51204;
          &#49436;&#47732; &#46041;&#51032; &#50630;&#51060;
          &#48124;&#53944;&#47560;&#48660; &#44228;&#51221;,
          &#47700;&#49884;&#51648;, &#49324;&#50857;&#51088;&#47749;,
          &#52828;&#44396; &#47553;&#53356;&#50640; &#45824;&#54620;
          &#51217;&#44540; &#44428;&#54620;&#51012; &#47588;&#47588;,
          &#51076;&#45824;, &#45824;&#50668;, &#44592;&#53440;
          &#48169;&#49885;&#51004;&#47196; &#54032;&#47588;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#54924;&#49324;&#51032; &#49324;&#51204; &#49436;&#47732;
          &#46041;&#51032; &#50630;&#51060; &#49324;&#50857;&#51088;
          &#53080;&#53584;&#52768; &#46608;&#45716;
          &#49436;&#48708;&#49828;&#50752;
          &#49345;&#54840;&#51089;&#50857;&#54624; &#49688; &#51080;&#45716;
          &#51228;3&#51088; &#50545;&#51012; &#44060;&#48156;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#48520;&#48277; &#46608;&#45716; &#47924;&#45800;&#51004;&#47196;
          &#49436;&#48708;&#49828;&#47484;
          &#51060;&#50857;&#54616;&#44144;&#45208; &#48376;
          &#51060;&#50857;&#50557;&#44288;&#51012;
          &#50948;&#48152;&#54616;&#45716; &#54665;&#50948;&#47484;
          &#44428;&#51109; &#46608;&#45716; &#52489;&#51652;&#54616;&#45716;
          &#54665;&#50948;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >iTunes &#50545; &#49828;&#53664;&#50612; &#49436;&#48708;&#49828;
          &#51060;&#50857;&#50557;&#44288; &#48143;
          &#50504;&#46300;&#47196;&#51060;&#46300; &#47560;&#53011;
          &#49436;&#48708;&#49828; &#51060;&#50857;&#50557;&#44288; &#46321;
          &#48124;&#53944;&#47560;&#48660;&#50640; &#45824;&#54620;
          &#47532;&#48624;&#47484; &#44172;&#49884;&#54624; &#46412;
          &#46608;&#45716; &#48376; &#49436;&#48708;&#49828;&#50640;
          &#51201;&#50857;&#46104;&#45716; &#51228;3&#51088;
          &#51060;&#50857;&#50557;&#44288;&#51012;
          &#51456;&#49688;&#54644;&#50556; &#54633;&#45768;&#45796;. &#50545;
          &#49828;&#53664;&#50612; &#47532;&#48624; &#51089;&#49457; &#49884;
          &#48124;&#53944;&#47560;&#48660;
          &#49324;&#50857;&#51088;&#47749;&#51012;
          &#45432;&#52636;&#54616;&#45716; &#44163;&#51008;
          &#50628;&#44201;&#55176; &#44552;&#51648;&#46104;&#47728;,
          &#51060;&#47484; &#50948;&#48152;&#54624; &#44221;&#50864;
          &#48124;&#53944;&#47560;&#48660; &#44228;&#51221;&#51060;
          &#49325;&#51228;&#46112; &#49688;&#46020;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <h3 class="c6" id="h.3p6g5khjj25r">
      <span class="c5"
        >9. &#49436;&#48708;&#49828; &#51060;&#50857;
        &#46972;&#51060;&#49440;&#49828;</span
      >
    </h3>
    <p class="c3">
      <span class="c2"
        >&#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#50640;&#44172;
        &#49436;&#48708;&#49828;&#51032; &#51068;&#48512;&#47196;
        &#51228;&#44277;&#46104;&#45716;
        &#49548;&#54532;&#53944;&#50920;&#50612;&#50640; &#45824;&#54620;
        &#44060;&#51064;&#51201;&#51060;&#44256;, &#51204;
        &#49464;&#44228;&#51201;&#51060;&#47728;, &#50577;&#46020;
        &#48520;&#44032;&#45733;&#54616;&#47728;, &#49324;&#50857;&#47308;
        &#50630;&#51060; &#51060;&#50857; &#44032;&#45733;&#54620;
        &#48708;&#46021;&#51216;&#51201; &#46972;&#51060;&#49440;&#49828;
        &#49324;&#50857; &#44428;&#54620;&#51012;
        &#48512;&#50668;&#54633;&#45768;&#45796;. &#54644;&#45817;
        &#46972;&#51060;&#49440;&#49828;&#45716; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#50640;&#49436;
        &#54728;&#50857;&#54616;&#45716; &#48276;&#50948;
        &#45236;&#50640;&#49436; &#54924;&#49324;
        &#49436;&#48708;&#49828;&#51032; &#54812;&#53469;&#51012;
        &#45572;&#47532;&#44592; &#50948;&#54620;
        &#47785;&#51201;&#51004;&#47196;&#47564;
        &#51228;&#44277;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >1) &#44228;&#51221; &#51221;&#48372;: &#49436;&#48708;&#49828;&#47484;
        &#51060;&#50857;&#54616;&#47140;&#47732; Mintmarble App&#50640;&#49436;
        &#54924;&#49324;&#44032; &#50836;&#44396;&#54616;&#45716;
        &#51208;&#52264;&#50640; &#46384;&#46972; &#44228;&#51221;&#51012;
        &#49373;&#49457;&#54616;&#44144;&#45208; &#51228; 3&#51088;
        &#54540;&#47019;&#54268;&#51012; &#53685;&#54644;
        &#47196;&#44536;&#51064;&#54644;&#50556; &#54633;&#45768;&#45796;.
        &#47196;&#44536;&#51064; &#51088;&#44201;&#51613;&#47749;&#50640;
        &#45824;&#54620; &#48372;&#50504; &#48143; &#44228;&#51221;&#51012;
        &#53685;&#54644; &#51060;&#47336;&#50612;&#51652; &#47784;&#46304;
        &#54665;&#50948;&#50640; &#45824;&#54620; &#52293;&#51076;&#51008;
        &#48376;&#51064;&#50640;&#44172; &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >&#49324;&#50857;&#51088;&#45716; &#49436;&#48708;&#49828;&#50752;
        &#44288;&#47144;&#54620; &#54532;&#47196;&#44536;&#47016;,
        &#49548;&#54532;&#53944;&#50920;&#50612;&#51032; &#51204;&#52404;
        &#46608;&#45716; &#51068;&#48512;&#47484; &#48373;&#51228;,
        &#51116;&#49373;&#49328;, &#51116;&#44172;&#49884;, &#48320;&#54805;,
        &#45796;&#50868;&#47196;&#46300;, &#51204;&#49569;, &#49688;&#51221;,
        &#51204;&#49884;, &#50669;&#49444;&#44228;, &#54032;&#47588; &#48143;
        &#54032;&#52489;, &#51076;&#45824;, &#45824;&#50668;, &#50577;&#46020;,
        &#48516;&#48176;, &#46972;&#51060;&#49440;&#49828;,
        &#49436;&#48652;&#46972;&#51060;&#49440;&#49828;, &#48512;&#45817;
        &#51060;&#50857; &#46321;&#51032; &#54665;&#50948;&#47484; &#54624;
        &#49688; &#50630;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.lxe568ae8l0h">
      <span class="c5"
        >10. &#52964;&#48036;&#45768;&#54000;
        &#44032;&#51060;&#46300;&#46972;&#51064;</span
      >
    </h3>
    <p class="c3">
      <span class="c2"
        >&#54924;&#49324;&#51032; &#52964;&#48036;&#45768;&#54000;
        &#44032;&#51060;&#46300;&#46972;&#51064;&#51008; &#47784;&#46304;
        &#49436;&#48708;&#49828; &#49324;&#50857;&#51088;&#44032;
        &#47560;&#51020;&#44751; &#51088;&#49888;&#51012;
        &#54364;&#54788;&#54616;&#45716; &#46041;&#49884;&#50640;
        &#54924;&#49324;&#51032; &#49436;&#48708;&#49828;&#47484;
        &#50504;&#51204;&#54616;&#44256; &#51600;&#44161;&#44172;
        &#51060;&#50857;&#54624; &#49688; &#51080;&#46020;&#47197;
        &#47560;&#47144;&#46108; &#44508;&#51221;&#51077;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >&#49688;&#49888;&#51012; &#50896;&#52824; &#50506;&#45716;
        &#45824;&#49345;&#50640;&#44172; &#47700;&#49884;&#51648;,
        &#53945;&#55176; &#48520;&#53132;&#44048;&#51012; &#51460; &#49688;
        &#51080;&#45716; &#45236;&#50857;&#51032; &#51204;&#49569;&#51008;
        &#49340;&#44032;&#49884;&#44592; &#48148;&#46989;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >&#51204;&#49569;&#54616;&#45716; &#47700;&#49884;&#51648;
        &#45236;&#50857;&#44284; &#45824;&#49345;&#51012;
        &#49888;&#51473;&#55176; &#44256;&#47140;&#54644;&#50556;
        &#54633;&#45768;&#45796;. &#45572;&#44396;&#45208;
        &#54868;&#47732;&#51012; &#52897;&#52376;&#54624; &#49688;&#45716;
        &#51080;&#51648;&#47564;, &#51060;&#47484;
        &#51200;&#51109;&#54616;&#44144;&#45208;
        &#52828;&#44396;&#46308;&#44284;
        &#44277;&#50976;&#54644;&#49436;&#45716; &#50504;
        &#46121;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >&#54633;&#48277;&#51201;&#51004;&#47196;
        &#51060;&#50857;&#54616;&#46020;&#47197; &#54633;&#45768;&#45796;.
        &#48124;&#53944;&#47560;&#48660;&#51012; &#53685;&#54644;
        &#48520;&#48277;&#54620; &#54665;&#50948;&#47484;
        &#49884;&#46020;&#54644;&#49436;&#45716; &#50504; &#46104;&#47728;,
        &#50500;&#47000;&#51032; &#53080;&#53584;&#52768;&#47484;
        &#51312;&#54924;&#54616;&#44144;&#45208; &#49436;&#47196;
        &#51452;&#44256;&#48155;&#51648; &#50506;&#50500;&#50556;
        &#54633;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c14 lst-kix_p93k25suvtvb-0 start">
      <li class="c1 li-bullet-0">
        <span class="c2">&#51020;&#46976;&#47932;</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#45432;&#52636; &#49324;&#51652; &#48143;
          &#48120;&#49457;&#45380;&#51088;(&#47564;18&#49464;
          &#48120;&#47564;)&#50752; &#44288;&#47144;&#46108;
          &#49440;&#51221;&#51201;&#51064; &#45236;&#50857;&#51032;
          &#53080;&#53584;&#52768;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#48120;&#49457;&#45380;&#51088;&#44032;
          &#49888;&#52404;&#51201;&#51004;&#47196;
          &#50948;&#54744;&#54616;&#44256; &#50976;&#54644;&#54620;
          &#54665;&#50948;&#50640; &#44032;&#45812;&#54616;&#45716;
          &#45236;&#50857;&#51032; &#53080;&#53584;&#52768;</span
        >
      </li>
      <li class="c1 li-bullet-0">
        <span class="c2">&#49324;&#49373;&#54876; &#52840;&#54644;</span>
      </li>
      <li class="c1 li-bullet-0"><span class="c2">&#50948;&#54801;</span></li>
      <li class="c1 li-bullet-0">
        <span class="c2"
          >&#44340;&#47213;&#55192; &#48143; &#46384;&#46028;&#47548;</span
        >
      </li>
      <li class="c1 li-bullet-0"><span class="c2">&#49324;&#52845;</span></li>
      <li class="c1 li-bullet-0"><span class="c2">&#51088;&#54644;</span></li>
    </ul>
    <p class="c3">
      <span class="c2"
        >&#49345;&#44592; &#44508;&#51221;&#51012;
        &#50948;&#48152;&#54616;&#45716; &#44221;&#50864;,
        &#53080;&#53584;&#52768; &#49325;&#51228; &#48143; &#44228;&#51221;
        &#51068;&#49884; &#51221;&#51648;, &#54693;&#54980;
        &#49436;&#48708;&#49828; &#51060;&#50857;&#51060;
        &#51228;&#54620;&#46112; &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#48376; &#44508;&#51221;&#51060; &#47560;&#47144;&#46108;
        &#48376;&#47000;&#51032; &#52712;&#51648;&#47484; &#51452;&#51032;
        &#44618;&#44172; &#44256;&#47140;&#54620; &#54980;,
        &#52384;&#51200;&#55176; &#51456;&#49688;&#54616;&#50668;
        &#51452;&#49884;&#44592; &#48148;&#46989;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;
        &#49324;&#50857;&#51088; &#52964;&#48036;&#45768;&#54000;&#50752;
        &#54632;&#44760; &#51032;&#44204;&#51012;
        &#44277;&#50976;&#54616;&#47728; &#44508;&#51221;&#51012;
        &#48320;&#44221;&#54616;&#44144;&#45208; &#52628;&#44032;&#54624;
        &#49688; &#51080;&#51004;&#47728;, &#50616;&#51228;&#45208;
        &#44277;&#51221;&#54616;&#44256; &#51068;&#44288;&#46108;
        &#48169;&#48277;&#51004;&#47196; &#44508;&#52825;&#51012;
        &#49884;&#54665;&#54616;&#44256;, &#54633;&#47532;&#51201;&#51064;
        &#54032;&#45800;&#50640; &#46384;&#46972; &#49345;&#54889;&#50640;
        &#44032;&#51109; &#51201;&#51208;&#54620; &#51312;&#52824;&#47484;
        &#52712;&#54624; &#49688; &#51080;&#46020;&#47197;
        &#52572;&#49440;&#51012; &#45796;&#54616;&#44256;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.b19d0348zze4">
      <span class="c5">11. &#54588;&#46300;&#48177;</span>
    </h3>
    <p class="c3">
      <span
        >&#49324;&#50857;&#51088;&#44032; &#51060;&#47700;&#51068;
        &#46608;&#45716; &#44592;&#53440; &#54805;&#49885;&#51004;&#47196;
        &#54924;&#49324; &#46608;&#45716; &#49436;&#48708;&#49828;&#50640;
        &#45824;&#54616;&#50668; &#51228;&#44277;&#54620;
        &#54588;&#46300;&#48177;, &#51228;&#50504;,
        &#50500;&#51060;&#46356;&#50612; &#48143; &#44592;&#53440;
        &#51221;&#48372;(&#51060;&#54616;
        &quot;&#54588;&#46300;&#48177;&quot;)&#45716; &#44592;&#48128;
        &#51088;&#47308;&#50640; &#54644;&#45817;&#54616;&#51648;
        &#50506;&#51004;&#47728;, &#54924;&#49324;&#51032;
        &#44256;&#50976;&#54620; &#51088;&#49328;&#51004;&#47196;
        &#44036;&#51452;&#46121;&#45768;&#45796;. &#51593;,
        &#54924;&#49324;&#45716; &#51228;&#44277;
        &#49324;&#50857;&#51088;&#50640;&#44172; &#50612;&#46496;&#54620;
        &#53685;&#48372;&#45208; &#48372;&#49345;&#51012;
        &#51228;&#44277;&#54616;&#51648; &#50506;&#44256;&#46020;
        &#49345;&#50629;&#51201; &#46608;&#45716; &#50668;&#53440;&#51032;
        &#47785;&#51201;&#51004;&#47196; &#54644;&#45817;
        &#54588;&#46300;&#48177;&#51012; &#51228;&#54620; &#50630;&#51060;
        &#51060;&#50857;&#54616;&#44256; &#48176;&#54252;&#54624; &#49688;
        &#51080;&#51004;&#47728;, &#49324;&#50857;&#51088;&#45716;
        &#51060;&#50640; &#45824;&#54644; &#51200;&#51089;&#44428; &#48143;
        &#51200;&#51089;&#51064;&#44201;&#44428;&#51012;
        &#54252;&#54632;&#54616;&#50668; &#50612;&#46496;&#54620;
        &#44428;&#47532;&#46020; &#54665;&#49324;&#54624; &#49688;
        &#50630;&#49845;&#45768;&#45796;. &#54924;&#49324;&#45716;
        &#50616;&#51228;&#45208; &#49324;&#50857;&#51088;&#51032;
        &#51032;&#44204;&#50640; &#44480; &#44592;&#50872;&#51060;&#44256;
        &#51080;&#51004;&#45208;, &#51060;&#50640; &#45824;&#54620;
        &#49548;&#50976;&#44428; &#48143; &#44592;&#53440;
        &#44428;&#47532;&#45208; &#44552;&#51204;&#51201;
        &#48372;&#49345;&#51012; &#50836;&#44396;&#54616;&#45716;
        &#47928;&#51032;&#45716; &#49340;&#44032; &#51452;&#49884;&#44592;
        &#48148;&#46989;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.soptcm3sfpgi">
      <span class="c5">12. &#54924;&#49324;&#51032; &#44428;&#47532;</span>
    </h3>
    <p class="c3">
      <span class="c2"
        >&#48376; &#49436;&#48708;&#49828;&#50640; &#45824;&#54620;
        &#47784;&#46304; &#49548;&#50976;&#44428; &#48143; &#44428;&#47532;,
        &#51648;&#48516;(&#49324;&#50857;&#51088;&#44032;
        &#51228;&#44277;&#54620; &#53080;&#53584;&#52768;&#45716;
        &#51228;&#50808;)&#51008; &#54924;&#49324; &#48143; &#54924;&#49324;
        &#46972;&#51060;&#49440;&#49828;&#51032; &#46021;&#51216;&#51201;
        &#51116;&#49328;&#51077;&#45768;&#45796;.
        &#49436;&#48708;&#49828;&#45716; &#51200;&#51089;&#44428;,
        &#49345;&#54364;&#44428; &#48143; &#44592;&#53440;
        &#48277;&#47456;&#51032; &#48372;&#54840;&#47484;
        &#48155;&#51004;&#47728;, &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51032; &#50612;&#46496;&#54620;
        &#51312;&#54637;&#46020; &#54924;&#49324;&#51032; &#49345;&#54840;
        &#48143; &#49345;&#54364;&#44428;, &#47196;&#44256;,
        &#46020;&#47700;&#51064;&#47749;, &#44592;&#53440;
        &#44256;&#50976;&#54620; &#48652;&#47004;&#46300;
        &#53945;&#51669;&#51012; &#51060;&#50857;&#54624; &#49688;
        &#51080;&#45716; &#44428;&#54620;&#51012;
        &#48512;&#50668;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#54924;&#49324; &#48143; &#49436;&#48708;&#49828;&#50640;
        &#44288;&#54620; &#54588;&#46300;&#48177;, &#51032;&#44204; &#48143;
        &#51228;&#50504;&#51008; &#49324;&#50857;&#51088;&#51032;
        &#51088;&#48156;&#51201; &#51032;&#51648;&#47196;
        &#51228;&#44277;&#46104;&#45716; &#44163;&#51060;&#47728;,
        &#54924;&#49324;&#50640;&#49436;
        &#51201;&#54633;&#54616;&#45796;&#44256; &#54032;&#45800;&#54624;
        &#44221;&#50864;, &#51228;&#44277;
        &#54924;&#49324;&#51088;&#50640;&#44172;
        &#53685;&#51648;&#54616;&#51648; &#50506;&#44256;&#46020;
        &#54644;&#45817; &#51032;&#44204;, &#54588;&#46300;&#48177; &#48143;
        &#51228;&#50504;&#51012; &#51088;&#50976;&#47213;&#44172;
        &#49324;&#50857;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.druwnemj7c2b">
      <span class="c5">13. &#44305;&#44256;&#51032; &#44172;&#51116;</span>
    </h3>
    <p class="c3">
      <span
        >&#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#51032;
        &#50868;&#50689;&#44284; &#44288;&#47144;&#54616;&#50668;
        &#49436;&#48708;&#49828; &#54868;&#47732;&#50640; &#51649;&#51217;
        &#46608;&#45716; &#51228;&#55092; &#44288;&#44228;&#50640;
        &#51080;&#45716; &#51228;3&#51088;&#47484; &#53685;&#54644;
        &#44305;&#44256;&#47484; &#44172;&#51116;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.5ffyrogqkvhv">
      <span class="c5">14. &#51200;&#51089;&#44428; &#51221;&#52293;</span>
    </h3>
    <p class="c3">
      <span class="c2"
        >1) &#48376;&#51064;&#51032; &#53080;&#53584;&#52768;&#44032;
        &#44288;&#47144;&#48277;&#50640; &#50948;&#48152;&#46104;&#45716;
        &#45236;&#50857;&#51012; &#54252;&#54632;&#54616;&#45716;
        &#44221;&#50864;, &#44288;&#47144;&#48277;&#51060; &#51221;&#54620;
        &#51208;&#52264;&#50640; &#46384;&#46972; &#54644;&#45817;
        &quot;&#44172;&#49884;&#47932;&quot;&#51032;
        &#44172;&#49884;&#51473;&#45800; &#48143; &#49325;&#51228;
        &#46321;&#51012; &#50836;&#52397;&#54624; &#49688;
        &#51080;&#51004;&#47728;, &#54924;&#49324;&#45716;
        &#44288;&#47144;&#48277;&#50640; &#46384;&#46972;
        &#51312;&#52824;&#47484; &#52712;&#54616;&#50668;&#50556;
        &#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >2) &#54924;&#49324;&#45716; &#51204;&#54637;&#50640; &#46384;&#47480;
        &#44428;&#47532;&#51088;&#51032; &#50836;&#52397;&#51060;
        &#50630;&#45716; &#44221;&#50864;&#46972;&#46020;
        &#44428;&#47532;&#52840;&#54644;&#44032; &#51064;&#51221;&#46112;
        &#47564;&#54620; &#49324;&#50976;&#44032; &#51080;&#44144;&#45208;
        &#44592;&#53440; &#54924;&#49324; &#51221;&#52293; &#48143;
        &#44288;&#47144;&#48277;&#50640; &#50948;&#48152;&#46104;&#45716;
        &#44221;&#50864;&#50640;&#45716; &#44288;&#47144;&#48277;&#50640;
        &#46384;&#46972; &#54644;&#45817; &#53080;&#53584;&#52768;&#50640;
        &#45824;&#54644; &#51076;&#49884;&#51312;&#52824; &#46321;&#51012;
        &#52712;&#54624; &#49688; &#51080;&#51004;&#47728;, &#51060;&#50640;
        &#45824;&#54644; &#50612;&#46496;&#54620; &#52293;&#51076;&#46020;
        &#51648;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#54596;&#50836;&#54620; &#44221;&#50864;,
        &#50668;&#47084; &#52264;&#47168; &#48152;&#48373;&#54644;&#49436;
        &#51200;&#51089;&#44428;&#51012; &#52840;&#54644;&#54616;&#45716;
        &#49324;&#50857;&#51088;&#44032; &#51080;&#45796;&#47732;,
        &#54644;&#45817; &#44228;&#51221;&#51012;
        &#51333;&#47308;&#49884;&#53420; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#48376; &#51312;&#50640; &#46384;&#47480;
        &#49464;&#48512;&#51208;&#52264;&#45716;
        &#44288;&#47144;&#48277;&#51060; &#44508;&#51221;&#54620;
        &#48276;&#50948; &#45236;&#50640;&#49436; &#54924;&#49324;&#44032;
        &#51221;&#54620;
        &#44172;&#49884;&#51473;&#45800;&#50836;&#52397;&#49436;&#48708;&#49828;&#50640;
        &#46384;&#47493;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.skjyzyro2hdq">
      <span class="c5">15. &#47732;&#52293; &#51312;&#54637;</span>
    </h3>
    <p class="c3">
      <span class="c2"
        >1) &#49436;&#48708;&#49828; &#51060;&#50857;
        &#44284;&#51221;&#50640;&#49436; &#48520;&#53132;&#54616;&#44256;,
        &#49440;&#51221;&#51201;&#51060;&#47728;,
        &#47784;&#50837;&#51201;&#51064; &#51088;&#47308;&#50640;
        &#45432;&#52636;&#46112; &#49688; &#51080;&#51004;&#47728;,
        &#49436;&#48708;&#49828;&#50640; &#51217;&#44540;&#54616;&#44256;
        &#51060;&#47484; &#51060;&#50857;&#54632;&#51004;&#47196;&#50024;
        &#51060;&#47084;&#54620; &#50948;&#54744; &#50836;&#49548;&#47484;
        &#48155;&#50500;&#46308;&#51060;&#45716; &#44163;&#50640;
        &#46041;&#51032;&#54633;&#45768;&#45796;. &#54924;&#49324;&#51032;
        &#50937;&#49324;&#51060;&#53944; &#46608;&#45716;
        &#49436;&#48708;&#49828;&#50640; &#45432;&#52636;&#46104;&#45716;
        &#53080;&#53584;&#52768;&#45716; &#54924;&#49324;&#51032;
        &#51032;&#44204;&#51012; &#48152;&#50689;&#54620; &#44163;&#51060;
        &#50500;&#45776; &#49688;&#46020; &#51080;&#51004;&#47728;,
        &#49436;&#48708;&#49828;&#51032; &#49324;&#50857;&#51088;&#44032;
        &#44172;&#49884;&#54620; &#50612;&#46496;&#54620;
        &#53080;&#53584;&#52768;&#46020;
        &#51648;&#50896;&#54616;&#44144;&#45208;
        &#51648;&#51648;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >2) &#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#53080;&#53584;&#52768;&#47484; &#50504;&#51204;&#54616;&#44172;
        &#48372;&#54840;&#54616;&#44592; &#50948;&#54644;
        &#54633;&#45817;&#54620; &#51312;&#52824;&#47484;
        &#49884;&#54665;&#54616;&#44256; &#51080;&#51004;&#45208;,
        &#54644;&#45817; &#53080;&#53584;&#52768;&#51032;
        &#45432;&#52636;&#47196; &#51064;&#54644; &#48156;&#49373;&#54624;
        &#49688; &#51080;&#45716; &#47784;&#46304; &#50948;&#54744;&#51012; 100%
        &#50756;&#48317;&#54616;&#44172; &#50696;&#48169;&#54624;
        &#49688;&#45716; &#50630;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#47732;&#52293; &#51312;&#54637;: &#44288;&#47144;
        &#48277;&#47456;&#50640;&#49436; &#54728;&#50857;&#54616;&#45716;
        &#52572;&#45824; &#48276;&#50948; &#45236;&#50640;&#49436;
        &#54924;&#49324;&#51032; &#49436;&#48708;&#49828; &#48143;
        &#50937;&#49324;&#51060;&#53944;, &#50545;, &#53080;&#53584;&#52768;,
        &#44592;&#53440; &#47784;&#46304; &#51088;&#47308;&#45716;
        &#50612;&#46496;&#54620; &#47749;&#49884;&#51201;,
        &#47925;&#49884;&#51201;, &#48277;&#51201; &#48372;&#51613;
        &#50630;&#51060; &quot;&#51080;&#45716; &#44536;&#45824;&#47196;&quot;
        &#49324;&#50857;&#51088;&#50640;&#44172;
        &#51228;&#44277;&#46121;&#45768;&#45796;. &#54924;&#49324;&#45716; (1)
        &#49436;&#48708;&#49828; &#48143; &#51088;&#47308;&#44032;
        &#49324;&#50857;&#51088;&#51032;
        &#50836;&#44396;&#49324;&#54637;&#50640;
        &#48512;&#54633;&#54616;&#44144;&#45208;
        &#51648;&#49549;&#51201;&#51004;&#47196; &#51473;&#45800;
        &#50630;&#51060;, &#51201;&#49884;&#50640;,
        &#50504;&#51204;&#54616;&#44172;, &#50724;&#47448; &#50630;&#51060;
        &#51060;&#50857; &#44032;&#45733;&#54616;&#47728;, (2)
        &#50937;&#49324;&#51060;&#53944;, &#49436;&#48708;&#49828;,
        &#51088;&#47308;&#51032; &#51060;&#50857;&#51012; &#53685;&#54644;
        &#54925;&#46301;&#54620; &#44208;&#44284;&#44032;
        &#54952;&#44284;&#51201;&#51060;&#47728;,
        &#51221;&#54869;&#54616;&#44256;, &#49888;&#47280;&#54624; &#49688;
        &#51080;&#51004;&#47728;, (3) &#50937;&#49324;&#51060;&#53944;,
        &#49436;&#48708;&#49828;, &#51088;&#47308;&#50640;&#49436;
        &#48156;&#44204;&#46104;&#45716; &#47784;&#46304; &#50724;&#47448;
        &#48143; &#44208;&#54632;&#51012; &#51593;&#49884;
        &#54644;&#44208;&#54624; &#49688; &#51080;&#51020;&#51012;
        &#48372;&#51613;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.9sdd80bnjcnt">
      <span class="c5">16. &#52293;&#51076; &#51228;&#54620;</span>
    </h3>
    <p class="c3">
      <span>1)</span
      ><span class="c2"
        >&#48277;&#47456;&#50640;&#49436; &#44552;&#51648;&#54616;&#45716;
        &#44221;&#50864;&#47484; &#51228;&#50808;&#54616;&#44256;,
        &#54924;&#49324; &#48143; &#54924;&#49324;&#51032;
        &#44277;&#44553;&#50629;&#52404;, &#44033;&#44033;&#51032;
        &#51076;&#50896;, &#51060;&#49324;, &#51649;&#50896;,
        &#45824;&#47532;&#51064;(&#51060;&#54616;
        &quot;&#47700;&#53440;&#50920;&#50612; &#51452;&#49885;&#54924;&#49324;
        &#52769;&quot;)&#51008; (1) &#49436;&#48708;&#49828; &#51060;&#50857;
        &#48143; &#51060;&#50857; &#48520;&#45733;, (2) &#49436;&#48708;&#49828;
        &#51228;&#44277; &#48143; &#49436;&#48708;&#49828;&#47484;
        &#53685;&#54644; &#51060;&#50857; &#44032;&#45733;&#54620;
        &#51088;&#47308;, (3) &#49436;&#48708;&#49828;
        &#49324;&#50857;&#51088;&#51032; &#54665;&#50948;&#47196;
        &#47568;&#48120;&#50516;&#50500; &#48156;&#49373;&#54616;&#45716;
        &#44036;&#51217;&#51201;, &#53945;&#48324;&#51201;,
        &#51669;&#48268;&#51201;, &#48512;&#49688;&#51201;,
        &#47784;&#48276;&#51201;, &#44208;&#44284;&#51201;
        &#49552;&#54644;&#50640; &#45824;&#54616;&#50668;
        &#50612;&#46496;&#54620; &#52293;&#51076;&#46020; &#51648;&#51648;
        &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >2) &#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#44032;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54616;&#50668;
        &#44592;&#45824;&#54616;&#45716; &#51060;&#51061;&#51012;
        &#50619;&#51648; &#47803;&#54616;&#44144;&#45208;
        &#49345;&#49892;&#54620;&#44163;&#50640; &#45824;&#54616;&#50668;
        &#52293;&#51076;&#51012; &#51648;&#51648;
        &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#46356;&#51648;&#53560; &#51088;&#49328; &#46321;&#51032;
        &#44032;&#52824; &#48320;&#46041;&#50640; &#45824;&#54616;&#50668;
        &#52293;&#51076;&#51012; &#51648;&#51648;
        &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#49436;&#48708;&#49828; &#51217;&#44540;&#51012; &#50948;&#54620;
        &#51228; 3&#51088; &#47196;&#44536;&#51064; &#51613;&#47749;
        &#49688;&#45800;, &#53448;&#51473;&#50521;&#54868;
        &#50900;&#47131;&#51032;
        &#54532;&#46972;&#51060;&#48727;&#53412;&#47484;
        &#44288;&#47532;&#54616;&#51648; &#50506;&#50500;
        &#48156;&#49373;&#54616;&#45716; &#47928;&#51228;&#50640;
        &#45824;&#54644; &#48373;&#44396; &#46321; &#47784;&#46304;
        &#52293;&#51076;&#51012; &#51648;&#51648;
        &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >5) &#48660;&#47197;&#52404;&#51064; &#48143; &#46356;&#51648;&#53560;
        &#51088;&#49328; &#44288;&#47144; &#44508;&#51228;&#50752;
        &#51221;&#52293;&#51008; &#54788;&#51116; &#48520;&#50756;&#51204;
        &#48143; &#48520;&#54869;&#49892;&#54616;&#47728;, &#44288;&#47144;
        &#44508;&#51228;&#45208; &#51221;&#52293;&#51032;
        &#48320;&#46041;&#51060; &#54924;&#49324;&#44032;
        &#51228;&#44277;&#54616;&#45716; &#49436;&#48708;&#49828;&#50640;
        &#50689;&#54693;&#51012; &#48120;&#52832; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.s9bklvx9bty3">
      <span class="c5">17. &#49436;&#48708;&#49828; &#51333;&#47308;</span>
    </h3>
    <p class="c3">
      <span class="c2"
        >1) &#49324;&#50857;&#51088;&#45716; &#50616;&#51228;&#46304;&#51648;
        &#49436;&#48708;&#49828; &#51060;&#50857;&#51012;
        &#51473;&#45800;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >2) &#49436;&#48708;&#49828; &#51060;&#50857; &#51473;&#45800; &#49884;
        &#49436;&#48708;&#49828;&#50752; &#44288;&#47144;&#46108;
        &#51221;&#48372;&#44032; &#49325;&#51228;&#46104;&#47728;
        &#48373;&#44396;&#44032; &#48520;&#44032;&#45733;
        &#54633;&#45768;&#45796;. &#45800;, &#48660;&#47197;&#52404;&#51064;
        &#44592;&#49696; &#53945;&#49457; &#49345;
        &#48660;&#47197;&#52404;&#51064; &#44288;&#47144;
        &#45936;&#51060;&#53552;&#45716; &#49325;&#51228;&#54624; &#49688;
        &#50630;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#51032;
        &#44592;&#45733;&#51012; &#50616;&#51228;&#46304;&#51648;
        &#52628;&#44032;, &#49688;&#51221; &#48143; &#49325;&#51228;&#54624;
        &#49688; &#51080;&#49845;&#45768;&#45796;. &#46608;&#54620;,
        &#49436;&#48708;&#49828;&#51032; &#51228;&#44277;&#51012;
        &#51068;&#49884; &#46608;&#45716;
        &#50689;&#44396;&#51201;&#51004;&#47196;
        &#51473;&#45800;&#54616;&#44144;&#45208; &#49352;&#47196;&#50868;
        &#51228;&#54620; &#49324;&#54637;&#51012; &#52628;&#44032;&#54624;
        &#49688;&#46020; &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &#49324;&#50857;&#51088; &#44228;&#51221;&#51032;
        &#49324;&#50857;&#51060;
        &#51333;&#47308;&#46104;&#45908;&#46972;&#46020;, &#51648;&#48520;
        &#51032;&#47924;&#45716; &#44536;&#45824;&#47196;
        &#50976;&#54952;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &#49436;&#48708;&#49828;&#44032; &#51333;&#47308; &#46608;&#45716;
        &#51473;&#45800;&#46104;&#45716; &#44221;&#50864;,
        &#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#50640;&#44172;
        &#51060;&#47484; &#50508;&#47532;&#44592; &#50948;&#54644;
        &#54633;&#45817;&#54620; &#45432;&#47141;&#51012;
        &#44592;&#50872;&#51060;&#44256;, &#49324;&#50857;&#51088;&#51032;
        &#53080;&#53584;&#52768;&#47484; &#48373;&#44396;&#54624; &#49688;
        &#51080;&#46020;&#47197; &#54801;&#51312;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c6" id="h.913d3tab1h49">
      <span class="c5">18. &#51068;&#48152; &#51312;&#54637;</span>
    </h3>
    <p class="c3">
      <span>1) &#50616;&#50612;:</span
      ><span class="c8"
        >&#48376; &#51060;&#50857;&#50557;&#44288;&#51032; &#44277;&#49885;
        &#50616;&#50612;&#45716; &#50689;&#50612;&#51060;&#47728;,
        &#54924;&#49324;&#44032; &#51060;&#50857;&#51088; &#48143;
        &#54924;&#50896;&#50640;&#44172; &#44592;&#53440;
        &#50616;&#50612;&#51032; &#48264;&#50669;&#48376;&#51012;
        &#51228;&#44277;&#54616;&#45716; &#44221;&#50864;&#50640;&#46020;,
        &#51060;&#50857;&#51088; &#48143; &#54924;&#50896;&#51008;
        &#48264;&#50669;&#48376;&#51060; &#51060;&#50857;&#51088; &#48143;
        &#54924;&#50896;&#51032; &#54200;&#51032;&#47484;
        &#50948;&#54616;&#50668; &#51228;&#44277;&#46104;&#50632;&#51012;
        &#49104;&#51060;&#47728; &#51060;&#50857;&#50557;&#44288;&#51032;
        &#50689;&#47928; &#48260;&#51204;&#51060;
        &#51060;&#50857;&#51088;&#50752; &#54924;&#49324;&#51032;
        &#44288;&#44228;&#50640;
        &#51201;&#50857;&#46108;&#45796;&#45716;&#45936;
        &#46041;&#51032;&#54633;&#45768;&#45796;. &#47564;&#51068;
        &#51060;&#50857;&#50557;&#44288; &#50689;&#47928;
        &#48260;&#51204;&#51032; &#45236;&#50857;&#44284;
        &#48264;&#50669;&#48376;&#51032; &#45236;&#50857; &#44036;&#50640;
        &#47784;&#49692;&#46104;&#45716; &#49324;&#54637;&#51060;
        &#51080;&#45716; &#44221;&#50864;&#50640;&#45716; &#50689;&#47928;
        &#48260;&#51204;&#51060; &#50864;&#49440;&#51201;&#51004;&#47196;
        &#51201;&#50857;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span>2</span
      ><span class="c2"
        >) &#48516;&#47532; &#51312;&#54637;: &#48277;&#50896;&#51032;
        &#54032;&#44208;&#51012; &#53685;&#54644; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51032; &#51068;&#48512;
        &#51312;&#54637;&#51060; &#51665;&#54665;&#51060;
        &#48520;&#44032;&#45733;&#54616;&#44144;&#45208;
        &#49892;&#54952;&#46104;&#45908;&#46972;&#46020;
        &#45208;&#47672;&#51648; &#51312;&#54637;&#51008;
        &#50976;&#54952;&#54616;&#44172;
        &#51201;&#50857;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >3) &#44428;&#47532;&#48520;&#54252;&#44592;: &#54924;&#49324;&#44032;
        &#48376; &#51060;&#50857;&#50557;&#44288;&#51012;
        &#51665;&#54665;&#54616;&#51648; &#50506;&#44144;&#45208;
        &#51665;&#54665;&#51012;
        &#50976;&#50696;&#54616;&#50688;&#45908;&#46972;&#46020;
        &#51060;&#45716; &#48376; &#50557;&#44288;&#51032; &#51665;&#54665;
        &#44428;&#47532;&#47484; &#54252;&#44592;&#54616;&#45716;
        &#44163;&#51004;&#47196; &#44036;&#51452;&#46104;&#51648;
        &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >4) &#50577;&#46020;: &#54924;&#49324;&#51032; &#49436;&#47732;
        &#46041;&#51032; &#50630;&#51060; &#49324;&#50857;&#51088;&#45716;
        &#48376; &#51060;&#50857;&#50557;&#44288;&#50640;&#49436;
        &#51221;&#54620; &#44428;&#47532; &#48143; &#51032;&#47924;&#47484;
        &#53440;&#51064;&#50640;&#44172; &#50577;&#46020;&#54624; &#49688;
        &#50630;&#49845;&#45768;&#45796;. &#45800;, &#51064;&#49688;
        &#54633;&#48337; &#48143; &#49436;&#48708;&#49828;
        &#47588;&#44033;&#51060; &#48156;&#49373;&#54616;&#45716;
        &#44221;&#50864;, &#54924;&#49324;&#45716;
        &#49324;&#50857;&#51088;&#51032; &#46041;&#51032; &#50630;&#51060;
        &#49324;&#50857;&#51088;&#50640; &#44032;&#51648;&#45716;
        &#54924;&#49324;&#51032; &#44428;&#47532; &#48143;
        &#51032;&#47924;&#47484; &#50577;&#46020;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c3">
      <span class="c2"
        >5) &#50756;&#51204;&#54633;&#51032;: &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51008;
        &#49436;&#48708;&#49828;&#50640; &#44288;&#54620;
        &#54924;&#49324;&#50752; &#49324;&#50857;&#51088; &#44036;&#51032;
        &#50756;&#51204;&#54616;&#44256; &#46021;&#51216;&#51201;&#51064;
        &#54633;&#51032;&#47484; &#45208;&#53440;&#45236;&#47728;,
        &#49436;&#48708;&#49828;&#50752; &#44288;&#47144;&#54616;&#50668;
        &#51060;&#51204;&#50640; &#52404;&#44208;&#46108; &#47784;&#46304;
        &#44228;&#50557;&#51012; &#45824;&#52404;&#54633;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#46412;&#46412;&#47196; &#48376;
        &#51060;&#50857;&#50557;&#44288;&#51012; &#49688;&#51221;&#54624;
        &#49688; &#51080;&#51004;&#47728;, &#46021;&#51088;&#51201;
        &#44208;&#51221;&#50640; &#51032;&#44144; &#49688;&#51221;
        &#49324;&#54637;&#51060; &#51473;&#45824;&#54616;&#45796;&#44256;
        &#54032;&#45800;&#54616;&#45716; &#44221;&#50864;,
        &#50937;&#54168;&#51060;&#51648; &#48176;&#45320; &#46608;&#45716;
        &#53685;&#51648; &#50976;&#54805;&#50640; &#46384;&#46972;
        &#51201;&#51208;&#54620; &#49688;&#45800;&#51012;
        &#49324;&#50857;&#54616;&#50668;
        &#49324;&#50857;&#51088;&#50640;&#44172;
        &#44256;&#51648;&#54633;&#45768;&#45796;.
        &#49324;&#50857;&#51088;&#44032; &#49688;&#51221;
        &#49324;&#54637;&#51060; &#51201;&#50857;&#46108;
        &#54980;&#50640;&#46020; &#49436;&#48708;&#49828;&#47484;
        &#44228;&#49549; &#51060;&#50857;&#54616;&#45716; &#44221;&#50864;,
        &#48320;&#44221; &#54980;&#51032;
        &#51060;&#50857;&#50557;&#44288;&#50640; &#46041;&#51032;&#47484;
        &#54620; &#44163;&#51004;&#47196;
        &#44036;&#51452;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <h4 class="c4" id="h.6gq89lngpneo">
      <span class="c7">&#48512;&#52825;</span>
    </h4>
    <p class="c3">
      <span class="c2"
        >&#51060; &#50557;&#44288;&#51008; 2023&#45380; n&#50900;
        n&#51068;&#48512;&#53552;
        &#51201;&#50857;&#46121;&#45768;&#45796;.</span
      >
    </p>
    <p class="c12"><span class="c2"></span></p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul.lst-kix_tlsisrlykxr1-8 {
  list-style-type: none;
}
.lst-kix_qq4o6gvqua8t-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_tlsisrlykxr1-7 {
  list-style-type: none;
}
.lst-kix_qq4o6gvqua8t-0 > li:before {
  content: "  ";
}
ul.lst-kix_tlsisrlykxr1-6 {
  list-style-type: none;
}
ul.lst-kix_tlsisrlykxr1-5 {
  list-style-type: none;
}
ul.lst-kix_tlsisrlykxr1-4 {
  list-style-type: none;
}
.lst-kix_qq4o6gvqua8t-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_tlsisrlykxr1-3 {
  list-style-type: none;
}
ul.lst-kix_tlsisrlykxr1-2 {
  list-style-type: none;
}
ul.lst-kix_tlsisrlykxr1-1 {
  list-style-type: none;
}
.lst-kix_p93k25suvtvb-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_tlsisrlykxr1-0 {
  list-style-type: none;
}
.lst-kix_qq4o6gvqua8t-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qq4o6gvqua8t-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_p93k25suvtvb-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yv29uvd4bsqy-4 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-4;
}
.lst-kix_qq4o6gvqua8t-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_p93k25suvtvb-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_p93k25suvtvb-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_p93k25suvtvb-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qq4o6gvqua8t-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qq4o6gvqua8t-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_yv29uvd4bsqy-6.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-6 0;
}
.lst-kix_p93k25suvtvb-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_p93k25suvtvb-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yv29uvd4bsqy-3 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-3;
}
.lst-kix_p93k25suvtvb-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_twpgf765a21o-8 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-8;
}
ol.lst-kix_twpgf765a21o-2.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-2 0;
}
ol.lst-kix_twpgf765a21o-5.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-5 0;
}
ul.lst-kix_qq4o6gvqua8t-1 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-0 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-7 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-6 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-8 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-3 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-2 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-5 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-0 {
  list-style-type: none;
}
ul.lst-kix_qq4o6gvqua8t-4 {
  list-style-type: none;
}
.lst-kix_yv29uvd4bsqy-1 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-1, lower-latin) ". ";
}
ol.lst-kix_yv29uvd4bsqy-1 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-2 {
  list-style-type: none;
}
.lst-kix_yv29uvd4bsqy-0 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-0, decimal) ". ";
}
ol.lst-kix_yv29uvd4bsqy-3 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-4 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-5 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-6 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-7 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-8 {
  list-style-type: none;
}
ol.lst-kix_yv29uvd4bsqy-7.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-7 0;
}
.lst-kix_yv29uvd4bsqy-7 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-7;
}
.lst-kix_yv29uvd4bsqy-1 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-1;
}
.lst-kix_7ijqy4tra808-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_7ijqy4tra808-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_twpgf765a21o-1.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-1 0;
}
ol.lst-kix_yv29uvd4bsqy-0.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-0 0;
}
.lst-kix_twpgf765a21o-0 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-0;
}
.lst-kix_yv29uvd4bsqy-6 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-6;
}
.lst-kix_twpgf765a21o-0 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-0, decimal) ") ";
}
ol.lst-kix_twpgf765a21o-8.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-8 0;
}
.lst-kix_yv29uvd4bsqy-0 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-0;
}
.lst-kix_twpgf765a21o-5 > li:before {
  content: "(" counter(lst-ctn-kix_twpgf765a21o-5, lower-roman) ") ";
}
ol.lst-kix_twpgf765a21o-7.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-7 0;
}
ol.lst-kix_twpgf765a21o-0.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-0 0;
}
.lst-kix_twpgf765a21o-3 > li:before {
  content: "(" counter(lst-ctn-kix_twpgf765a21o-3, decimal) ") ";
}
.lst-kix_twpgf765a21o-7 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-7, lower-latin) ". ";
}
.lst-kix_twpgf765a21o-4 > li:before {
  content: "(" counter(lst-ctn-kix_twpgf765a21o-4, lower-latin) ") ";
}
.lst-kix_twpgf765a21o-8 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-8, lower-roman) ". ";
}
ol.lst-kix_yv29uvd4bsqy-1.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-1 0;
}
.lst-kix_twpgf765a21o-1 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-1, lower-latin) ") ";
}
.lst-kix_7ijqy4tra808-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_7ijqy4tra808-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_twpgf765a21o-2 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-2, lower-roman) ") ";
}
.lst-kix_7ijqy4tra808-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_7ijqy4tra808-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_7ijqy4tra808-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_twpgf765a21o-1 {
  list-style-type: none;
}
.lst-kix_twpgf765a21o-3 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-3;
}
ol.lst-kix_twpgf765a21o-0 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-3 {
  list-style-type: none;
}
.lst-kix_twpgf765a21o-6 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-6;
}
ol.lst-kix_twpgf765a21o-2 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-5 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-4 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-7 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-6 {
  list-style-type: none;
}
.lst-kix_7ijqy4tra808-6 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_twpgf765a21o-8 {
  list-style-type: none;
}
.lst-kix_7ijqy4tra808-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_twpgf765a21o-6 > li:before {
  content: "" counter(lst-ctn-kix_twpgf765a21o-6, decimal) ". ";
}
.lst-kix_qq4o6gvqua8t-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_tv6o449g2qks-3 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-4 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-1 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-2 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-6.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-6 0;
}
ul.lst-kix_tv6o449g2qks-7 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-8 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-5 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-6 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-3 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-2 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-1 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-0 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-7 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-6 {
  list-style-type: none;
}
ul.lst-kix_tv6o449g2qks-0 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-5 {
  list-style-type: none;
}
ul.lst-kix_7ijqy4tra808-4 {
  list-style-type: none;
}
.lst-kix_twpgf765a21o-4 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-4;
}
ul.lst-kix_7ijqy4tra808-8 {
  list-style-type: none;
}
ol.lst-kix_twpgf765a21o-3.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-3 0;
}
.lst-kix_tlsisrlykxr1-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_tlsisrlykxr1-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_twpgf765a21o-5 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-5;
}
.lst-kix_twpgf765a21o-2 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-2;
}
ol.lst-kix_yv29uvd4bsqy-8.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-8 0;
}
.lst-kix_tlsisrlykxr1-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_tlsisrlykxr1-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_tlsisrlykxr1-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_yv29uvd4bsqy-5.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-5 0;
}
.lst-kix_tlsisrlykxr1-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_yv29uvd4bsqy-2.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-2 0;
}
.lst-kix_tv6o449g2qks-0 > li:before {
  content: "  ";
}
.lst-kix_tv6o449g2qks-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_p93k25suvtvb-0 {
  list-style-type: none;
}
.lst-kix_yv29uvd4bsqy-2 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-2, lower-roman) ". ";
}
.lst-kix_tv6o449g2qks-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_tv6o449g2qks-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yv29uvd4bsqy-3 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-3, decimal) ". ";
}
.lst-kix_tv6o449g2qks-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_yv29uvd4bsqy-4 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-4, lower-latin) ". ";
}
.lst-kix_tv6o449g2qks-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_tv6o449g2qks-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yv29uvd4bsqy-6 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-6, decimal) ". ";
}
.lst-kix_tv6o449g2qks-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_yv29uvd4bsqy-5 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-5, lower-roman) ". ";
}
ul.lst-kix_p93k25suvtvb-1 {
  list-style-type: none;
}
.lst-kix_twpgf765a21o-1 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-1;
}
ol.lst-kix_yv29uvd4bsqy-3.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-3 0;
}
ul.lst-kix_p93k25suvtvb-2 {
  list-style-type: none;
}
.lst-kix_tlsisrlykxr1-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_tlsisrlykxr1-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_twpgf765a21o-7 > li {
  counter-increment: lst-ctn-kix_twpgf765a21o-7;
}
ul.lst-kix_p93k25suvtvb-3 {
  list-style-type: none;
}
.lst-kix_yv29uvd4bsqy-8 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-8, lower-roman) ". ";
}
ul.lst-kix_p93k25suvtvb-4 {
  list-style-type: none;
}
ul.lst-kix_p93k25suvtvb-5 {
  list-style-type: none;
}
ul.lst-kix_p93k25suvtvb-6 {
  list-style-type: none;
}
.lst-kix_tlsisrlykxr1-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_p93k25suvtvb-7 {
  list-style-type: none;
}
.lst-kix_tv6o449g2qks-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_yv29uvd4bsqy-7 > li:before {
  content: "" counter(lst-ctn-kix_yv29uvd4bsqy-7, lower-latin) ". ";
}
ul.lst-kix_p93k25suvtvb-8 {
  list-style-type: none;
}
.lst-kix_p93k25suvtvb-8 > li:before {
  content: "\0025a0   ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
ol.lst-kix_twpgf765a21o-4.start {
  counter-reset: lst-ctn-kix_twpgf765a21o-4 0;
}
ol.lst-kix_yv29uvd4bsqy-4.start {
  counter-reset: lst-ctn-kix_yv29uvd4bsqy-4 0;
}
.lst-kix_yv29uvd4bsqy-2 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-2;
}
.lst-kix_yv29uvd4bsqy-8 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-8;
}
.lst-kix_yv29uvd4bsqy-5 > li {
  counter-increment: lst-ctn-kix_yv29uvd4bsqy-5;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c15 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c9 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  margin-left: 36pt;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c10 {
  -webkit-text-decoration-skip: none;
  color: #000;
  font-weight: 400;
  text-decoration: line-through;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c1 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  color: #161519;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c12 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c0 {
  color: #161519;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: Arial;
  font-style: normal;
}
.c21 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 23pt;
  font-family: Arial;
  font-style: normal;
}
.c5 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: Arial;
  font-style: normal;
}
.c2 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c7 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c17 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c19 {
  padding-top: 24pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  padding-top: 12pt;
  padding-bottom: 2pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c16 {
  padding-top: 14pt;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c6 {
  padding-top: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c20 {
  background-color: #fff;
  max-width: 800pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c14 {
  padding: 0;
  margin: 0;
}
.c8 {
  color: #161519;
  font-size: 10.5pt;
}
.c13 {
  color: #161519;
}
.c18 {
  height: 11pt;
}
.title {
  padding-top: 0;
  color: #000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0;
  color: #666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
p {
  margin: 0;
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
h1 {
  padding-top: 20pt;
  color: #000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>