<template>
  <div class="doc-content c29">
	<p class="c25 title" id="h.bi16qg28kw6p" style="text-decoration:none;">
      <span class="c28">개인정보 처리방침</span>
    </p>
    <p class="c15">
      <span class="c3"
        >Metaware PTE, Ltd. &#48143; &#51088;&#54924;&#49324; (&#51060;&#54616;
        &ldquo;&#50864;&#47532;&ldquo;, &ldquo;&#45817;&#49324;&ldquo;
        &#46608;&#45716; &ldquo;&#54924;&#49324;&ldquo;&#46972;
        &#54633;&#45768;&#45796;)&#45716; &#44060;&#51064;&#51221;&#48372;
        &#48143; &#49324;&#50857;&#51088; &#51221;&#48372;&#47484;
        &#48372;&#54840;&#54616;&#44256; &#51221;&#48372;&#47484;
        &#49688;&#51665; &#48143; &#52376;&#47532;&#54616;&#45716;
        &#48169;&#49885;&#50640; &#45824;&#54644;
        &#53804;&#47749;&#54616;&#44172; &#54616;&#44592; &#50948;&#54644;
        &#52572;&#49440;&#51012; &#45796;&#54616;&#44256;
        &#51080;&#49845;&#45768;&#45796;. &#48376; &#44256;&#51648;
        (&#51060;&#54616;
        &ldquo;&#44060;&#51064;&#51221;&#48372;&#48372;&#54840;&#51221;&#52293;&ldquo;)&#45716;
        &#49324;&#50857;&#51088;&#47196;&#48512;&#53552; &#46608;&#45716;
        &#49324;&#50857;&#51088;&#50640;&#44172;
        &#49688;&#51665;&#54616;&#45716; &#51221;&#48372;&#47484;
        &#52376;&#47532;&#54616;&#44592; &#50948;&#54620; &#48169;&#52840;
        &#48143; &#44288;&#54665;&#51012;
        &#49444;&#47749;&#54633;&#45768;&#45796;. &#51060;&#45716;
        &#54924;&#49324;&#44032; &#50868;&#50689;&#54616;&#44256; &#48376;
        &#44060;&#51064;&#51221;&#48372;
        &#48372;&#54840;&#51221;&#52293;&#50640; &#44288;&#47144;&#46108;
        Mintmarble &#49436;&#48708;&#49828;&#50640;
        &#51201;&#50857;&#46121;&#45768;&#45796;. &#50696;&#47484;
        &#46308;&#50612; Mintmarble &#44172;&#51076; (&#51060;&#54616;
        &ldquo;&#44172;&#51076;&ldquo;) &#48143; Creator Center,
        &#50728;&#46972;&#51064; &#52964;&#48036;&#45768;&#54000;
        (&#53685;&#54633;&#51201;&#51004;&#47196;
        &ldquo;&#49436;&#48708;&#49828;&ldquo;&#46972; &#52845;&#54632;)
        &#51077;&#45768;&#45796;. &#44480;&#54616;&#45716; &#48376;
        &#44060;&#51064;&#51221;&#48372;&#48372;&#54840;&#51221;&#52293;
        &#45236;&#50857;&#51012; &#54869;&#51064;&#54616;&#49884;&#44256;
        &#52628;&#44032;&#51201;&#51004;&#47196;
        &#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
        &#49688;&#51665; &#48143; &#52376;&#47532;&#50640; &#45824;&#54644;
        &#44256;&#51648;&#54616;&#45716;
        &#44277;&#51648;&#49324;&#54637;&#51012; &#54869;&#51064;
        &#54616;&#49884;&#44256;, &#44060;&#51064;&#51221;&#48372;&#47484;
        &#49324;&#50857;&#54616;&#45716; &#48169;&#48277;, &#51060;&#50976;
        &#46608;&#45716; &#45936;&#51060;&#53552; &#48372;&#54840;&#48277;
        &#50504;&#50640; &#49324;&#50857;&#51088;&#51032;
        &#44428;&#47532;&#44032; &#47924;&#50631;&#51064;&#51648;
        &#51064;&#51648;&#54616;&#49884;&#44600;
        &#48148;&#46989;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.1d3ysdl9ourd">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372;&#51032; &#49688;&#51665;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#48376;
        &#44060;&#51064;&#51221;&#48372;&#48372;&#54840;&#51221;&#52293;&#51032;
        &lsquo;&#44060;&#51064;&#51221;&#48372;&rsquo;&#46976;
        &#49885;&#48324;&#46108; &#54841;&#51008;
        &#49885;&#48324;&#46104;&#51648; &#50506;&#51008;
        &#44060;&#51064;&#50640; &#45824;&#54620; &#47784;&#46304;
        &#51221;&#48372;&#47484; &#51032;&#48120;&#54633;&#45768;&#45796;.
        &#49436;&#48708;&#49828; &#51060;&#50857; &#49884;
        &#45817;&#49324;&#45716; &#45796;&#51020;&#44284; &#44057;&#51060;
        &#49324;&#50857;&#51088;&#44032; &#45817;&#49324;
        &#49436;&#48708;&#49828;&#47484; &#51060;&#50857;&#54624; &#46412;
        &#49688;&#51665;&#46104;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#47484;
        &#52376;&#47532;&#54616;&#44256; &#51080;&#49845;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_av87ir40dkfm-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >Mintmarble &#44172;&#51076; &#47196;&#44536;&#51064;&#51012;
          &#54616;&#44592; &#50948;&#54620; &#51228;3&#51088;
          &#54540;&#47019;&#54268;&#50640; (Google, Apple &#46321;)
          &#44032;&#51077;&#54620; &#51221;&#48372; (&#50696;: &#51064;&#51613;
          &#53664;&#53360; &#48156;&#44553;) &#48143; &#47784;&#48148;&#51068;
          &#50545;&#51012; &#53685;&#54620; &#44172;&#51076;
          &#51217;&#49549;&#51012; &#50948;&#54644; &#44032;&#51077;&#54620;
          &#51221;&#48372; (Google)</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c3"
          >Mintmarble &#44277;&#49885; &#49324;&#51060;&#53944;
          &#51060;&#50857;&#51012; &#50948;&#54620;
          &#53448;&#51473;&#50521;&#54868; &#50900;&#47131; &#51452;&#49548;
          &#48143; &#44592;&#49696;&#51201;&#51064; &#51221;&#48372; (&#50696;:
          &#48652;&#46972;&#50864;&#51200; &#51333;&#47448;, OS,
          &#45800;&#47568;&#44592; &#51333;&#47448;, IP &#51452;&#49548;).</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49324;&#50857;&#51088;&#51032; &#47928;&#51032; &#48143;
          &#48520;&#47564; &#52376;&#47532;&#47484; &#50948;&#54620;
          &#51221;&#48372; (&#51060;&#47700;&#51068;, &#51064;&#44172;&#51076;
          ID &#46608;&#45716; &#45769;&#45348;&#51076; &#48143;
          &#49324;&#50857;&#51088; &#51060;&#49800;&#50640; &#45824;&#54620;
          &#51221;&#48372;)</span
        >
      </li>
    </ul>
    <ul class="c0 lst-kix_vsncjlenrete-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >Mintmarble &#52964;&#48036;&#45768;&#54000; &#51060;&#50857; &#49884;
          &#54596;&#50836;&#54620; &#51221;&#48372; (&#51060;&#47492; &#48143;
          &#54532;&#47196;&#54596; &#49324;&#51652;, &#54252;&#49828;&#54021;
          &#45216;&#51676; &#48143; IP &#51217;&#49549; &#45216;&#51676;)</span
        >
      </li>
    </ul>
    <p class="c20"><span class="c13 c3"></span></p>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#50948;&#50640; &#51221;&#48372;
        &#47785;&#47197;&#51012; &#49324;&#50857;&#51088;&#44032;
        &#51649;&#51217; &#51228;&#44277;&#54616;&#45716;
        &#44060;&#51064;&#51221;&#48372; &#54637;&#47785; &#46608;&#45716;
        &#49436;&#48708;&#49828; &#49324;&#50857;&#51088; &#48143;
        &#51228;3&#51088; &#44592;&#44288; (&#50696;: Google, Apple)&#51012;
        &#53685;&#54644; &#51221;&#48372;&#47484;
        &#49688;&#51665;&#54633;&#45768;&#45796;.
        &#51228;3&#51088;&#47196;&#48512;&#53552; &#49688;&#51665;&#46108;
        &#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
        &#49345;&#49464; &#45236;&#50857;&#51008; &#48512;&#47197;
        II&#50640;&#49436; &#54869;&#51064;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;. &#51221;&#48372;&#44032;
        &#50630;&#51012; &#44221;&#50864;, &#54924;&#49324;&#45716;
        &#44480;&#54616;&#44032; &#50836;&#52397;&#54620; &#47784;&#46304;
        &#49436;&#48708;&#49828;&#47484; &#51228;&#44277;&#54624; &#49688;
        &#50630;&#51004;&#47728; &#49436;&#48708;&#49828;&#51032;
        &#52264;&#51060;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#51221;&#48372;&#50752; &#44288;&#47144;&#46121;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#48124;&#44048;&#51221;&#48372;
        &#46608;&#45716; &#49324;&#50857;&#51088;&#51032; &#48276;&#51396;
        &#50976;&#51396; &#54032;&#44208; &#48143; &#48276;&#51396;
        &#44288;&#47144;&#46108; &#51221;&#48372;&#47484;
        &#49688;&#51665;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.
        (&#50696;: &#51064;&#51333; &#46608;&#45716; &#48124;&#51313;
        &#51221;&#48372;, &#51221;&#52824;&#51201; &#44204;&#54644;,
        &#51333;&#44368; &#46608;&#45716; &#52384;&#54617;&#51201;
        &#49888;&#45392;, &#45432;&#46041;&#51312;&#54633;
        &#47716;&#48260;&#49901;, &#50976;&#51204;&#51088; &#51221;&#48372;,
        &#49373;&#52404; &#51221;&#48372;, &#44148;&#44053; &#51221;&#48372;
        &#46608;&#45716; &#49457;&#49373;&#54876; &#48143;
        &#49457;&#51201;&#51648;&#54693;)</span
      >
    </p>
    <h3 class="c10" id="h.zfx7xko0hceg">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372;&#51032; &#51060;&#50857;
        &#47785;&#51201;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#45796;&#51020;&#51032;
        &#47785;&#51201;&#51012; &#50948;&#54616;&#50668;
        &#44060;&#51064;&#51221;&#48372;&#47484;
        &#51060;&#50857;&#54633;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_xr4wbtfr7i3x-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49436;&#48708;&#49828; &#51228;&#44277;&#51012; &#54616;&#44592;
          &#50948;&#54632;: &#54924;&#49324;&#45716; &#44228;&#51221;
          &#44060;&#49444;, &#49324;&#50857;&#51088; &#44228;&#51221;
          &#50976;&#51648;, &#51201;&#54633;&#54620; &#49436;&#48708;&#49828;
          &#51228;&#44277; &#46608;&#45716; &#49436;&#48708;&#49828;
          &#47582;&#52644;&#54868; &#48143; &#52964;&#48036;&#45768;&#54000;
          &#44592;&#45733; &#51228;&#44277;&#51012; &#54616;&#44592;
          &#50948;&#54616;&#50668; &#49324;&#50857;&#51088;&#51032;
          &#51221;&#48372;&#47484;
          &#49324;&#50857;&#54633;&#45768;&#45796;;</span
        >
      </li>
    </ul>
    <ul class="c0 lst-kix_3o35opftnzi0-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#45908; &#51201;&#54633;&#54620; &#49436;&#48708;&#49828;&#47484;
          &#51228;&#44277;&#54616;&#44592; &#50948;&#54632;:
          &#54924;&#49324;&#45716; &#49436;&#48708;&#49828;&#51032;
          &#50976;&#51648;, &#44060;&#49440;, &#44288;&#47532;&#47484;
          &#50948;&#54616;&#50668; (&#53944;&#47084;&#48660; &#49800;&#54021;,
          &#45936;&#51060;&#53552; &#48516;&#49437; &#48143;
          &#53580;&#49828;&#53944; &#54252;&#54632;)
          &#49324;&#50857;&#51088;&#51032; &#51221;&#48372;&#47484;
          &#49324;&#50857;&#54633;&#45768;&#45796;;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49436;&#48708;&#49828; &#48143; &#49324;&#50857;&#51088;&#51032;
          &#50504;&#51204;&#49457; &#54869;&#48372;&#47484; &#50948;&#54632;:
          &#54532;&#47196;&#44536;&#47016;&#51012;
          &#48520;&#48277;&#51201;&#51004;&#47196;
          &#49324;&#50857;&#54616;&#45716; &#49324;&#50857;&#51088;&#47484;
          &#44048;&#51648;&#54616;&#45716; &#44163;&#46020;
          &#54252;&#54632;&#46121;&#45768;&#45796;. &#46608;&#54620;
          &#49324;&#44592;, &#50724;&#50857;, &#48372;&#50504; &#50948;&#54744;,
          &#44592;&#49696;&#51201; &#51109;&#50528;&#47484; &#48169;&#51648;
          &#48143; &#45824;&#51025;&#54616;&#44592; &#50948;&#54616;&#50668;
          &#49324;&#50857;&#51088;&#51032; &#51221;&#48372;&#47484;
          &#49324;&#50857;&#54633;&#45768;&#45796;;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#51204;&#51088;&#50864;&#54200; &#48372;&#45236;&#44592;
          &#50948;&#54632;: &#54924;&#49324;&#45716;
          &#49324;&#50857;&#51088;&#51032; &#47928;&#51032;/&#50836;&#52397;
          &#49324;&#54637;&#51012; &#51025;&#45824;&#54616;&#44592;
          &#50948;&#54616;&#50668; &#46608;&#45716; &#49436;&#48708;&#49828;
          &#51060;&#50857;&#50640; &#45824;&#54644; &#44277;&#51648;&#47484;
          &#54616;&#44592; &#50948;&#54616;&#50668;
          &#49324;&#50857;&#51088;&#50640;&#44172;
          &#51060;&#47700;&#51068;&#51012; &#48372;&#45244; &#49688;
          &#51080;&#49845;&#45768;&#45796;;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#44288;&#47144; &#48277;&#44508;&#49345;&#51032;
          &#48277;&#47456;&#51012; &#51456;&#49688;&#54616;&#44592;
          &#50948;&#54632;: &#48277;&#51221; &#51032;&#47924; (&#50696;:
          &#44592;&#47197; &#44288;&#47532; &#51032;&#47924;)&#47484;
          &#51456;&#49688;, &#48516;&#51137; &#54644;&#44208;,
          &#44228;&#50557;&#49345;&#51032; &#54801;&#51221; &#51665;&#54665;
          &#48143; &#48277;&#51201; &#52397;&#44396;&#47484; &#49444;&#51221;,
          &#54665;&#49324; &#46608;&#45716; &#48169;&#50612;&#54616;&#44592;
          &#50948;&#54616;&#50668; &#51221;&#48372;&#47484;
          &#49324;&#50857;&#54616;&#45716; &#44163;&#51060;
          &#54252;&#54632;&#46121;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#48512;&#47197; I&#50640;
        &#49444;&#47749;&#46108; &#45824;&#47196; &#45796;&#51020;
        &#48277;&#51201; &#44540;&#44144;&#50640; &#46384;&#46972;
        &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#47484;
        &#49324;&#50857;&#54633;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_t6i46twrfd5f-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#54924;&#49324;&#50752; &#49324;&#50857;&#51088;&#50752;&#51032;
          &#44228;&#50557; &#51060;&#54665;&#54616;&#44592;
          &#50948;&#54632;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#48277;&#51221; &#51032;&#47924;&#47484;
          &#51456;&#49688;&#54616;&#44592; &#50948;&#54632;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#54924;&#49324;&#51032; &#51221;&#45817;&#54620; &#51060;&#51061;
          (&#46608;&#45716; &#51228;3&#51088;&#51032; &#51060;&#51061;)&#51060;
          &#54596;&#50836;&#54620; &#44221;&#50864; &#46608;&#54620;
          &#49324;&#50857;&#51088;&#51032; &#51060;&#51061; &#48143;
          &#44592;&#48376; &#44428;&#47532;&#44032; &#51060;&#47084;&#54620;
          &#51060;&#51061;&#51012; &#50864;&#49440;&#54616;&#51648;
          &#50506;&#44592;&#47484; &#50948;&#54632;.</span
        >
      </li>
    </ul>
    <p class="c15">
      <span class="c13 c3"
        >&#52628;&#44032;&#51201;&#51004;&#47196; &#45796;&#51020;
        &#48277;&#51201; &#44540;&#44144;&#50640; &#46384;&#46972;
        &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#47484;
        &#49324;&#50857;&#54633;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_v7uuzhs54zyr-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49324;&#50857;&#51088;&#51032; &#51060;&#51061; (&#46608;&#45716;
          &#45796;&#47480; &#49324;&#46988;&#51032; &#51060;&#51061;)&#51012;
          &#48372;&#54840;&#54616;&#44592; &#50948;&#54632;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49324;&#50857;&#51088;&#51032; &#46041;&#51032;&#44032;
          &#51080;&#45716; &#44221;&#50864;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#48277;&#51201; &#52397;&#44396;&#47484;
          &#50745;&#54840;&#54616;&#44144;&#45208;
          &#52628;&#44396;&#54644;&#50556; &#54616;&#45716;
          &#49345;&#54889;.</span
        >
      </li>
    </ul>
    <h3 class="c10" id="h.dxykcsjiyebr">
      <span class="c7"
        >&#51032;&#49324; &#44208;&#51221; &#51088;&#46041;&#54868; &#48143;
        &#54532;&#47196;&#54028;&#51068;&#47553;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#44288;&#44228; &#44284;&#51221;&#50640;&#49436;
        &#54532;&#47196;&#54028;&#51068;&#47553; &#46608;&#45716;
        &#51032;&#49324; &#44208;&#51221; &#51088;&#46041;&#54868;&#47484;
        &#49324;&#50857;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.gg3w2j56hbxf">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372;&#51032; &#51228;&#44277;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#45796;&#51020;&#51032;
        &#47785;&#51201;&#51064; &#44221;&#50864;&#50640;&#45716;
        &#49324;&#50857;&#51088; &#44060;&#51064;&#51221;&#48372;&#47484;
        &#51228;3&#51088;&#50752; &#44277;&#50976;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_odcs8zc7hy1i-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49436;&#48708;&#49828; &#51228;&#44277;&#51088; &#48143;
          &#50808;&#48512; &#52968;&#53944;&#47204;&#47084;&#50640;
          &#44277;&#44060;: &#54924;&#49324;&#45716; &#50948;&#50640;&#49436;
          &#49444;&#47749;&#54620; &#47785;&#51201;&#51012; &#46160;&#44256;
          &#49324;&#50857;&#51088;&#51032;
          &#44060;&#51064;&#51221;&#48372;&#47484;
          &#52376;&#47532;&#54616;&#44592; &#50948;&#54644; &#53440;&#49324;
          &#44277;&#44553;&#50629;&#52404; &#48143; &#49436;&#48708;&#49828;
          &#51228;&#44277;&#51088; (&#46608;&#45716;
          &quot;&#45936;&#51060;&#53552; &#52376;&#47532;&#51088;&quot;)&#47484;
          &#51060;&#50857;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
          &#54924;&#49324;&#51032; &#45936;&#51060;&#53552;
          &#54532;&#47196;&#49464;&#49436;&#45716; &#51060;
          &#51221;&#52293;&#50640; &#46384;&#46972; &#54924;&#49324;&#51032;
          &#51648;&#52840;&#50640; &#46384;&#46972;&#49436;
          &#51089;&#46041;&#54616;&#47728; &#51201;&#51208;&#54620;
          &#44592;&#48128; &#50976;&#51648; &#48143; &#48372;&#50504;
          &#51032;&#47924;&#47484;
          &#51201;&#50857;&#54633;&#45768;&#45796;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#50808;&#48512; &#52968;&#53944;&#47204;&#47084;&#50640;
          &#44277;&#44060;: &#54924;&#49324;&#45716; &#50948;&#50640;&#49436;
          &#49444;&#47749;&#54620; &#47785;&#51201;&#51012; &#46160;&#44256;
          &#51060;&#50857;&#51088;&#51032;
          &#44060;&#51064;&#51221;&#48372;&#47484;
          &#52376;&#47532;&#54616;&#44592; &#50948;&#54644; &#50808;&#48512;
          &#52968;&#53944;&#47204;&#47084;&#50752; &#51221;&#48372;&#47484;
          &#44277;&#50976;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
          &#50808;&#48512; &#52968;&#53944;&#47204;&#47084;&#50640;&#44172;
          &#44277;&#50976;&#46104;&#45716;
          &#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
          &#49345;&#49464; &#45236;&#50857;&#51008; &#48512;&#47197;
          II&#50640;&#49436; &#54869;&#51064;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#50689;&#50629; &#50577;&#46020;&#50640; &#46384;&#47480;
          &#44277;&#44060;: &#54924;&#49324;&#45716;
          &#50937;&#49324;&#51060;&#53944; &#47588;&#44033;, &#54633;&#48337;,
          &#53685;&#54633;, &#51088;&#49328; &#47588;&#44033;,
          &#44592;&#50629;&#44277;&#44060;(IPO) &#46608;&#45716;
          &#54028;&#49328;&#51032; &#44221;&#50864;&#50752; &#44057;&#51008;
          &#44592;&#50629; &#44144;&#47000;&#50752;
          &#44288;&#47144;&#54616;&#50668; &#51060;&#50857;&#51088;&#51032;
          &#51221;&#48372;&#47484; &#44277;&#50976;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#48277;&#51201; &#50857;&#46020;&#47484; &#50948;&#54620;
          &#44277;&#44060;: &#54924;&#49324;&#45716;
          &#44060;&#51064;&#51221;&#48372; &#48372;&#54840;&#48277;&#50640;
          &#46384;&#46972; &#49324;&#50857;&#51088;&#51032;
          &#44060;&#51064;&#51221;&#48372;&#47484; &#48277; &#51665;&#54665;
          &#44592;&#44288;, &#51221;&#48512; &#44592;&#44288;, &#48277;&#47456;
          &#44256;&#47928; &#48143; &#50808;&#48512;
          &#52968;&#49444;&#53556;&#53944;&#50640;&#44172;
          &#51204;&#49569;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
          &#51060;&#47084;&#54620; &#52376;&#47532;&#51032; &#48277;&#51201;
          &#44540;&#44144;&#45716; &#48277;&#51201; &#52397;&#44396;&#51032;
          &#49892;&#54665; &#46608;&#45716; &#48320;&#54840;&#50752;
          &#44057;&#51060; &#54924;&#49324;&#50640;&#44172;
          &#51201;&#50857;&#46104;&#45716; &#48277;&#51201; &#51032;&#47924;
          &#46608;&#45716; &#54924;&#49324;&#51032; &#51221;&#45817;&#54620;
          &#51060;&#51061;&#51012; &#51456;&#49688;&#54616;&#45716;
          &#44163;&#51077;&#45768;&#45796;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#49324;&#50857;&#51088;&#51032; &#46041;&#51032; &#54616;&#50640;
          &#44277;&#44060;: &#49324;&#50857;&#51088;&#44032;
          &#46041;&#51032;&#54620; &#44221;&#50864;
          &#49324;&#50857;&#51088;&#50640; &#44288;&#54620;
          &#51221;&#48372;&#47484; &#51228;3&#51088;&#50752;
          &#44277;&#50976;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <p class="c15">
      <span class="c13 c3"
        >&#44480;&#54616;&#45716; &#54924;&#49324;
        &#49436;&#48708;&#49828;&#47484; &#53685;&#54644;
        &#47700;&#49884;&#51648; &#48143; &#44592;&#53440;
        &#53080;&#53584;&#52768;&#47484;
        &#50629;&#47196;&#46300;&#54616;&#44256; &#45796;&#47480;
        &#49324;&#50857;&#51088;&#46308;&#44284; &#44277;&#50976;&#54624;
        &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#49324;&#50857;&#51088;&#44032; &#54924;&#49324;
        &#49436;&#48708;&#49828;&#47196; &#54876;&#46041;&#51012;
        &#54616;&#49892; &#44221;&#50864; &#49324;&#50857;&#51088;&#44032;
        &#44277;&#50976;&#54616;&#45716; &#47784;&#46304;
        &#51221;&#48372;&#45716; &#45796;&#47480;
        &#49324;&#50857;&#51088;&#44032; &#51069;&#44144;&#45208;,
        &#49688;&#51665;&#54616;&#44144;&#45208;, &#49324;&#50857;&#54624;
        &#49688; &#51080;&#45796;&#45716; &#51216;&#51012;
        &#51064;&#51648;&#54644;&#50556; &#54616;&#44256;
        &#51060;&#47084;&#54620; &#50689;&#50669;&#50640;
        &#51221;&#48372;&#47484; &#44277;&#44060;&#54624;
        &#44221;&#50864;&#50640;&#45716; &#54637;&#49345;
        &#51452;&#51032;&#47484; &#54644;&#50556; &#54633;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#44480;&#54616;&#44032;
        &#51228;&#52636;&#54620; &#51221;&#48372;&#50640; &#45824;&#54644;
        &#52293;&#51076;&#51648;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#49324;&#50857;&#51088;&#51032; &#44060;&#51064;&#51221;&#48372;
        &#49688;&#47161;&#51064;&#51060; &#50976;&#47101;&#50672;&#54633;(EU)
        &#51665;&#54665;&#50948;&#50896;&#54924;&#50640;&#49436;
        &#51201;&#51208;&#54620; &#49688;&#51456;&#51032; &#51221;&#48372;
        &#48372;&#54840;&#47484; &#48372;&#51109;&#54616;&#45716;
        &#44163;&#51004;&#47196; &#51064;&#51221;&#54616;&#51648;
        &#50506;&#51008; &#44397;&#44032;&#51032;
        &#50976;&#47101;&#50672;&#54633;(EU) &#48143;
        &#50976;&#47101;&#44221;&#51228;&#51648;&#50669;(EEA)
        &#50808;&#48512;&#50640; &#51080;&#51012; &#44221;&#50864;,
        &#54924;&#49324;&#45716; &#51201;&#54633;&#54620;
        &#51312;&#52824;&#47484; &#49884;&#54665;&#54616;&#50668;
        &#45796;&#51020;&#51012; &#48372;&#51109;&#54633;&#45768;&#45796;.
        &#54644;&#45817; &#51221;&#48372; &#48372;&#54840; &#48143;
        &#44060;&#51064;&#51221;&#48372; &#48372;&#54840;&#48277;&#50640;
        &#46384;&#46972; &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#47484; &#44397;&#50808;&#47196;
        &#51060;&#51204;&#54624; &#44221;&#50864; &#51201;&#51208;&#54620;
        &#48372;&#54840;&#51312;&#52824;&#47484; &#53685;&#54644;
        &#50504;&#51204;&#54616;&#44172;
        &#51060;&#47336;&#50612;&#51648;&#44256; &#50976;&#51648;&#46112;
        &#44163;&#51077;&#45768;&#45796;. &#51060;&#47084;&#54620;
        &#48372;&#54840;&#51312;&#52824;&#50640;&#45716;
        &#50976;&#47101;&#50672;&#54633;(EU) &#54364;&#51456;
        &#51221;&#48372;&#48372;&#54840; &#51312;&#54637;&#50640;
        &#46384;&#47480; &#44228;&#50557;&#51012;
        &#49892;&#49884;&#54616;&#45716; &#51221;&#48372; &#51060;&#51204;
        &#54801;&#51221;&#51060; &#54252;&#54632;&#46121;&#45768;&#45796;
        (&#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
        &#51201;&#51208;&#54620; &#48372;&#54840;&#51312;&#52824;&#47484;
        &#51228;&#44277;&#54616;&#44592; &#50948;&#54644;
        &#50976;&#47101;&#50672;&#54633;(EU)
        &#51665;&#54665;&#50948;&#50896;&#54924;&#50640;&#49436;
        &#49324;&#51204; &#49849;&#51064;&#54620; &#51221;&#48372;
        &#51060;&#51204; &#54801;&#51221; &#54805;&#49885;).
        &#49324;&#50857;&#51088;&#45716; &#48376; &#51221;&#52293;&#50640;
        &#44277;&#44060;&#54616;&#44256; &#51080;&#45716;
        &#54924;&#49324;&#51032; &#50672;&#46973;&#52376;&#47196;
        &#50672;&#46973;&#54616;&#49884;&#50612;
        &#48372;&#54840;&#51312;&#52824;&#51032; &#49324;&#48376;&#51012;
        &#50836;&#52397;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.hreqrhazoyvr">
      <span class="c7">&#50500;&#46041;</span>
    </h3>
    <p class="c15">
      <span class="c3 c13"
        >&#45817;&#49324;&#45716; 14&#49464; &#48120;&#47564;&#51012;
        &#45824;&#49345;&#51004;&#47196; &#49436;&#48708;&#49828;&#47484;
        &#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#54924;&#49324;&#45716; &#54644;&#45817;
        &#48277;&#44508;&#49345;&#51032; &#51221;&#51032;&#54620;
        &#45824;&#47196; &#48512;&#47784;&#51032; &#46041;&#51032;
        &#50630;&#51060; &#46608;&#45716; &#54644;&#45817;
        &#48277;&#44508;&#49345;&#50640;&#49436;
        &#54728;&#50857;&#54616;&#45716; &#45824;&#47196;
        &#50500;&#46041;&#51004;&#47196;&#48512;&#53552;
        &#51221;&#48372;&#47484; &#44256;&#51032;&#47196;
        &#49688;&#51665;&#54616;&#44144;&#45208;
        &#54032;&#47588;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.2mcyu0dtb4g1">
      <span class="c7">&#44060;&#51064;&#51221;&#48372; &#48372;&#50504;</span>
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#45817;&#49324;&#45716; &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064; &#51221;&#48372;&#44032; &#49892;&#49688;&#47196;
        &#48516;&#49892;, &#47924;&#45800; &#49324;&#50857; &#46608;&#45716;
        &#51217;&#49549;, &#48320;&#51312; &#46608;&#45716;
        &#44277;&#44060;&#46104;&#45716; &#44163;&#51012;
        &#48169;&#51648;&#54616;&#44592; &#50948;&#54644;
        &#51201;&#51208;&#54620; &#48372;&#50504; &#51312;&#52824;&#47484;
        &#52712;&#54616;&#44256; &#51080;&#49845;&#45768;&#45796;.
        &#46608;&#54620; &#45817;&#49324;&#45716;
        &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
        &#51217;&#44540; &#44428;&#54620;&#51012; &#54644;&#45817;
        &#50629;&#47924;&#49345;&#51004;&#47196; &#51217;&#44540;&#51060;
        &#54596;&#50836;&#54620; &#51649;&#50896;, &#45824;&#47532;&#51064;,
        &#44228;&#50557;&#51088; &#48143; &#44592;&#53440;
        &#51228;3&#51088;&#50640;&#44172;&#47564;
        &#51228;&#54620;&#54633;&#45768;&#45796;. &#54924;&#49324;&#45716;
        &#51221;&#48372; &#48372;&#50504; &#45572;&#52636;&#51060;
        &#51032;&#49900;&#46104;&#45716; &#44221;&#50864; &#51060;&#47484;
        &#52376;&#47532;&#54616;&#44592; &#50948;&#54620;
        &#51312;&#52824;&#47484; &#47560;&#47144;&#54664;&#51004;&#47728;
        &#48277;&#51201;&#51004;&#47196; &#54596;&#50836;&#54620;
        &#44221;&#50864; &#49324;&#50857;&#51088;&#50752; &#54644;&#45817;
        &#44508;&#51228; &#44592;&#44288;&#50640; &#45572;&#52636;&#51060;
        &#51032;&#49900;&#46104;&#45716; &#49324;&#49892;&#51012;
        &#44256;&#51648;&#54624; &#44163;&#51077;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.kmjeqot3tnx5">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372;&#51032;
        &#48372;&#50976;&#44592;&#44036;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#54924;&#49324;&#45716; &#48277;&#51201;, &#54924;&#44228;&#51201;
        &#46608;&#45716; &#48372;&#44256; &#50836;&#44148;&#51012;
        &#52649;&#51313;&#54616;&#45716; &#44163;&#51012;
        &#54252;&#54632;&#54616;&#50668; &#50948;&#50640;
        &#47749;&#49884;&#46108; &#51060;&#50857;&#47785;&#51201;&#51012;
        &#45804;&#49457;&#54616;&#45716; &#45936; &#54596;&#50836;&#54620;
        &#44592;&#44036; &#46041;&#50504;&#47564;
        &#49324;&#50857;&#51088;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#47484;
        &#48372;&#50976;&#54633;&#45768;&#45796;.
        &#44060;&#51064;&#51221;&#48372;&#51032; &#51201;&#51208;&#54620;
        &#48372;&#50976;&#44592;&#44036;&#51012; &#51221;&#54616;&#44592;
        &#50948;&#54644; &#45936;&#51060;&#53552;&#51032; &#50577;,
        &#45236;&#50857; &#48143; &#48124;&#44048;&#46020;, &#44060;&#51064;
        &#51221;&#48372;&#51032; &#47924;&#45800; &#49324;&#50857;
        &#46608;&#45716; &#44277;&#44060;&#47196; &#51064;&#54620;
        &#50948;&#54744;, &#44060;&#51064;&#51221;&#48372;&#47484;
        &#52376;&#47532;&#54616;&#45716; &#47785;&#51201;, &#45796;&#47480;
        &#49688;&#45800;&#51012; &#53685;&#54644; &#51060;&#47084;&#54620;
        &#47785;&#51201;&#51012; &#45804;&#49457;&#54624; &#49688;
        &#51080;&#45716;&#51648; &#50668;&#48512; &#48143; &#54644;&#45817;
        &#48277;&#51201; &#50836;&#44396; &#49324;&#54637;&#46308;&#51012;
        &#44256;&#47140;&#54616;&#47732;&#49436;
        &#44208;&#51221;&#54633;&#45768;&#45796;.
        &#51068;&#48152;&#51201;&#51004;&#47196;
        &#45824;&#48512;&#48516;&#51032; &#45936;&#51060;&#53552;&#45716;
        &#49324;&#50857;&#51088;&#51032; &#44288;&#44228;&#44032;
        &#51333;&#47308;&#46104;&#45716; &#51593;&#49884;
        &#54028;&#44592;&#54633;&#45768;&#45796;. &#45796;&#47564;,
        &#50500;&#47000;&#50752; &#44057;&#51008;
        &#49345;&#54889;&#50640;&#49436; &#44480;&#54616;&#51032;
        &#44060;&#51064;&#51221;&#48372;&#45716; &#45796;&#47480;
        &#51221;&#54644;&#51652; &#44592;&#44036; &#46041;&#50504;
        &#48372;&#50976;&#46121;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_9rcb9egve5ts-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >Mintmarble Auction &#44144;&#47000;&#45236;&#50669;&#51060;
          &#51080;&#45716; &#49324;&#50857;&#51088; &#51221;&#48372; &ndash;
          5&#45380;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >Mintmarble &#45236; &#50516;&#54840;&#54868;&#54224;
          &#51077;/&#52636;&#44552; &#45236;&#50669;&#51060; &#51080;&#45716;
          &#49324;&#50857;&#51088; &#51221;&#48372; &ndash; 5&#45380;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#44256;&#44061;&#49468;&#53552;&#50640; &#51228;&#52636;&#46108;
          &#47928;&#51032; &#44288;&#47144; &#51221;&#48372; &ndash;
          3&#45380;</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >Creator Center &#53356;&#47532;&#50640;&#51060;&#53552;
          &#51221;&#48372; &ndash; NFT &#54032;&#47588; &#54980; 5&#45380;</span
        >
      </li>
    </ul>
    <p class="c15">
      <span class="c13 c3"
        >&#50948;&#51032; &#45236;&#50857;&#50640;&#46020;
        &#48520;&#44396;&#54616;&#44256; &#54924;&#49324;&#45716;
        &#48277;&#51201; &#46608;&#45716; &#44508;&#51228;&#51201;
        &#51032;&#47924;&#47484; &#51456;&#49688;&#54616;&#45716; &#45936;
        &#54596;&#50836;&#54620; &#44592;&#44036; &#46041;&#50504;
        &#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;&#47484;
        &#48372;&#50976;&#54624; &#49688;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.yc0pwzzfy4al">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372;&#50640; &#45824;&#54620;
        &#51221;&#48372;&#51452;&#52404;&#51032; &#51032;&#47924;,
        &#44428;&#47532;</span
      >
    </h3>
    <p class="c8">
      <span class="c23"
        >&#51221;&#48372; &#48320;&#44221;&#50640; &#45824;&#54644;
        &#50508;&#47140;&#50556; &#54624; &#51032;&#47924;<br /></span
      ><br /><span class="c13 c3"
        >&#45817;&#49324;&#44032; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372; &#45936;&#51060;&#53552;&#47484;
        &#51221;&#54869;&#54616;&#44256; &#52572;&#49888;&#51004;&#47196;
        &#48372;&#50976;&#54616;&#45716; &#44163;&#51060;
        &#51473;&#50836;&#54633;&#45768;&#45796;. &#47564;&#50557;
        &#44480;&#54616;&#44032; &#44480;&#54616;&#51032; &#51221;&#48372;
        &#48320;&#44221;&#51060; &#51080;&#44144;&#45208;
        &#51221;&#54869;&#54616;&#51648; &#50506;&#51008;
        &#51221;&#48372;&#44032; &#51080;&#45716; &#44163;&#51012;
        &#50504;&#45796;&#47732; &#45817;&#49324;&#50640;&#44172;
        &#48320;&#44221;&#49324;&#54637;&#51012; &#50508;&#47140;
        &#51221;&#48372;&#47484; &#44081;&#49888; &#54841;&#51008;
        &#49688;&#51221;&#54616;&#46020;&#47197; &#54644;&#50556;
        &#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c8"><br />
      <span class="c23"
        >&#44060;&#51064;&#51221;&#48372;&#50752; &#50672;&#44288;&#46108;
        &#44480;&#54616;&#51032; &#44428;&#47532;<br /></span
      ><br /><span class="c13 c3"
        >&#44288;&#47144; &#48277;&#47456;&#50640; &#46384;&#46972;
        &#44480;&#54616;&#45716; &#45796;&#51020;&#44284; &#44057;&#51008;
        &#44428;&#47532;&#47484; &#44032;&#51665;&#45768;&#45796;:</span
      >
    </p>
    <ul class="c0 lst-kix_iaym45bg3mlk-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#51217;&#44540;&#54624; &#44428;&#47532;.
          &#49324;&#50857;&#51088;&#45716; &#50864;&#47532;&#44032;
          &#48372;&#50976;&#54616;&#44256; &#51080;&#45716;
          &#44060;&#51064;&#51221;&#48372;&#50640; &#49324;&#48376;&#51012;
          &#50836;&#44396;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
          &#50836;&#44396;&#54632;&#50640; &#46384;&#46972;
          &#50864;&#47532;&#44032; &#48372;&#50976;&#54616;&#44256;
          &#51080;&#45716; &#44060;&#51064; &#51221;&#48372;&#47484;
          &#54633;&#48277;&#51201;&#51004;&#47196;
          &#52376;&#47532;&#54616;&#44256; &#51080;&#45716;&#51648;
          &#54869;&#51064;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;&#47484;
          &#49688;&#51221; &#44536;&#47532;&#44256;/&#54841;&#51008;
          &#49325;&#51228;&#47484; &#50836;&#52397;&#54624;
          &#44428;&#47532;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;&#51032;
          &#52376;&#47532;&#47484; &#51228;&#54620;&#54624;
          &#44428;&#47532;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#50864;&#47532;&#50640;&#44172; &#51228;&#44277;&#54620;
          &#44060;&#51064;&#51221;&#48372;&#47484; &#44592;&#44228;
          &#54032;&#46021; &#44032;&#45733;&#54620;
          &#54805;&#49885;&#51004;&#47196; &#45796;&#47480;
          &#51312;&#51649;&#50640;&#44172; &#49688;&#47161; &#48143;
          &#51204;&#49569; &#50836;&#52397;&#54624; &#44428;&#47532;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#45817;&#49324;&#44032; &#52376;&#47532;&#54616;&#45716;
          &#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;&#47484;
          &#50616;&#51228;&#46304; &#52712;&#49548;&#54624;
          &#44428;&#47532;.</span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3"
          >&#44480;&#54616;&#51032; &#49849;&#51064;&#51012;
          &#52712;&#54616;&#54624; &#44428;&#47532;.</span
        >
      </li>
    </ul>
    <p class="c8">
		<br />
      <span class="c3"
        >&#48376;&#51064;&#51032; &#44428;&#47532;&#47484;
        &#49899;&#54665;&#54616;&#47140;&#47732; &#48376; &#51221;&#52293;
        &#47560;&#51648;&#47561;&#50640; &#44592;&#51116;&#46108;
        &#50672;&#46973;&#52376;&#47484; &#53685;&#54644;
        &#50672;&#46973;&#51012; &#54644;&#51452;&#49884;&#44592;
        &#48148;&#46989;&#45768;&#45796;. &#45817;&#49324;&#45716;
        &#44480;&#54616;&#51032; &#49888;&#50896; &#54869;&#51064;&#44284;
        &#44060;&#51064;&#51221;&#48372; &#51217;&#44540;
        &#44428;&#54620;(&#54841;&#51008; &#45796;&#47480;
        &#44428;&#47532;&#47484; &#51060;&#54665;&#54624; &#44428;&#47532;)
        &#54869;&#51064;&#51012; &#50948;&#54644; &#49345;&#49464;&#54620;
        &#44060;&#51064;&#51221;&#48372;&#47484; &#50836;&#52397;&#54624;
        &#54596;&#50836;&#44032; &#51080;&#49845;&#45768;&#45796;.
        &#51060;&#47088; &#51208;&#52264;&#45716;
        &#44060;&#51064;&#51221;&#48372;&#47484; &#50676;&#46988;&#54624;
        &#44428;&#54620;&#51060; &#50630;&#45716; &#51088;&#50640;&#44172;
        &#44060;&#51064;&#51221;&#48372;&#47484; &#44277;&#44060;&#54624;
        &#49688; &#50630;&#45716; &#46321;&#51032; &#45796;&#47480;
        &#51201;&#54633;&#54620; &#48372;&#50504;
        &#51312;&#52824;&#51077;&#45768;&#45796;. &#45817;&#49324;&#44032;
        &#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;
        &#52376;&#47532;&#54616;&#45716; &#48169;&#49885;&#50640;
        &#45824;&#54644; &#48520;&#47564;&#51012;
        &#51228;&#44592;&#54616;&#49892; &#44221;&#50864;
        &#50864;&#47532;&#50640;&#44172; &#50672;&#46973;&#51012;
        &#54616;&#49884;&#47732; &#44480;&#54616;&#51032;
        &#50836;&#52397;&#51012; &#52572;&#45824;&#54620;
        &#48736;&#47476;&#44172; &#52376;&#47532;&#54616;&#46020;&#47197;
        &#45432;&#47141;&#54624; &#44163;&#51077;&#45768;&#45796;.
        &#44480;&#54616;&#44032; &#47564;&#51068; &#45817;&#49324;&#44032;
        &#44480;&#54616;&#51032; &#44060;&#51064;&#51221;&#48372;
        &#52376;&#47532;&#44032; &#51201;&#48277;&#52824;
        &#50506;&#45796;&#44256; &#49373;&#44033;&#46104;&#47732;
        &#44048;&#46021;&#44592;&#44288;&#50640; &#44256;&#49548;&#47484;
        &#51228;&#52636;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#44480;&#54616;&#44032; EU&#50640;
        &#44144;&#51452;&#54620;&#45796;&#47732; &#44480;&#54616;
        &#44397;&#44032;&#51032; &#44048;&#46021;&#44592;&#44288;&#51012;
        &#52286;&#51012; &#49688; &#51080;&#51004;&#47728; &#45796;&#51020;
        &#51452;&#49548;&#47196; &#50672;&#46973;&#51012;
        &#52712;&#54616;&#49884;&#47732; &#46121;&#45768;&#45796;.</span
      ><span class="c21"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/board/members_en&amp;sa=D&amp;source=editors&amp;ust=1689216813834780&amp;usg=AOvVaw1bcqTu6xUqzgTkAAbk-NLx"
          >https://edpb.europa.eu/about-edpb/board/members_en.</a
        ></span
      ><span class="c3"
        >&nbsp;&#44480;&#54616;&#44032; &#47564;&#51068; UK
        &#44144;&#51452;&#54616;&#49888;&#45796;&#47732; UK
        &#44048;&#46021;&#44592;&#44288;&#51008;
        &#51221;&#48372;&#50948;&#50896;&#49892;(ICO)&#51060;&#47728;</span
      ><span class="c3"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/your-personal-information-concerns&amp;sa=D&amp;source=editors&amp;ust=1689216813835030&amp;usg=AOvVaw0wi9v8qnY0UIXfpDSySNbL"
          >&nbsp;</a
        ></span
      ><span class="c21"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/your-personal-information-concerns&amp;sa=D&amp;source=editors&amp;ust=1689216813835183&amp;usg=AOvVaw1dAAkHY05APfw6q1ghjWQ7"
          >https://ico.org.uk/make-a-complaint/your-personal-information-concerns</a
        ></span
      ><span class="c13 c3"
        >&nbsp;&#51004;&#47196; &#50672;&#46973;&#54616;&#49884;&#47732;
        &#46121;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.o1dqqhaln0v9">
      <span class="c7"
        >&#45936;&#51060;&#53552; &#52968;&#53944;&#47204;&#47084; &#48143;
        &#50672;&#46973; &#51221;&#48372;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >EU &#51068;&#48152; &#44060;&#51064;&#51221;&#48372;&#48372;&#54840;
        &#44508;&#51228;(GDPR) &#47785;&#51201;&#44284; UK &#51068;&#48152;
        &#44060;&#51064;&#51221;&#48372;&#48372;&#54840;
        &#44508;&#51228;(GDPR)&#51032; &#47785;&#51201;&#44284;
        &#44536;&#47532;&#44256; &#44592;&#53440; &#45936;&#51060;&#53552;
        &#48372;&#54840; &#48277;&#47456;&#50640;
        &#51032;&#44144;&#54616;&#50668; &#45817;&#49324;&#45716;
        &#45936;&#51060;&#53552;
        &#52968;&#53944;&#47204;&#47084;&#51077;&#45768;&#45796;. &#48376;
        &#44277;&#51648;&#50640; &#44288;&#54620;
        &#47928;&#51032;&#49324;&#54637;&#51060; &#51080;&#44144;&#45208;
        &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#50752;
        &#44288;&#47144;&#54616;&#50668; &#45817;&#49324;&#50752;
        &#50672;&#46973;&#51012; &#54616;&#49884;&#44144;&#45208; &#48376;
        &#44277;&#51648;&#50640; &#44288;&#54620; &#50864;&#47140;&#44032;
        &#51080;&#51004;&#49892; &#44221;&#50864; &#50500;&#47000;
        &#50672;&#46973;&#52376;&#50640; &#47928;&#51032;
        &#54616;&#49884;&#44592; &#48148;&#46989;&#45768;&#45796;. (Metaware
        PTE&#50752; Metaware Co., Ltd &#46041;&#51068;):</span
      >
    </p>
    <p class="c8">
      <span class="c13 c3">[&#50672;&#46973;&#52376; &#51221;&#48372;]</span>
    </p>
    <ul class="c0 lst-kix_5yfg1vhyd729-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3">privacy@metaware.ltd</span>
      </li>
    </ul>
	<br />
    <p class="c8">
      <span class="c13 c3">[DPO &#50672;&#46973; &#51221;&#48372;]</span>
    </p>
    <ul class="c0 lst-kix_mnj0jzqmcuew-0 start">
      <li class="c6 li-bullet-0"><span class="c13 c3">VeraSafe</span></li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">Anastasia Pavlou (Privacy Counsel)</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">100 M Street S.E., Suite 600</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">Washington, D.C. 20003 USA</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">+1 (617) 398-7067</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">experts@verasafe.com</span>
      </li>
    </ul>
    <p class="c8">
      <span class="c13 c3"
        >[EU &#45812;&#45817;&#51088; &#50672;&#46973;&#52376;
        &#51221;&#48372;]</span
      >
    </p>
    <ul class="c0 lst-kix_osvm062131jc-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3">VeraSafe Netherlands BV</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">Keizersgracht 391 A</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">1016 EJ Amsterdam</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">The Netherlands</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c3">&#50672;&#46973;&#52376; &#50577;&#49885;:</span
        ><span class="c3"
          ><a
            class="c14"
            href="https://www.google.com/url?q=https://www.verasafe.com/privacy-services/contact-article-27-representative/&amp;sa=D&amp;source=editors&amp;ust=1689216813836344&amp;usg=AOvVaw3302stGigbRgldaHad_TrM"
            >&nbsp;</a
          ></span
        ><span class="c21"
          ><a
            class="c14"
            href="https://www.google.com/url?q=https://www.verasafe.com/privacy-services/contact-article-27-representative/&amp;sa=D&amp;source=editors&amp;ust=1689216813836491&amp;usg=AOvVaw11M3XRMu2ZEHo9JBnIs5Nn"
            >https://www.verasafe.com/privacy-services/contact-article-27-representative/</a
          ></span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">+420 228 881 031.</span>
      </li>
    </ul>
    <p class="c8">
      <span class="c13 c3"
        >[UK &#45812;&#45817;&#51088; &#50672;&#46973;&#52376;
        &#51221;&#48372;]</span
      >
    </p>
    <ul class="c0 lst-kix_o3a106j5hv4e-0 start">
      <li class="c6 li-bullet-0">
        <span class="c13 c3">VeraSafe United Kingdom Ltd.</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">37 Albert Embankment</span>
      </li>
      <li class="c6 li-bullet-0"><span class="c13 c3">London SE1 7TL</span></li>
      <li class="c6 li-bullet-0"><span class="c13 c3">United Kingdom</span></li>
      <li class="c6 li-bullet-0">
        <span class="c3">&#50672;&#46973;&#52376; &#50577;&#49885;:</span
        ><span class="c3"
          ><a
            class="c14"
            href="https://www.google.com/url?q=https://verasafe.com/public-resources/contact-data-protection-representative&amp;sa=D&amp;source=editors&amp;ust=1689216813837056&amp;usg=AOvVaw2IE7RAIku8jk-ipr56Y1OQ"
            >&nbsp;</a
          ></span
        ><span class="c21"
          ><a
            class="c14"
            href="https://www.google.com/url?q=https://verasafe.com/public-resources/contact-data-protection-representative&amp;sa=D&amp;source=editors&amp;ust=1689216813837198&amp;usg=AOvVaw3dMQvD5shHlOuwkC_qbqJ4"
            >https://verasafe.com/public-resources/contact-data-protection-representative</a
          ></span
        >
      </li>
      <li class="c6 li-bullet-0">
        <span class="c13 c3">+44 (20) 45322003</span>
      </li>
    </ul>
    <h3 class="c10" id="h.utbsmholvspf">
      <span class="c7"
        >&#44060;&#51064;&#51221;&#48372; &#52376;&#47532;&#48169;&#52840;
        &#48320;&#44221;</span
      >
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#51221;&#48372; &#48372;&#54840; &#44288;&#47144;
        &#48277;&#47456;&#50640; &#51032;&#54616;&#50668;
        &#45817;&#49324;&#51032; &#49892;&#47924;&#50752;
        &#49436;&#48708;&#49828;&#50640; &#48320;&#44221;&#51060;
        &#51080;&#51012; &#49884; &#48376; &#51221;&#52293;&#50640;
        &#48320;&#44221;&#49324;&#54637;&#51012;
        &#48152;&#50689;&#54616;&#50668; &#44081;&#49888;&#54624;
        &#44163;&#51060;&#47728; &#51473;&#50836;
        &#48320;&#44221;&#49324;&#54637;&#50640; &#45824;&#54644;
        &#44480;&#54616;&#50640;&#44172; &#51201;&#54633;&#54620;
        &#48169;&#48277;&#51004;&#47196; &#44277;&#51648;&#54624;
        &#44163;&#51077;&#45768;&#45796;. &#48376; &#51221;&#52293;&#50640;
        &#44060;&#51221;&#49324;&#54637;&#51012; &#44172;&#49884;&#54624;
        &#49884; &#48376; &#51221;&#52293; &#49345;&#45800;&#50640;
        &ldquo;&#47560;&#51648;&#47561; &#49688;&#51221;
        &#51068;&#51088;&rdquo;&#47484; &#44060;&#51221;&#54624; &#44163;
        &#51077;&#45768;&#45796;.</span
      >
    </p>
    <h3 class="c10" id="h.as2v8ndcvx1v">
      <span class="c7">&#48512;&#47197; I</span>
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#49324;&#50857;&#51088; &#45936;&#51060;&#53552; &#52376;&#47532;
        &#47785;&#51201;</span
      >
    </p>
    <a id="t.6b8776cac6c2ae1b4d3a68bddf3ecd443bfdf096"></a><a id="t.0"></a>
    <table class="c17">
      <tr class="c9">
        <td class="c16" colspan="1" rowspan="1">
          <p class="c19">
            <span class="c18">&#51060;&#50857; &#47785;&#51201;</span>
          </p>
        </td>
        <td class="c31" colspan="1" rowspan="1">
          <p class="c19">
            <span class="c18"
              >&#44060;&#51064;&#51221;&#48372; &#44396;&#48516;</span
            >
          </p>
        </td>
        <td class="c32" colspan="1" rowspan="1">
          <p class="c19">
            <span class="c18"
              >&#52376;&#47532;&#50640; &#45824;&#54620; &#48277;&#51201;
              &#44592;&#51456;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c27">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#51228;&#54408;&#44284; &#49436;&#48708;&#49828;
              &#51228;&#44277;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#51221;&#48372;, &#49885;&#48324;&#51088;,
              &#45348;&#53944;&#50892;&#53356; &#51221;&#48372;,
              &#44592;&#44592; &#51221;&#48372;, &#49324;&#50857;
              &#45936;&#51060;&#53552;, &#44480;&#54616; &#46041;&#51032;
              &#54788;&#54889;, (&#54596;&#50836; &#49884;)
              &#53448;&#51473;&#50521;&#54868; &#50900;&#47131; &#51452;&#49548;
              &#44288;&#47144; &#51221;&#48372;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#44228;&#50557; &#44592;&#51456;</span>
          </p>
        </td>
      </tr>
      <tr class="c28">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#49436;&#48708;&#49828; &#51228;&#44277;&#51012;
              &#50948;&#54620; &#44060;&#51064; &#49324;&#50857;&#51088;
              &#49885;&#48324;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#45936;&#51060;&#53552;,
              &#49885;&#48324;&#51088;, &#44592;&#44592; &#51221;&#48372;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#44228;&#50557;&#44592;&#51456;</span>
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#50864;&#47532; &#49436;&#48708;&#49828; &#54408;&#51656;,
              &#49324;&#50857;&#51088; &#44221;&#54744; &#54693;&#49345;
              &#48143; &#48260;&#44536; &#44060;&#49440;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#45348;&#53944;&#50892;&#53356; &#51221;&#48372;,
              &#44592;&#44592; &#51221;&#48372;, &#44172;&#51076;
              &#45936;&#51060;&#53552;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#45817;&#49324; &#49436;&#48708;&#49828;
              &#44060;&#49440;&#51012; &#50948;&#54620; &#54633;&#47532;&#51201;
              &#51060;&#54644;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#48520;&#48277;, &#48512;&#51221;,
              &#49849;&#51064;&#46104;&#51648; &#50506;&#51008;
              &#49436;&#48708;&#49828; &#53456;&#51648; &#48143;
              &#48169;&#51648;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44208;&#51228; &#51221;&#48372;, &#49885;&#48324;&#51088;,
              &#45348;&#53944;&#50892;&#53356; &#51221;&#48372;,
              &#44592;&#44592; &#51221;&#48372;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#45817;&#49324; &#49324;&#50629; &#44428;&#47532; &#48143;
              &#49436;&#48708;&#49828; &#48372;&#54840;&#47484; &#50948;&#54620;
              &#54633;&#47532;&#51201; &#51060;&#54644;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#49324;&#50857;&#51088;&#51032; &#47928;&#51032; &#48143;
              &#50836;&#52397; &#44288;&#47532;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#51221;&#48372;, &#44592;&#44592;
              &#51221;&#48372;, &#44172;&#51076; &#45936;&#51060;&#53552;,
              &#44208;&#51228; &#51221;&#48372;, &#54876;&#46041;
              &#45936;&#51060;&#53552;, (&#54596;&#50836; &#49884;)
              &#53448;&#51473;&#50521;&#54868; &#50900;&#47131; &#51452;&#49548;
              &#44288;&#47144; &#51221;&#48372;, &#44256;&#44061;
              &#49436;&#48708;&#49828; &#45936;&#51060;&#53552;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44480;&#54616;&#51032; &#50836;&#52397;&#51060;
              &#45817;&#49324; &#49436;&#48708;&#49828;
              &#51060;&#50857;&#51060;&#46972;&#47732;
              &#44228;&#50557;&#49345;&#51032; &#44592;&#51456; &#45796;&#47480;
              &#47589;&#46973; &#49324;&#50976;&#47196; &#50672;&#46973;&#54620;
              &#44221;&#50864;&#47732; &#44480;&#54616;&#51032;
              &#49849;&#51064;&#54616;&#51032; &#44592;&#51456;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >Mintmarble &#52964;&#48036;&#45768;&#54000;
              &#49436;&#48708;&#49828; &#51228;&#44277;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#45936;&#51060;&#53552;,
              &#45348;&#53944;&#50892;&#53356; &#51221;&#48372;,
              &#54876;&#46041; &#45936;&#51060;&#53552;,
              &#49324;&#50857;&#51088; &#49373;&#49457; &#51221;&#48372;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#44228;&#50557;&#44592;&#51456;</span>
          </p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#53216;&#53412; &#51221;&#48372; &#44288;&#47532;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#49324;&#50857; &#45936;&#51060;&#53552;</span>
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#48520;&#54596;&#50836;&#54620; &#53216;&#53412;&#47484;
              &#53685;&#54620; &#51221;&#48372; &#49688;&#51665;&#51068;
              &#44221;&#50864; &#49849;&#51064; &#54596;&#49688;
              &#53216;&#53412;&#47484; &#53685;&#54644; &#51221;&#48372;&#44032;
              &#49688;&#51665;&#46112; &#44221;&#50864; &#45817;&#49324;
              &#49436;&#48708;&#49828;&#51032; &#44592;&#45733; &#51221;&#49345;
              &#51089;&#46041; &#50668;&#48512;&#47484; &#50948;&#54620;
              &#54633;&#47532;&#51201; &#51060;&#54644;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44208;&#51228; &#52376;&#47532; &#46321;&#51012;
              &#54252;&#54632;&#54620; &#44480;&#54616;&#50752;&#51032;
              (&#44228;&#50557;&#49345;&#51032;) &#44288;&#44228;
              &#44592;&#54925;, &#49884;&#54665; &#48143; &#44288;&#47532;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#44208;&#51228; &#51221;&#48372;</span>
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#44228;&#50557;&#44592;&#51456;</span>
          </p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44592;&#47197; &#48372;&#44288; &#51032;&#47924;
              &#46321;&#51012; &#54252;&#54632;&#54620; &#48277;&#51201;
              &#51032;&#47924; &#51456;&#49688; &#48372;&#51109;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#45936;&#51060;&#53552;,
              &#49885;&#48324;&#51088;, &#45348;&#53944;&#50892;&#53356;
              &#51221;&#48372;, &#44592;&#44592; &#51221;&#48372;,
              &#44480;&#54616;&#51032; &#49849;&#51064; &#54788;&#54889;,
              &#44172;&#51076; &#45936;&#51060;&#53552;, &#44208;&#51228;
              &#51221;&#48372;, (&#54596;&#50836; &#49884;)
              &#53448;&#51473;&#50521;&#54868; &#50900;&#47131; &#51452;&#49548;
              &#44288;&#47144; &#51221;&#48372;, &#44256;&#44061;
              &#49436;&#48708;&#49828; &#45936;&#51060;&#53552;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3">&#48277;&#51201; &#51032;&#47924;</span>
          </p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#48516;&#51137; &#54644;&#44208;, &#45817;&#49324;
              &#44228;&#50557;&#49345;&#51032; &#44228;&#50557;
              &#51665;&#54665;, &#48277;&#51201; &#44428;&#47532;
              &#44396;&#52629;, &#51060;&#54665;, &#48320;&#54840;</span
            >
          </p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#44256;&#44061; &#45936;&#51060;&#53552;,
              &#49885;&#48324;&#51088;, &#45348;&#53944;&#50892;&#53356;
              &#51221;&#48372;, &#44592;&#44592; &#51221;&#48372;,
              &#44480;&#54616;&#51032; &#49849;&#51064; &#54788;&#54889;,
              &#44172;&#51076; &#45936;&#51060;&#53552;, &#44208;&#51228;
              &#51221;&#48372;, (&#54596;&#50836; &#49884;)
              &#53448;&#51473;&#50521;&#54868; &#50900;&#47131; &#51452;&#49548;
              &#44288;&#47144; &#51221;&#48372;, &#44256;&#44061;
              &#49436;&#48708;&#49828; &#45936;&#51060;&#53552;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#45817;&#49324; &#48277;&#51201; &#44428;&#47532;&#47484;
              &#44396;&#52629;, &#51665;&#54665;,
              &#48320;&#54840;&#54616;&#44592; &#50948;&#54620;
              &#54633;&#47532;&#51201; &#51060;&#54644;</span
            >
          </p>
        </td>
      </tr>
    </table>
    <h3 class="c10" id="h.elxkn51b5i6">
      <span class="c7">&#48512;&#47197; II</span>
    </h3>
    <p class="c15">
      <span class="c13 c3"
        >&#51228;3&#51088;&#47196;&#48512;&#53552; &#49688;&#51665;&#54620;
        &#51221;&#48372;</span
      >
    </p>
    <a id="t.e12e31d33abc5d710e91d1d8de18d91b6b10368e"></a><a id="t.1"></a>
    <table class="c17">
      <tr class="c9">
        <td class="c16" colspan="1" rowspan="1">
          <p class="c19"><span class="c18">&#51228;3&#51088;</span></p>
        </td>
        <td class="c31" colspan="1" rowspan="1">
          <p class="c19">
            <span class="c18">&#51060;&#50857; &#47785;&#51201;</span>
          </p>
        </td>
        <td class="c32" colspan="1" rowspan="1">
          <p class="c19">
            <span class="c18"
              >&#49688;&#51665;&#54616;&#45716;
              &#44060;&#51064;&#51221;&#48372;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5"><span class="c3">Google LLC</span></p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#49324;&#50857;&#51088;&#51032; Google &#44228;&#51221;&#51012;
              &#49324;&#50857;&#54616;&#50668; &#49352; &#44228;&#51221;&#51012;
              &#49444;&#51221;&#54616;&#44592; &#50948;&#54632;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >Google&#50640;&#49436; &#48156;&#44553;&#46108;
              &#51064;&#51613;&#53664;&#53360;</span
            >
          </p>
        </td>
      </tr>
      <tr class="c11">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c5"><span class="c3">Apple Inc.</span></p>
        </td>
        <td class="c4" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >&#49324;&#50857;&#51088;&#51032; Apple &#44228;&#51221;&#51012;
              &#49324;&#50857;&#54616;&#50668; &#49352; &#44228;&#51221;&#51012;
              &#49444;&#51221;&#54616;&#44592; &#50948;&#54632;</span
            >
          </p>
        </td>
        <td class="c12" colspan="1" rowspan="1">
          <p class="c5">
            <span class="c3"
              >Apple&#50640;&#49436; &#48156;&#44553;&#46108;
              &#51064;&#51613;&#53664;&#53360;</span
            >
          </p>
        </td>
      </tr>
    </table>
    <p class="c26"><span class="c13 c24"></span></p>
    <p class="c30"><span class="c13 c24"></span></p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul.lst-kix_htvgndjk16zr-0 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-1 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-7.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-7 0;
}
ul.lst-kix_htvgndjk16zr-2 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-3 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-4 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-5 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-6 {
  list-style-type: none;
}
ul.lst-kix_htvgndjk16zr-7 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-1.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-1 0;
}
ul.lst-kix_htvgndjk16zr-8 {
  list-style-type: none;
}
.lst-kix_mdjth0u1dzg9-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mdjth0u1dzg9-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_plqhaytsgjjt-6.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-6 0;
}
.lst-kix_mdjth0u1dzg9-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_xzf8ac7zhk4q-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_xzf8ac7zhk4q-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mdjth0u1dzg9-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_xzf8ac7zhk4q-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_xzf8ac7zhk4q-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_xzf8ac7zhk4q-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_xzf8ac7zhk4q-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_mdjth0u1dzg9-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_ml4x93p5e9un-5.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-5 0;
}
.lst-kix_mdjth0u1dzg9-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_895z84dykark-2.start {
  counter-reset: lst-ctn-kix_895z84dykark-2 0;
}
.lst-kix_xzf8ac7zhk4q-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_plqhaytsgjjt-4 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-4;
}
.lst-kix_895z84dykark-1 > li {
  counter-increment: lst-ctn-kix_895z84dykark-1;
}
.lst-kix_xzf8ac7zhk4q-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_xzf8ac7zhk4q-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_osvm062131jc-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_osvm062131jc-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_osvm062131jc-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_osvm062131jc-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_vc0e4qrqvfro-8 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-8;
}
.lst-kix_osvm062131jc-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_osvm062131jc-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_osvm062131jc-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_osvm062131jc-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_osvm062131jc-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ml4x93p5e9un-0 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-0;
}
ol.lst-kix_895z84dykark-7.start {
  counter-reset: lst-ctn-kix_895z84dykark-7 0;
}
ol.lst-kix_vc0e4qrqvfro-3 {
  list-style-type: none;
}
.lst-kix_mdjth0u1dzg9-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_vc0e4qrqvfro-0 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-4.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-4 0;
}
.lst-kix_mdjth0u1dzg9-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_vc0e4qrqvfro-7 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-6 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-5 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-4 {
  list-style-type: none;
}
.lst-kix_mdjth0u1dzg9-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_vc0e4qrqvfro-8 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-0.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-0 0;
}
.lst-kix_vc0e4qrqvfro-5 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-5, lower-roman) ". ";
}
.lst-kix_vc0e4qrqvfro-6 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-6, decimal) ". ";
}
ol.lst-kix_c29coztpbqn2-8 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-6 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-7 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-4 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-8 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-8, lower-roman) ". ";
}
ol.lst-kix_c29coztpbqn2-5 {
  list-style-type: none;
}
.lst-kix_s0822h8zjpog-4 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-4;
}
.lst-kix_odcs8zc7hy1i-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_odcs8zc7hy1i-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_vc0e4qrqvfro-3 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-3, decimal) ". ";
}
ol.lst-kix_ml4x93p5e9un-8 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_t8uqy9x5d6j5-2 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-2;
}
.lst-kix_4w3gypoty62b-0 > li:before {
  content: "  ";
}
.lst-kix_odcs8zc7hy1i-5 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_ml4x93p5e9un-3 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-4.start {
  counter-reset: lst-ctn-kix_895z84dykark-4 0;
}
ol.lst-kix_ml4x93p5e9un-2 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-1 {
  list-style-type: none;
}
.lst-kix_s0822h8zjpog-3 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-3;
}
ol.lst-kix_ml4x93p5e9un-0 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-7 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-7.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-7 0;
}
ol.lst-kix_t8uqy9x5d6j5-8.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-8 0;
}
ol.lst-kix_ml4x93p5e9un-6 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-5 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-4 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-2 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-4 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-4;
}
ol.lst-kix_c29coztpbqn2-3 {
  list-style-type: none;
}
.lst-kix_wxeebjp5q4xw-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_c29coztpbqn2-0 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-1 {
  list-style-type: none;
}
.lst-kix_wxeebjp5q4xw-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_odcs8zc7hy1i-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_4w3gypoty62b-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ml4x93p5e9un-1 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-1, lower-latin) ". ";
}
ol.lst-kix_ml4x93p5e9un-3.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-3 0;
}
.lst-kix_4w3gypoty62b-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_ml4x93p5e9un-3 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-3, decimal) ". ";
}
.lst-kix_t8uqy9x5d6j5-1 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-1;
}
.lst-kix_av87ir40dkfm-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_ml4x93p5e9un-5 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-5, lower-roman) ". ";
}
ul.lst-kix_njjlq5el23km-5 {
  list-style-type: none;
}
ul.lst-kix_njjlq5el23km-6 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-4 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-4;
}
ul.lst-kix_njjlq5el23km-7 {
  list-style-type: none;
}
ul.lst-kix_njjlq5el23km-8 {
  list-style-type: none;
}
.lst-kix_av87ir40dkfm-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_njjlq5el23km-0 {
  list-style-type: none;
}
.lst-kix_4w3gypoty62b-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ml4x93p5e9un-7 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-7, lower-latin) ". ";
}
ol.lst-kix_plqhaytsgjjt-8 {
  list-style-type: none;
}
ul.lst-kix_njjlq5el23km-1 {
  list-style-type: none;
}
ul.lst-kix_njjlq5el23km-2 {
  list-style-type: none;
}
ul.lst-kix_njjlq5el23km-3 {
  list-style-type: none;
}
.lst-kix_av87ir40dkfm-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_njjlq5el23km-4 {
  list-style-type: none;
}
.lst-kix_4w3gypoty62b-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_plqhaytsgjjt-4 {
  list-style-type: none;
}
ol.lst-kix_plqhaytsgjjt-5 {
  list-style-type: none;
}
ol.lst-kix_plqhaytsgjjt-6 {
  list-style-type: none;
}
ol.lst-kix_plqhaytsgjjt-7 {
  list-style-type: none;
}
ol.lst-kix_plqhaytsgjjt-0 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-5.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-5 0;
}
ol.lst-kix_plqhaytsgjjt-1 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-7 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-7;
}
ol.lst-kix_plqhaytsgjjt-2 {
  list-style-type: none;
}
.lst-kix_av87ir40dkfm-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_plqhaytsgjjt-3 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-3 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-4 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-1 {
  list-style-type: none;
}
.lst-kix_o3a106j5hv4e-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_o3a106j5hv4e-2 {
  list-style-type: none;
}
.lst-kix_o3a106j5hv4e-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_t8uqy9x5d6j5-6.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-6 0;
}
ol.lst-kix_c29coztpbqn2-2.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-2 0;
}
ul.lst-kix_o3a106j5hv4e-0 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-8 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-6 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-7 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-7 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-8 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-4 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-5 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-5 {
  list-style-type: none;
}
ul.lst-kix_o3a106j5hv4e-6 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-2 {
  list-style-type: none;
}
ul.lst-kix_mdjth0u1dzg9-3 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-0.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-0 0;
}
ul.lst-kix_mdjth0u1dzg9-0 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-6 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-6;
}
ul.lst-kix_mdjth0u1dzg9-1 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-7 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-7;
}
ol.lst-kix_s0822h8zjpog-1.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-1 0;
}
.lst-kix_o3a106j5hv4e-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_av87ir40dkfm-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_plqhaytsgjjt-8.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-8 0;
}
.lst-kix_plqhaytsgjjt-0 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-0;
}
.lst-kix_895z84dykark-8 > li {
  counter-increment: lst-ctn-kix_895z84dykark-8;
}
.lst-kix_o3a106j5hv4e-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_o3a106j5hv4e-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_ml4x93p5e9un-2.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-2 0;
}
ul.lst-kix_qn57cp57pz8n-5 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-7 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-6 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-8 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-3 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-5 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-4 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-6 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-3 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-0 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-4 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-7 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-1 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-8 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-2 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-7 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-3 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-6 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-4 {
  list-style-type: none;
}
ul.lst-kix_wxeebjp5q4xw-0 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-5 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-1 {
  list-style-type: none;
}
.lst-kix_t6i46twrfd5f-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_vsncjlenrete-4 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-2 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-3 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-7 {
  list-style-type: none;
}
.lst-kix_895z84dykark-8 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-8, lower-roman) ". ";
}
ul.lst-kix_qn57cp57pz8n-1 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-2 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-8 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-2 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-1 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-5 {
  list-style-type: none;
}
ul.lst-kix_vsncjlenrete-0 {
  list-style-type: none;
}
ul.lst-kix_v7uuzhs54zyr-6 {
  list-style-type: none;
}
ul.lst-kix_qn57cp57pz8n-0 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-0.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-0 0;
}
.lst-kix_895z84dykark-5 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-5, lower-roman) ". ";
}
.lst-kix_tfcedthh5apk-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_vsncjlenrete-8 {
  list-style-type: none;
}
.lst-kix_tfcedthh5apk-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t6i46twrfd5f-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-1 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-1, lower-latin) ". ";
}
.lst-kix_t6i46twrfd5f-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_vc0e4qrqvfro-5.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-5 0;
}
.lst-kix_895z84dykark-0 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-0, decimal) ". ";
}
.lst-kix_895z84dykark-4 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-4, lower-latin) ". ";
}
.lst-kix_fyozqy2ccmbw-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_fyozqy2ccmbw-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_3o35opftnzi0-5 {
  list-style-type: none;
}
.lst-kix_qn57cp57pz8n-5 > li:before {
  content: "-  ";
}
.lst-kix_t6i46twrfd5f-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_3o35opftnzi0-6 {
  list-style-type: none;
}
ul.lst-kix_3o35opftnzi0-7 {
  list-style-type: none;
}
.lst-kix_qn57cp57pz8n-4 > li:before {
  content: "-  ";
}
.lst-kix_t6i46twrfd5f-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_3o35opftnzi0-8 {
  list-style-type: none;
}
ul.lst-kix_3o35opftnzi0-1 {
  list-style-type: none;
}
.lst-kix_fyozqy2ccmbw-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_3o35opftnzi0-2 {
  list-style-type: none;
}
ul.lst-kix_3o35opftnzi0-3 {
  list-style-type: none;
}
ul.lst-kix_3o35opftnzi0-4 {
  list-style-type: none;
}
.lst-kix_tfcedthh5apk-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_mnj0jzqmcuew-7 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-8 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-8;
}
ul.lst-kix_mnj0jzqmcuew-6 {
  list-style-type: none;
}
.lst-kix_qn57cp57pz8n-8 > li:before {
  content: "-  ";
}
ul.lst-kix_mnj0jzqmcuew-8 {
  list-style-type: none;
}
.lst-kix_tfcedthh5apk-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_mnj0jzqmcuew-3 {
  list-style-type: none;
}
ul.lst-kix_mnj0jzqmcuew-2 {
  list-style-type: none;
}
ul.lst-kix_mnj0jzqmcuew-5 {
  list-style-type: none;
}
ul.lst-kix_mnj0jzqmcuew-4 {
  list-style-type: none;
}
ul.lst-kix_mnj0jzqmcuew-1 {
  list-style-type: none;
}
ul.lst-kix_mnj0jzqmcuew-0 {
  list-style-type: none;
}
.lst-kix_fyozqy2ccmbw-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_3o35opftnzi0-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_c29coztpbqn2-5 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-5, lower-roman) ". ";
}
.lst-kix_s0822h8zjpog-6 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-6, decimal) ". ";
}
.lst-kix_3o35opftnzi0-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_c29coztpbqn2-2 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-2, lower-roman) ". ";
}
.lst-kix_c29coztpbqn2-6 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-6, decimal) ". ";
}
.lst-kix_s0822h8zjpog-2 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-2, lower-roman) ". ";
}
.lst-kix_s0822h8zjpog-1 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-1, lower-latin) ". ";
}
.lst-kix_3o35opftnzi0-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_3o35opftnzi0-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_3o35opftnzi0-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_jjw5eyhagoar-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_wxeebjp5q4xw-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_wxeebjp5q4xw-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_jjw5eyhagoar-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_jjw5eyhagoar-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s0822h8zjpog-8 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-8;
}
.lst-kix_ml4x93p5e9un-6 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-6;
}
.lst-kix_wxeebjp5q4xw-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ml4x93p5e9un-3 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-3;
}
.lst-kix_jjw5eyhagoar-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_c29coztpbqn2-1 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-1, lower-latin) ". ";
}
ol.lst-kix_vc0e4qrqvfro-6.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-6 0;
}
.lst-kix_s0822h8zjpog-5 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-5, lower-roman) ". ";
}
ol.lst-kix_895z84dykark-0.start {
  counter-reset: lst-ctn-kix_895z84dykark-0 0;
}
.lst-kix_c29coztpbqn2-5 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-5;
}
.lst-kix_5yfg1vhyd729-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_odcs8zc7hy1i-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_vc0e4qrqvfro-4.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-4 0;
}
.lst-kix_vc0e4qrqvfro-6 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-6;
}
.lst-kix_895z84dykark-4 > li {
  counter-increment: lst-ctn-kix_895z84dykark-4;
}
.lst-kix_njjlq5el23km-1 > li:before {
  content: "  ";
}
.lst-kix_njjlq5el23km-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_odcs8zc7hy1i-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_vc0e4qrqvfro-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_895z84dykark-6 {
  list-style-type: none;
}
ol.lst-kix_ml4x93p5e9un-6.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-6 0;
}
ol.lst-kix_895z84dykark-5 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-8 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-8 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-7 {
  list-style-type: none;
}
.lst-kix_odcs8zc7hy1i-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_895z84dykark-2 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-6 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-1 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-7 {
  list-style-type: none;
}
.lst-kix_wxeebjp5q4xw-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_895z84dykark-4 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-4 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-3 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-3;
}
ol.lst-kix_895z84dykark-3 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-5 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-2 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-3 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-0 {
  list-style-type: none;
}
ul.lst-kix_5yfg1vhyd729-1 {
  list-style-type: none;
}
.lst-kix_4w3gypoty62b-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_av87ir40dkfm-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_plqhaytsgjjt-5 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-5, lower-roman) ". ";
}
ol.lst-kix_895z84dykark-0 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-4 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-4, lower-latin) ". ";
}
.lst-kix_av87ir40dkfm-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_ml4x93p5e9un-8 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-8, lower-roman) ". ";
}
.lst-kix_5yfg1vhyd729-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_4w3gypoty62b-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s0822h8zjpog-1 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-1;
}
.lst-kix_v7uuzhs54zyr-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ml4x93p5e9un-1 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-1;
}
.lst-kix_xr4wbtfr7i3x-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mnj0jzqmcuew-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_o3a106j5hv4e-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_xr4wbtfr7i3x-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_3o35opftnzi0-0 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-1 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-1, lower-latin) ". ";
}
.lst-kix_v7uuzhs54zyr-6 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_ml4x93p5e9un-8.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-8 0;
}
.lst-kix_9kzgrta7leqc-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_s0822h8zjpog-6 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-6;
}
.lst-kix_9rcb9egve5ts-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qn57cp57pz8n-1 > li:before {
  content: "-  ";
}
.lst-kix_pjvc257hwq35-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_osvm062131jc-1 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-0 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-0, decimal) ". ";
}
ul.lst-kix_osvm062131jc-0 {
  list-style-type: none;
}
ul.lst-kix_osvm062131jc-7 {
  list-style-type: none;
}
ul.lst-kix_osvm062131jc-6 {
  list-style-type: none;
}
ol.lst-kix_vc0e4qrqvfro-3.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-3 0;
}
ul.lst-kix_osvm062131jc-8 {
  list-style-type: none;
}
ul.lst-kix_osvm062131jc-3 {
  list-style-type: none;
}
ul.lst-kix_osvm062131jc-2 {
  list-style-type: none;
}
ul.lst-kix_osvm062131jc-5 {
  list-style-type: none;
}
.lst-kix_9kzgrta7leqc-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_osvm062131jc-4 {
  list-style-type: none;
}
.lst-kix_9rcb9egve5ts-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mnj0jzqmcuew-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t8uqy9x5d6j5-5 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-5;
}
.lst-kix_9rcb9egve5ts-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_pjvc257hwq35-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_iaym45bg3mlk-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_ml4x93p5e9un-7.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-7 0;
}
.lst-kix_o3a106j5hv4e-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_tfcedthh5apk-8 {
  list-style-type: none;
}
.lst-kix_htvgndjk16zr-0 > li:before {
  content: "  ";
}
.lst-kix_htvgndjk16zr-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_tfcedthh5apk-6 {
  list-style-type: none;
}
ul.lst-kix_tfcedthh5apk-7 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-8.start {
  counter-reset: lst-ctn-kix_895z84dykark-8 0;
}
.lst-kix_htvgndjk16zr-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_htvgndjk16zr-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_htvgndjk16zr-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_htvgndjk16zr-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_vsncjlenrete-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_iaym45bg3mlk-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_htvgndjk16zr-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_iaym45bg3mlk-6 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_vc0e4qrqvfro-8.start {
  counter-reset: lst-ctn-kix_vc0e4qrqvfro-8 0;
}
.lst-kix_vsncjlenrete-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_iaym45bg3mlk-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_htvgndjk16zr-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_vsncjlenrete-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9bu7zmlwdxim-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_93cg229rxw45-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_tfcedthh5apk-0 {
  list-style-type: none;
}
ul.lst-kix_tfcedthh5apk-1 {
  list-style-type: none;
}
.lst-kix_iaym45bg3mlk-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9bu7zmlwdxim-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_93cg229rxw45-0 > li:before {
  content: "  ";
}
ul.lst-kix_tfcedthh5apk-4 {
  list-style-type: none;
}
.lst-kix_htvgndjk16zr-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_tfcedthh5apk-5 {
  list-style-type: none;
}
.lst-kix_vsncjlenrete-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_c29coztpbqn2-1.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-1 0;
}
ul.lst-kix_tfcedthh5apk-2 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-2 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-2;
}
.lst-kix_9bu7zmlwdxim-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_tfcedthh5apk-3 {
  list-style-type: none;
}
.lst-kix_vsncjlenrete-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t8uqy9x5d6j5-7 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-7;
}
.lst-kix_93cg229rxw45-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_93cg229rxw45-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_vsncjlenrete-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_vsncjlenrete-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_93cg229rxw45-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t8uqy9x5d6j5-0 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-0, decimal) ". ";
}
.lst-kix_vsncjlenrete-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_93cg229rxw45-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_vsncjlenrete-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_93cg229rxw45-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t8uqy9x5d6j5-6 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-6, decimal) ". ";
}
ol.lst-kix_s0822h8zjpog-1 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-0 {
  list-style-type: none;
}
.lst-kix_t8uqy9x5d6j5-5 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-5, lower-roman) ". ";
}
ol.lst-kix_plqhaytsgjjt-0.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-0 0;
}
.lst-kix_t8uqy9x5d6j5-4 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-4, lower-latin) ". ";
}
ol.lst-kix_s0822h8zjpog-8 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-7 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-7.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-7 0;
}
.lst-kix_93cg229rxw45-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_s0822h8zjpog-6 {
  list-style-type: none;
}
.lst-kix_t8uqy9x5d6j5-1 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-1, lower-latin) ". ";
}
.lst-kix_t8uqy9x5d6j5-3 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-3, decimal) ". ";
}
ol.lst-kix_s0822h8zjpog-5 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-4 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-3 {
  list-style-type: none;
}
.lst-kix_93cg229rxw45-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_s0822h8zjpog-2 {
  list-style-type: none;
}
.lst-kix_t8uqy9x5d6j5-2 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-2, lower-roman) ". ";
}
ol.lst-kix_s0822h8zjpog-5.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-5 0;
}
ol.lst-kix_ml4x93p5e9un-4.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-4 0;
}
.lst-kix_t8uqy9x5d6j5-0 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-0;
}
ol.lst-kix_c29coztpbqn2-6.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-6 0;
}
.lst-kix_895z84dykark-6 > li {
  counter-increment: lst-ctn-kix_895z84dykark-6;
}
.lst-kix_c29coztpbqn2-8 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-8;
}
.lst-kix_9bu7zmlwdxim-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_9bu7zmlwdxim-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s0822h8zjpog-5 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-5;
}
.lst-kix_895z84dykark-3 > li {
  counter-increment: lst-ctn-kix_895z84dykark-3;
}
ol.lst-kix_plqhaytsgjjt-5.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-5 0;
}
.lst-kix_9bu7zmlwdxim-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9bu7zmlwdxim-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_s0822h8zjpog-2 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-2;
}
.lst-kix_9bu7zmlwdxim-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_xzf8ac7zhk4q-7 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-6 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-5 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-8 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-4 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-7 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-3 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-6 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-2 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-5 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-1 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-4 {
  list-style-type: none;
}
ul.lst-kix_xzf8ac7zhk4q-0 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-3 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-2 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-1 {
  list-style-type: none;
}
ul.lst-kix_t6i46twrfd5f-0 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-2.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-2 0;
}
.lst-kix_9bu7zmlwdxim-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_895z84dykark-1.start {
  counter-reset: lst-ctn-kix_895z84dykark-1 0;
}
.lst-kix_5yfg1vhyd729-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_njjlq5el23km-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_njjlq5el23km-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_s0822h8zjpog-0.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-0 0;
}
.lst-kix_5yfg1vhyd729-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_njjlq5el23km-0 > li:before {
  content: "  ";
}
.lst-kix_njjlq5el23km-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_njjlq5el23km-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_s0822h8zjpog-3.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-3 0;
}
.lst-kix_5yfg1vhyd729-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_jjw5eyhagoar-6 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-0 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-5 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-8 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-7 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-4 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-3 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-2 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-1 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-8 {
  list-style-type: none;
}
.lst-kix_t8uqy9x5d6j5-3 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-3;
}
ol.lst-kix_t8uqy9x5d6j5-7 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-0 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-6 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-5 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-2 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-1 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-4 {
  list-style-type: none;
}
ul.lst-kix_jjw5eyhagoar-3 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-6 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-6, decimal) ". ";
}
.lst-kix_plqhaytsgjjt-4 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-4, lower-latin) ". ";
}
ul.lst-kix_odcs8zc7hy1i-7 {
  list-style-type: none;
}
ol.lst-kix_plqhaytsgjjt-7.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-7 0;
}
ul.lst-kix_odcs8zc7hy1i-8 {
  list-style-type: none;
}
ul.lst-kix_odcs8zc7hy1i-5 {
  list-style-type: none;
}
ul.lst-kix_odcs8zc7hy1i-6 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-5 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-5;
}
ul.lst-kix_odcs8zc7hy1i-0 {
  list-style-type: none;
}
.lst-kix_5yfg1vhyd729-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_odcs8zc7hy1i-3 {
  list-style-type: none;
}
ul.lst-kix_odcs8zc7hy1i-4 {
  list-style-type: none;
}
ul.lst-kix_odcs8zc7hy1i-1 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-8 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-8, lower-roman) ". ";
}
ul.lst-kix_odcs8zc7hy1i-2 {
  list-style-type: none;
}
.lst-kix_v7uuzhs54zyr-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_xr4wbtfr7i3x-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_vc0e4qrqvfro-3 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-3;
}
.lst-kix_mnj0jzqmcuew-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_xr4wbtfr7i3x-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9kzgrta7leqc-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_v7uuzhs54zyr-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_895z84dykark-2 > li {
  counter-increment: lst-ctn-kix_895z84dykark-2;
}
.lst-kix_c29coztpbqn2-1 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-1;
}
.lst-kix_mnj0jzqmcuew-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_c29coztpbqn2-0 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-0;
}
.lst-kix_xr4wbtfr7i3x-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_9kzgrta7leqc-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_v7uuzhs54zyr-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_xr4wbtfr7i3x-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_xr4wbtfr7i3x-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9kzgrta7leqc-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_9kzgrta7leqc-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_v7uuzhs54zyr-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_plqhaytsgjjt-2 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-2, lower-roman) ". ";
}
.lst-kix_t8uqy9x5d6j5-8 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-8, lower-roman) ". ";
}
.lst-kix_plqhaytsgjjt-0 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-0, decimal) ". ";
}
.lst-kix_qn57cp57pz8n-0 > li:before {
  content: "-  ";
}
.lst-kix_pjvc257hwq35-0 > li:before {
  content: "  ";
}
.lst-kix_pjvc257hwq35-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_t8uqy9x5d6j5-5.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-5 0;
}
.lst-kix_9rcb9egve5ts-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_iaym45bg3mlk-7 {
  list-style-type: none;
}
.lst-kix_9kzgrta7leqc-0 > li:before {
  content: "  ";
}
ul.lst-kix_iaym45bg3mlk-6 {
  list-style-type: none;
}
ul.lst-kix_iaym45bg3mlk-8 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-6 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-6;
}
.lst-kix_9rcb9egve5ts-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_ml4x93p5e9un-1.start {
  counter-reset: lst-ctn-kix_ml4x93p5e9un-1 0;
}
ol.lst-kix_c29coztpbqn2-3.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-3 0;
}
ul.lst-kix_iaym45bg3mlk-1 {
  list-style-type: none;
}
.lst-kix_pjvc257hwq35-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_iaym45bg3mlk-0 {
  list-style-type: none;
}
.lst-kix_iaym45bg3mlk-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_iaym45bg3mlk-3 {
  list-style-type: none;
}
ul.lst-kix_iaym45bg3mlk-2 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-3.start {
  counter-reset: lst-ctn-kix_895z84dykark-3 0;
}
ul.lst-kix_iaym45bg3mlk-5 {
  list-style-type: none;
}
.lst-kix_9rcb9egve5ts-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mnj0jzqmcuew-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mnj0jzqmcuew-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_iaym45bg3mlk-4 {
  list-style-type: none;
}
.lst-kix_iaym45bg3mlk-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_pjvc257hwq35-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_pjvc257hwq35-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t8uqy9x5d6j5-8 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-8;
}
.lst-kix_9rcb9egve5ts-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mnj0jzqmcuew-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_fyozqy2ccmbw-0 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-2 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-1 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-7.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-7 0;
}
ul.lst-kix_fyozqy2ccmbw-8 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-7 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-4.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-4 0;
}
ul.lst-kix_fyozqy2ccmbw-4 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-3 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-6 {
  list-style-type: none;
}
ul.lst-kix_fyozqy2ccmbw-5 {
  list-style-type: none;
}
ol.lst-kix_c29coztpbqn2-4.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-4 0;
}
.lst-kix_tfcedthh5apk-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-6 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-6, decimal) ". ";
}
.lst-kix_fyozqy2ccmbw-0 > li:before {
  content: "  ";
}
.lst-kix_895z84dykark-7 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-7, lower-latin) ". ";
}
.lst-kix_t6i46twrfd5f-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t6i46twrfd5f-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-2 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-2, lower-roman) ". ";
}
.lst-kix_fyozqy2ccmbw-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_fyozqy2ccmbw-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-3 > li:before {
  content: "" counter(lst-ctn-kix_895z84dykark-3, decimal) ". ";
}
.lst-kix_qn57cp57pz8n-2 > li:before {
  content: "-  ";
}
.lst-kix_t6i46twrfd5f-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qn57cp57pz8n-3 > li:before {
  content: "-  ";
}
ol.lst-kix_plqhaytsgjjt-3.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-3 0;
}
.lst-kix_plqhaytsgjjt-1 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-1;
}
.lst-kix_qn57cp57pz8n-6 > li:before {
  content: "-  ";
}
.lst-kix_tfcedthh5apk-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t6i46twrfd5f-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_9bu7zmlwdxim-6 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-7 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-4 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-5 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-2 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-5.start {
  counter-reset: lst-ctn-kix_895z84dykark-5 0;
}
ul.lst-kix_xr4wbtfr7i3x-8 {
  list-style-type: none;
}
.lst-kix_qn57cp57pz8n-7 > li:before {
  content: "-  ";
}
ul.lst-kix_9bu7zmlwdxim-3 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-2.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-2 0;
}
ul.lst-kix_xr4wbtfr7i3x-7 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-0 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-1 {
  list-style-type: none;
}
.lst-kix_tfcedthh5apk-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_xr4wbtfr7i3x-4 {
  list-style-type: none;
}
.lst-kix_fyozqy2ccmbw-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_fyozqy2ccmbw-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_xr4wbtfr7i3x-3 {
  list-style-type: none;
}
ul.lst-kix_xr4wbtfr7i3x-6 {
  list-style-type: none;
}
ul.lst-kix_xr4wbtfr7i3x-5 {
  list-style-type: none;
}
ul.lst-kix_xr4wbtfr7i3x-0 {
  list-style-type: none;
}
ul.lst-kix_9bu7zmlwdxim-8 {
  list-style-type: none;
}
ul.lst-kix_xr4wbtfr7i3x-2 {
  list-style-type: none;
}
ul.lst-kix_xr4wbtfr7i3x-1 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-4 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-4, lower-latin) ". ";
}
.lst-kix_s0822h8zjpog-7 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-7, lower-latin) ". ";
}
.lst-kix_plqhaytsgjjt-5 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-5;
}
ol.lst-kix_plqhaytsgjjt-4.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-4 0;
}
.lst-kix_c29coztpbqn2-3 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-3, decimal) ". ";
}
.lst-kix_s0822h8zjpog-8 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-8, lower-roman) ". ";
}
.lst-kix_3o35opftnzi0-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_vc0e4qrqvfro-2 {
  list-style-type: none;
}
ol.lst-kix_895z84dykark-6.start {
  counter-reset: lst-ctn-kix_895z84dykark-6 0;
}
.lst-kix_895z84dykark-7 > li {
  counter-increment: lst-ctn-kix_895z84dykark-7;
}
ul.lst-kix_vc0e4qrqvfro-1 {
  list-style-type: none;
}
.lst-kix_3o35opftnzi0-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s0822h8zjpog-0 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-0, decimal) ". ";
}
.lst-kix_3o35opftnzi0-2 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_t8uqy9x5d6j5-3.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-3 0;
}
.lst-kix_c29coztpbqn2-8 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-8, lower-roman) ". ";
}
.lst-kix_c29coztpbqn2-7 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-7, lower-latin) ". ";
}
.lst-kix_3o35opftnzi0-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t8uqy9x5d6j5-6 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-6;
}
.lst-kix_wxeebjp5q4xw-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_wxeebjp5q4xw-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_tfcedthh5apk-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-0 > li {
  counter-increment: lst-ctn-kix_895z84dykark-0;
}
.lst-kix_tfcedthh5apk-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_jjw5eyhagoar-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_jjw5eyhagoar-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_pjvc257hwq35-3 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-2 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-5 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-4 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-7 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-6 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-8 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-0 > li:before {
  content: "" counter(lst-ctn-kix_c29coztpbqn2-0, decimal) ". ";
}
.lst-kix_s0822h8zjpog-3 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-3, decimal) ". ";
}
.lst-kix_s0822h8zjpog-4 > li:before {
  content: "" counter(lst-ctn-kix_s0822h8zjpog-4, lower-latin) ". ";
}
.lst-kix_c29coztpbqn2-2 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-2;
}
.lst-kix_jjw5eyhagoar-0 > li:before {
  content: "  ";
}
.lst-kix_jjw5eyhagoar-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_pjvc257hwq35-1 {
  list-style-type: none;
}
ul.lst-kix_pjvc257hwq35-0 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-7 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-6 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-0 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-7 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-7, lower-latin) ". ";
}
ul.lst-kix_93cg229rxw45-6 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-5 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-8 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-8;
}
ul.lst-kix_9rcb9egve5ts-4 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-2 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-8 {
  list-style-type: none;
}
.lst-kix_odcs8zc7hy1i-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_9rcb9egve5ts-3 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-1 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-3 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-2 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-4 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-2 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-1 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-3 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-5 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-0 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-6 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-4 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-4, lower-latin) ". ";
}
ul.lst-kix_93cg229rxw45-4 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-5 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-8 {
  list-style-type: none;
}
ul.lst-kix_9kzgrta7leqc-7 {
  list-style-type: none;
}
ul.lst-kix_93cg229rxw45-1 {
  list-style-type: none;
}
.lst-kix_c29coztpbqn2-4 > li {
  counter-increment: lst-ctn-kix_c29coztpbqn2-4;
}
ul.lst-kix_93cg229rxw45-0 {
  list-style-type: none;
}
.lst-kix_5yfg1vhyd729-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_9rcb9egve5ts-8 {
  list-style-type: none;
}
ul.lst-kix_9rcb9egve5ts-7 {
  list-style-type: none;
}
.lst-kix_njjlq5el23km-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_xzf8ac7zhk4q-8 {
  list-style-type: none;
}
.lst-kix_vc0e4qrqvfro-5 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-5;
}
.lst-kix_vc0e4qrqvfro-0 > li:before {
  content: "" counter(lst-ctn-kix_vc0e4qrqvfro-0, decimal) ". ";
}
.lst-kix_wxeebjp5q4xw-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_odcs8zc7hy1i-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_jjw5eyhagoar-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_5yfg1vhyd729-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_av87ir40dkfm-0 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-7 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-7, lower-latin) ". ";
}
ul.lst-kix_av87ir40dkfm-1 {
  list-style-type: none;
}
.lst-kix_t8uqy9x5d6j5-4 > li {
  counter-increment: lst-ctn-kix_t8uqy9x5d6j5-4;
}
.lst-kix_ml4x93p5e9un-2 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-2, lower-roman) ". ";
}
ol.lst-kix_c29coztpbqn2-8.start {
  counter-reset: lst-ctn-kix_c29coztpbqn2-8 0;
}
.lst-kix_vc0e4qrqvfro-7 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-7;
}
ul.lst-kix_av87ir40dkfm-8 {
  list-style-type: none;
}
.lst-kix_plqhaytsgjjt-3 > li:before {
  content: "" counter(lst-ctn-kix_plqhaytsgjjt-3, decimal) ". ";
}
ul.lst-kix_av87ir40dkfm-6 {
  list-style-type: none;
}
.lst-kix_ml4x93p5e9un-6 > li:before {
  content: "" counter(lst-ctn-kix_ml4x93p5e9un-6, decimal) ". ";
}
.lst-kix_av87ir40dkfm-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_av87ir40dkfm-7 {
  list-style-type: none;
}
ul.lst-kix_av87ir40dkfm-4 {
  list-style-type: none;
}
ol.lst-kix_s0822h8zjpog-8.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-8 0;
}
ul.lst-kix_av87ir40dkfm-5 {
  list-style-type: none;
}
ul.lst-kix_av87ir40dkfm-2 {
  list-style-type: none;
}
ol.lst-kix_t8uqy9x5d6j5-0.start {
  counter-reset: lst-ctn-kix_t8uqy9x5d6j5-0 0;
}
ul.lst-kix_av87ir40dkfm-3 {
  list-style-type: none;
}
.lst-kix_5yfg1vhyd729-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_4w3gypoty62b-1 > li:before {
  content: "  ";
}
.lst-kix_av87ir40dkfm-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_4w3gypoty62b-0 {
  list-style-type: none;
}
.lst-kix_4w3gypoty62b-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ml4x93p5e9un-2 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-2;
}
.lst-kix_njjlq5el23km-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_4w3gypoty62b-6 {
  list-style-type: none;
}
.lst-kix_v7uuzhs54zyr-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_4w3gypoty62b-5 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-8 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-7 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-2 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-1 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-4 {
  list-style-type: none;
}
ul.lst-kix_4w3gypoty62b-3 {
  list-style-type: none;
}
.lst-kix_o3a106j5hv4e-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_o3a106j5hv4e-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_v7uuzhs54zyr-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_xr4wbtfr7i3x-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s0822h8zjpog-7 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-7;
}
.lst-kix_s0822h8zjpog-0 > li {
  counter-increment: lst-ctn-kix_s0822h8zjpog-0;
}
.lst-kix_v7uuzhs54zyr-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_xr4wbtfr7i3x-5 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_plqhaytsgjjt-1.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-1 0;
}
.lst-kix_t8uqy9x5d6j5-7 > li:before {
  content: "" counter(lst-ctn-kix_t8uqy9x5d6j5-7, lower-latin) ". ";
}
.lst-kix_9kzgrta7leqc-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_9kzgrta7leqc-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ml4x93p5e9un-7 > li {
  counter-increment: lst-ctn-kix_ml4x93p5e9un-7;
}
ol.lst-kix_s0822h8zjpog-6.start {
  counter-reset: lst-ctn-kix_s0822h8zjpog-6 0;
}
.lst-kix_pjvc257hwq35-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_9rcb9egve5ts-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_plqhaytsgjjt-3 > li {
  counter-increment: lst-ctn-kix_plqhaytsgjjt-3;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_iaym45bg3mlk-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_895z84dykark-5 > li {
  counter-increment: lst-ctn-kix_895z84dykark-5;
}
.lst-kix_mnj0jzqmcuew-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_pjvc257hwq35-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_iaym45bg3mlk-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_plqhaytsgjjt-2.start {
  counter-reset: lst-ctn-kix_plqhaytsgjjt-2 0;
}
.lst-kix_mnj0jzqmcuew-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_9rcb9egve5ts-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_vc0e4qrqvfro-0 > li {
  counter-increment: lst-ctn-kix_vc0e4qrqvfro-0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c10 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.3;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
  margin: 15px 0;
}
.c15 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c6 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  margin-left: 36pt;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c20 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  margin-left: 36pt;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
  height: 11pt;
}
.c8 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-bottom: 0;
  line-height: 1.15;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c32 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #737985;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c16 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #737985;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c4 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c12 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #161519;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c1 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #161519;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c31 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c30 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c26 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c7 {
  color: #161519;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: Arial;
  font-style: normal;
}
.c2 {
  color: #1a1a1a;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: Arial;
  font-style: normal;
}
.c25 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: line-through;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-family: Arial;
  font-style: normal;
}
.c19 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c5 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: Arial;
  font-style: normal;
}
.c21 {
  text-decoration-skip-ink: none;
  font-size: 10.5pt;
  -webkit-text-decoration-skip: none;
  color: #0075ff;
  text-decoration: underline;
}
.c17 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c29 {
  background-color: #fff;
  max-width: 800pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c23 {
  font-size: 12pt;
  color: #1a1a1a;
  font-weight: 700;
  margin-bottom: 15px;
}
.c3 {
  color: #161519;
  font-size: 10.5pt;
}
.c14 {
  color: inherit;
  text-decoration: inherit;
}
.c24 {
  color: #000;
  font-size: 11pt;
}
.c0 {
  padding: 0;
  margin: 0;
}
.c18 {
  color: #737985;
  font-size: 10.5pt;
}
.c28 {
  height: 28.5pt;
}
.c11 {
  height: 44.2pt;
}
.c27 {
  height: 60pt;
}
.c22 {
  height: 75.8pt;
}
.c9 {
  height: 24.8pt;
}
.title {
  padding-top: 0;
  color: #000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0;
  color: #666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
p {
  margin: 0;
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
h1 {
  padding-top: 20pt;
  color: #000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>