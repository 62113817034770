<template>
    <section class="footer">
        <!-- <div class="footer-box no1">
            <div class="container row">
                <div class="col-12 text-line">
                    <div class="icon-area pc">
                        <a class="linktree" href="https://linktr.ee/mintmarble" target="_black"></a>
                        <a class="discord" href="https://discord.com/invite/8sRAVzsE2Y" target="_black"></a>
                        <a class="twitter" href="https://twitter.com/mintmarble_" target="_black"></a>
                        <a class="direct" href="https://t.me/mintmarble_social" target="_black"></a>
                        <a class="sns3" href="https://medium.com/@mintmarble" target="_black"></a>
                    </div>
                    <div class="icon-area mb">
                        <div class="top">
                            <a class="linktree" href="https://linktr.ee/mintmarble" target="_blank"></a>
                            <a class="discord" href="https://discord.com/invite/8sRAVzsE2Y" target="_blank"></a>
                            <a class="twitter" href="https://twitter.com/mintmarble_" target="_blank"></a>
                            <a class="direct" href="https://t.me/mintmarble_social" target="_blank"></a>
                        </div>
                        <div class="end">
                            <a class="sns3" href="https://medium.com/@mintmarble" target="_blank"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="footer-box no2">
            <div class="container row">
                <div class="img-area">
                    <div class="top">
                        <a class="email" href="mailto:hello@mintmarble.com"><img src="@/assets/images/footer-email.png" alt=""></a>
                        <a class="download"  href="https://cdn.metaware.ltd/assets/storage/mintmarble_media_kit.zip"><img src="@/assets/images/footer-down.png" alt=""></a>
                    </div>
                    <div class="end">
                        <img class="end-img" src="@/assets/images/footer-end-img.png" >
                        <img class="copy-m" src="@/assets/images/copy-m.png" >
                        <img class="dev-m" src="@/assets/images/develop-m.png" >
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
export default {
    name: 'Footer',
    data(){
        return {
        }
    },
    props: {
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        goTop(){
            window.scrollTo(0,0)
        }
    },
}
</script>