<template>
  <div class="doc-content c24">
    <p class="c25 title" id="h.bi16qg28kw6p">
      <span class="c28">Privacy Policy</span>
    </p>
    <p class="c11">
      <span class="c7"
        >Metaware PTE, Ltd. and its affiliates (&ldquo;we&rdquo;,
        &ldquo;our&rdquo;, &ldquo;us&rdquo;, &ldquo;the Company&rdquo;) is
        committed to protecting the privacy and security of the information we
        collect and being transparent about the ways in which we collect and
        process your information. This statement (the &ldquo;Privacy
        Policy&rdquo;) sets forth our policies and practices for handling the
        information we collect from or about you. It applies to the Mintmarble
        services that we operate and that link to this Privacy Policy, such as
        the Mintmarble game, Creator Center, and our online communities
        (collectively, the &ldquo;Services&rdquo;).</span
      >
    </p>
    <p class="c11">
      <span class="c12"
        >It is important that you read this Privacy Policy, together with any
        other notices we may provide on specific occasions when we are
        collecting or processing your personal data, so that you are aware of
        how and why we are using such personal data and what your rights are
        under the relevant data protection laws.</span
      >
    </p>
    <h3 class="c8" id="h.1d3ysdl9ourd">
      <span class="c29">COLLECTION OF PERSONAL DATA</span>
    </h3>
    <p class="c11">
      <span class="c12"
        >In this notice, &ldquo;personal data&rdquo; means any information about
        an identified or identifiable individual. We process the following
        categories of personal data from you when you use our Services:</span
      >
    </p>
    <ul class="c22 lst-kix_kiyea4n6luut-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Registration information such as authentication token issued by the
          third party platform through which you log-in to sign up to Mintmarble
          app (Google, Apple, and etc.) and access the Games through their
          respective mobile app (Google only).</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c12"
          >Decentralized wallet key and technical information which you used for
          Mintmarble official website (e.g. browser type, OS, device type, IP
          address).</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information necessary to manage inquiries and requests from you such
          as your in-game ID or nickname and information related to the issue
          you are contacting us about.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information necessary to manage your posts on the Mintmarble
          community such as your name and profile image, the date of posts, and
          IP access date.</span
        >
      </li>
    </ul>
    <p class="c33 c34 c37"><span class="c7"></span></p>
    <p class="c11">
      <span class="c7"
        >We receive the above categories of information from you when you
        directly provide it to us, or from other sources, including from users
        of our Services and third-party services and organizations. (e.g.
        Google, Apple). Please see Appendix II for more information on personal
        data collected from third parties. Without this information, we are not
        able to provide you with all the requested services, and any differences
        in services are related to your information.</span
      >
    </p>
    <p class="c11">
      <span class="c7"
        >We do not collect any sensitive information (i.e. data consisting of
        racial or ethnic origin, political opinions, religious or philosophical
        beliefs, or trade union membership, genetic data, biometric data, data
        concerning health or data concerning a natural person&#39;s sex life or
        sexual orientation) or any data relating to criminal conviction and
        offences from you.</span
      >
    </p>
    <h3 class="c8" id="h.zfx7xko0hceg">
      <span class="c6">USE OF PERSONAL DATA</span>
    </h3>
    <p class="c33">
      <span class="c7"
        >We will use your personal data for the following purposes:</span
      >
    </p>
    <ul class="c22 lst-kix_4al1ahj1dkpg-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To provide you with the Services. We may use your information to open
          and maintain user accounts, facilitate the functionality of our
          Services, customize the Services, and to provide forum and community
          features;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To improve the quality of our Services. We may use your information
          to maintain, improve, and administer our Services, including by
          troubleshooting, data analytics, and testing;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To keep our Services and users safe. This includes to detect users
          illegally using our programs; and to otherwise prevent and respond to
          fraud, abuse, security risks, and technical issues.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To send you electronic correspondence. We may send you emails or
          otherwise communicate with you in order to manage inquiries and
          requests from you or otherwise to communicate about your use of the
          Services.</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To comply with applicable laws and regulations. This includes using
          your information to ensure compliance with legal obligations (such as
          recordkeeping obligations), solve disputes, enforce our contractual
          agreements, and to establish, exercise or defend legal claims.</span
        >
      </li>
    </ul>
    <p class="c33">
      <span class="c7"
        >We will use your personal data on the following legal bases as
        described in Appendix I:</span
      >
    </p>
    <ul class="c22 lst-kix_4al1ahj1dkpg-0">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To perform a contract we have entered into with you;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">To comply with a legal obligation; or</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.</span
        >
      </li>
    </ul>
    <p class="c33">
      <span class="c7"
        >We may also use your personal data on the following less common legal
        bases:</span
      >
    </p>
    <ul class="c22 lst-kix_4al1ahj1dkpg-0">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >To protect your interests (or someone else&rsquo;s interests).</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">Where your consent is given; or</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c12"
          >Where it is required to defend or pursue legal claims.</span
        >
      </li>
    </ul>
    <h3 class="c8" id="h.dxykcsjiyebr">
      <span class="c6">AUTOMATED DECISION-MAKING AND PROFILING</span>
    </h3>
    <p class="c11">
      <span class="c12"
        >We do not engage in profiling or other automated decision making in the
        course of our relationship with you.</span
      >
    </p>
    <h3 class="c8" id="h.gg3w2j56hbxf">
      <span class="c6">DISCLOSURE OF PERSONAL DATA</span>
    </h3>
    <p class="c11">
      <span class="c7"
        >We may share your personal data with third parties in the following
        contexts:</span
      >
    </p>
    <p class="c11 c34"><span class="c7"></span></p>
    <p class="c11">
      <span class="c7"
        >Disclosure to our service providers and external controllers:</span
      >
    </p>
    <p class="c11">
      <span class="c7"
        >We may use third-party vendors and service providers (or &ldquo;data
        processors&rdquo;) to process your personal information for the purposes
        outlined above. Our data processors operate only in accordance with our
        instructions, in line with this policy, and are subject to appropriate
        confidentiality and security obligations.</span
      >
    </p>
    <ul class="c22 lst-kix_86vsfro9kiyi-0 start">
      <li class="c11 c37 li-bullet-0">
        <span class="c7"
          >Disclosure to external controllers:<br />We may share your
          information with external controllers to process your personal
          information for the purposes outlined above. Please see Appendix III
          for more information on personal data provided to external
          controllers.</span
        >
      </li>
      <li class="c11 c37 li-bullet-0">
        <span class="c7"
          >Disclosure pursuant to business transfers:<br />We may share your
          information in connection with a substantial corporate transaction,
          such as the sale of a website, a merger, consolidation, asset sale,
          initial public offering, or in the unlikely event of bankruptcy.</span
        >
      </li>
      <li class="c11 c37 li-bullet-0">
        <span class="c7"
          >Disclosure for legal purposes:<br />We may also transfer your
          personal data to law enforcement agencies, governmental authorities,
          legal counsel and external consultants in compliance with applicable
          data protection laws. The legal basis for such processing is
          compliance with a legal obligation to which we are subject to or our
          legitimate interests, such as the exercise or defense of legal
          claims.</span
        >
      </li>
      <li class="c11 c37 li-bullet-0">
        <span class="c7"
          >Disclosure with your consent:<br />Lastly, we may also share
          information about you to third parties when you have consented to
          it.</span
        >
      </li>
    </ul>
    <p class="c11">
      <span class="c7"
        >Our services allow you to upload and share messages and other content
        with others. If you choose to engage in public activities on the
        Services, you should be aware that any information you share there can
        be read, collected or used by other users of these areas. You should
        always exercise discretion and use caution when disclosing information
        while participating in these areas. We are not responsible for the
        information you choose to submit in these public areas.</span
      >
    </p>
    <p class="c11">
      <span class="c12"
        >If a recipient of your personal data is located outside of the EU and
        the European Economic Area (&ldquo;EEA&rdquo;) in a country that is not
        recognized by the European Commission as ensuring an adequate level of
        data protection, we will implement appropriate measures to ensure that
        your personal data remains protected and secure when it is transferred
        outside of your home country, in accordance with applicable data
        protection and privacy laws. These safeguards include data transfer
        agreements implementing European Commission&#39;s Standard Contractual
        Clauses (a form of data transfer agreement pre-approved by the European
        Commission as providing adequate safeguards for personal data). You may
        ask for a copy of such appropriate measures by contacting the company
        published in the policy.</span
      >
    </p>
    <h3 class="c8" id="h.hreqrhazoyvr"><span class="c6">CHILDREN</span></h3>
    <p class="c11">
      <span class="c12"
        >Services are not intended for anyone under age of 14. We do not
        knowingly collect or sell any information from children, as defined by
        applicable law, without parental consent or as otherwise permitted by
        applicable law.</span
      >
    </p>
    <h3 class="c8" id="h.2mcyu0dtb4g1">
      <span class="c6">DATA SECURITY</span>
    </h3>
    <p class="c11">
      <span class="c7"
        >We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used or accessed in an
        unauthorised way, altered or disclosed. In addition, we limit access to
        your personal data to only those employees, agents, contractors and
        other third parties who have a business need for such access.</span
      >
    </p>
    <p class="c11">
      <span class="c12"
        >We have put in place procedures to deal with any suspected data
        security breach and will notify you and any applicable regulator of a
        suspected breach where we are legally required to do so.</span
      >
    </p>
    <h3 class="c8" id="h.kmjeqot3tnx5">
      <span class="c6">DATA RETENTION</span>
    </h3>
    <p class="c11">
      <span class="c7"
        >We will only retain your personal data for as long as necessary to
        fulfil the purposes set out above, including for the purposes of
        satisfying any legal, accounting, or reporting requirements. To
        determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.</span
      >
    </p>
    <p class="c11">
      <span class="c7"
        >Broadly, most of your data will be deleted immediately upon termination
        of our relationship with you. But in the following circumstances, your
        personal data will be retained for different set periods:</span
      >
    </p>
    <p class="c11 c34"><span class="c7"></span></p>
    <ul class="c22 lst-kix_itp1gg4p4tc5-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information of users who have transaction history in Mintmarble
          auction &ndash; for 5 years</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information of users who have cryptocurrency transaction history in
          Mintmarble &ndash; for 5 years</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information related to your inquiry submitted to our customer service
          center &ndash; for 3 year</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Information of creators at Creator Center &ndash; for 5 years after
          sale of NFT</span
        >
      </li>
    </ul>
    <p class="c11">
      <span class="c7"
        >Notwithstanding the above, we may keep your personal data as long as
        required to comply with legal or regulatory obligations to which we are
        subject.</span
      >
    </p>
    <h3 class="c8" id="h.yc0pwzzfy4al">
      <span class="c6"
        >YOUR DUTY AND RIGHTS IN RELATION TO YOUR PERSONAL DATA</span
      >
    </h3>
    <p class="c10">
      <span class="c30">Your duty to inform us of changes<br /><br /></span
      ><span class="c12"
        >It is important that the personal data we hold about you is accurate
        and current. If you become aware of changes or inaccuracies in your
        information, you should inform us of such changes so that the
        information may be updated or corrected.</span
      >
    </p>
    <p class="c10">
      <span class="c30">Your rights in connection with personal data<br /><br /></span
      ><span class="c12"
        >You may be entitled, in accordance with applicable law, to the
        following rights to:</span
      >
    </p>
    <ul class="c22 lst-kix_in4a7m9fd50j-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Request access to your personal data. This enables you to receive a
          copy of the personal data we hold about you and to check that we are
          lawfully processing it;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Request correction and/or deletion of your personal data;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Request the restriction of the processing of your personal
          data;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Request receipt or transmission to another organization, in a
          machine-readable form, of the personal data that you have provided to
          us;</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7"
          >Object, at any time, to the processing of your personal data by us;
          or</span
        >
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">Withdraw your consent.</span>
      </li>
    </ul>
    <p class="c10">
      <span class="c7"
        >To exercise any of your rights, please contact us by using the contact
        details at the end of this policy. We may need to request specific
        personal data from you to help us verify your identity and confirm your
        right to access the personal data (or to exercise any of your other
        rights). This is another appropriate security measure to ensure, for
        example, that personal data is not disclosed to any person who has no
        right to receive it.</span
      >
    </p>
    <p class="c10">
      <span class="c7"
        >In the event that you wish to make a complaint about how we process
        your personal data, please contact us and we will endeavour to handle
        your request as soon as possible. You may lodge a complaint with a
        supervisory authority if you believe our processing of your information
        is unlawful. If you are in the EU, you can find the supervisory
        authority for your country and how to contact them in
        https://edpb.europa.eu/about-edpb/board/members_en. If you are in the
        UK, the supervisory authority is the Information Commissioner&rsquo;s
        Office, who you can contact in
        https://ico.org.uk/make-a-complaint/your-personal-information-concerns.</span
      >
    </p>
    <h3 class="c8" id="h.o1dqqhaln0v9">
      <span class="c6">DATA CONTROLLER AND CONTACT DETAILS</span>
    </h3>
    <p class="c11">
      <span class="c12"
        >The Company is the data controller for the purposes of the EU GDPR, the
        UK GDPR and other applicable data protection laws. If you have any
        questions about this notice or wish to contact the Company regarding
        your personal data or concerns you have about this notice, please
        contact (for both Metaware PTE and Metaware Co., Ltd:</span
      >
    </p>
	<br />
    <p class="c10"><span class="c7">[Contact Information]</span></p>
    <ul class="c22 lst-kix_odro3bhr51u-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7">privacy@metaware.ltd</span>
      </li>
    </ul>
    <p class="c10"><span class="c7">[DPO contact information]</span></p>
    <ul class="c22 lst-kix_mhjggt2tubz-0 start">
      <li class="c0 li-bullet-0"><span class="c7">VeraSafe</span></li>
      <li class="c0 li-bullet-0">
        <span class="c7">Anastasia Pavlou (Privacy Counsel)</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">100 M Street S.E., Suite 600</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">Washington, D.C. 20003 USA</span>
      </li>
      <li class="c0 li-bullet-0"><span class="c7">+1 (617) 398-7067</span></li>
      <li class="c0 li-bullet-0">
        <span class="c7">experts@verasafe.com</span>
      </li>
    </ul>
    <p class="c10">
      <span class="c7">[EU Representative contact information]</span>
    </p>
    <ul class="c22 lst-kix_qfnx896nul1z-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7">VeraSafe Netherlands BV</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">Keizersgracht 391 A</span>
      </li>
      <li class="c0 li-bullet-0"><span class="c7">1016 EJ Amsterdam</span></li>
      <li class="c0 li-bullet-0"><span class="c7">The Netherlands</span></li>
      <li class="c0 li-bullet-0">
        <span class="c12">contract form:</span
        ><span class="c12"
          ><a
            class="c4"
            href="https://www.google.com/url?q=https://www.verasafe.com/privacy-services/contact-article-27-representative/&amp;sa=D&amp;source=editors&amp;ust=1689217897348003&amp;usg=AOvVaw3uVLoSbqMcwtB_0xEQB5yY"
            >&nbsp;</a
          ></span
        ><span class="c27"
          ><a
            class="c4"
            href="https://www.google.com/url?q=https://www.verasafe.com/privacy-services/contact-article-27-representative/&amp;sa=D&amp;source=editors&amp;ust=1689217897348424&amp;usg=AOvVaw1anikOJeJzTlabwU6c9HeC"
            >https://www.verasafe.com/privacy-services/contact-article-27-representative/</a
          ></span
        >
      </li>
      <li class="c0 li-bullet-0"><span class="c7">+420 228 881 031.</span></li>
    </ul>
    <p class="c10">
      <span class="c7">[UK Representative contact information]</span>
    </p>
    <ul class="c22 lst-kix_ehm1robkn4n7-0 start">
      <li class="c0 li-bullet-0">
        <span class="c7">VeraSafe United Kingdom Ltd.</span>
      </li>
      <li class="c0 li-bullet-0">
        <span class="c7">37 Albert Embankment</span>
      </li>
      <li class="c0 li-bullet-0"><span class="c7">London SE1 7TL</span></li>
      <li class="c0 li-bullet-0"><span class="c7">United Kingdom</span></li>
      <li class="c0 li-bullet-0">
        <span class="c12">contract form:</span
        ><span class="c12"
          ><a
            class="c4"
            href="https://www.google.com/url?q=https://verasafe.com/public-resources/contact-data-protection-representative&amp;sa=D&amp;source=editors&amp;ust=1689217897349294&amp;usg=AOvVaw0Jro3XeMuOSzstBi5aUfBB"
            >&nbsp;</a
          ></span
        ><span class="c27"
          ><a
            class="c4"
            href="https://www.google.com/url?q=https://verasafe.com/public-resources/contact-data-protection-representative&amp;sa=D&amp;source=editors&amp;ust=1689217897349538&amp;usg=AOvVaw3WKQuDuhwc0pdpznIY0ugN"
            >https://verasafe.com/public-resources/contact-data-protection-representative</a
          ></span
        >
      </li>
      <li class="c0 li-bullet-0"><span class="c7">+44 (20) 45322003</span></li>
    </ul>
    <h3 class="c8" id="h.utbsmholvspf">
      <span class="c6">CHANGES TO THIS POLICY</span>
    </h3>
    <p class="c11">
      <span class="c12"
        >We will update this policy to reflect changes in our practices and
        services, and take appropriate measures to notify you of any significant
        changes in accordance with applicable data protection laws. When we post
        changes to this policy, we will revise the &quot;Last Updated&quot; date
        at the top of this policy.</span
      >
    </p>
    <h3 class="c8" id="h.as2v8ndcvx1v"><span class="c6">APPENDIX I</span></h3>
    <p class="c11">
      <span class="c12">Purposes for Processing USER Data</span>
    </p>
    <a id="t.4b7a3931735dbd191945424a18feb742c3b86ec5"></a><a id="t.0"></a>
    <table class="c23">
      <tr class="c13">
        <td class="c32" colspan="1" rowspan="1">
          <p class="c20"><span class="c18">Purpose of Use</span></p>
        </td>
        <td class="c3" colspan="1" rowspan="1">
          <p class="c20">
            <span class="c18">Categories of Personal Data</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c20"><span class="c18">Legal Basis for Processing</span></p>
        </td>
      </tr>
      <tr class="c1">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Providing products and services</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Identifiers, Network information, Device
              information, Usage data, Status of your consent, (when necessary)
              Information pertaining to Decentralized wallet address</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Contract</span></p>
        </td>
      </tr>
      <tr class="c21">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Identifying individual users for provision of services</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Identifiers, Device information</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Contract</span></p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Improve the quality of our service, user experiences and fix
              bugs</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Network information, Device information, Game data</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Our legitimate interests to improve our services</span
            >
          </p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Detecting and preventing illegal, unjust or unapproved use of
              service</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Payment information, Identifiers, Network information, Device
              information</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Our legitimate interests to protect our business rights and
              services</span
            >
          </p>
        </td>
      </tr>
      <tr class="c31">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Managing inquiries and requests from you</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Device information, Game data, Payment
              information, Activity data, (when necessary) Information
              pertaining to Decentralized wallet address, Customer service
              data</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c7"
              >Contract, if your request relates to your use of our
              services</span
            >
          </p>
          <p class="c2">
            <span class="c7"
              >Your consent, if you are contacting us in another context</span
            >
          </p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Providing Mintmarble community service</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Network information, Activity data, User generated
              content</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Contract</span></p>
        </td>
      </tr>
      <tr class="c31">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Management of cookie information</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Usage data</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c7"
              >Consent when the information is collected through non-essential
              cookies</span
            >
          </p>
          <p class="c2">
            <span class="c7"
              >Legitimate interest to ensure our Services function properly when
              the information is collected through essential cookies</span
            >
          </p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Planning, performing and managing the (contractual) relationship
              with you including processing the payment</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Payment information</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Contract</span></p>
        </td>
      </tr>
      <tr class="c31">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Ensuring compliance with legal obligations such as record keeping
              obligations</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Identifiers, Network information, Device
              information, Status of your consent, Game data, Payment
              information, (when necessary) Information pertaining to
              Decentralized wallet address, Customer service data</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Legal obligation</span></p>
        </td>
      </tr>
      <tr class="c31">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Solving disputes, enforce our contractual agreements and to
              establish, exercise or defend legal claims</span
            >
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Customer data, Identifiers, Network information, Device
              information, Status of your consent, Game data, Payment
              information, (when necessary) Information pertaining to
              Decentralized wallet address, Customer service data</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >Our legitimate interests to establish, exercise and defend our
              legal rights</span
            >
          </p>
        </td>
      </tr>
    </table>
    <h3 class="c8" id="h.elxkn51b5i6"><span class="c6">APPENDIX II</span></h3>
    <p class="c11">
      <span class="c12">Information Collected from third parties</span>
    </p>
    <a id="t.c04c3be8ad55088a47d5e04435168c300536b3b3"></a><a id="t.1"></a>
    <table class="c23">
      <tr class="c13">
        <td class="c32" colspan="1" rowspan="1">
          <p class="c20"><span class="c18">Third Parties</span></p>
        </td>
        <td class="c3" colspan="1" rowspan="1">
          <p class="c20"><span class="c18">Purpose of Use</span></p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c20"><span class="c18">Information Collected</span></p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Google LLC</span></p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >To set up a new account by using user&rsquo;s Google
              account</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Authentication token issued by Google</span>
          </p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c2"><span class="c12">Apple Inc.</span></p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12"
              >To set up a new account by using user&rsquo;s Apple account</span
            >
          </p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c2">
            <span class="c12">Authentication token issued by Apple</span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c17"><span class="c35"></span></p>
    <p class="c19"><span class="c35"></span></p>
    <p class="c19"><span class="c35"></span></p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.lst-kix_kiyea4n6luut-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_itp1gg4p4tc5-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s38xhp13qfdn-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_s38xhp13qfdn-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_itp1gg4p4tc5-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_itp1gg4p4tc5-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_kiyea4n6luut-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_itp1gg4p4tc5-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_itp1gg4p4tc5-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s38xhp13qfdn-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kiyea4n6luut-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_itp1gg4p4tc5-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s38xhp13qfdn-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_s38xhp13qfdn-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s38xhp13qfdn-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_itp1gg4p4tc5-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_kiyea4n6luut-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kiyea4n6luut-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_t44lp0dxyzcr-2 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-1 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-4 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-3 {
  list-style-type: none;
}
.lst-kix_itp1gg4p4tc5-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_hwkwz0r7i93u-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_itp1gg4p4tc5-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_t44lp0dxyzcr-0 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-2 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-3 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-0 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-1 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-6 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-8 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-7 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-7 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-4 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-6 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-5 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-5 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-4 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-3 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-2 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-1 {
  list-style-type: none;
}
ul.lst-kix_n8cnb5pgmkfl-0 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-5 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-6 {
  list-style-type: none;
}
.lst-kix_s38xhp13qfdn-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_ehm1robkn4n7-7 {
  list-style-type: none;
}
ul.lst-kix_s38xhp13qfdn-8 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-8 {
  list-style-type: none;
}
.lst-kix_s38xhp13qfdn-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_s38xhp13qfdn-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qfnx896nul1z-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qfnx896nul1z-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qfnx896nul1z-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_qfnx896nul1z-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qfnx896nul1z-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qfnx896nul1z-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_hwkwz0r7i93u-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_hwkwz0r7i93u-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_qfnx896nul1z-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_hwkwz0r7i93u-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_qfnx896nul1z-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_hwkwz0r7i93u-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_hwkwz0r7i93u-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_qfnx896nul1z-8 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-7 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-6 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-5 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-4 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-3 {
  list-style-type: none;
}
.lst-kix_hwkwz0r7i93u-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_kiyea4n6luut-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_qfnx896nul1z-2 {
  list-style-type: none;
}
ul.lst-kix_qfnx896nul1z-1 {
  list-style-type: none;
}
.lst-kix_kiyea4n6luut-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_qfnx896nul1z-0 {
  list-style-type: none;
}
.lst-kix_qfnx896nul1z-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_hwkwz0r7i93u-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_kiyea4n6luut-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_hwkwz0r7i93u-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_kiyea4n6luut-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_kiyea4n6luut-2 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-3 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-0 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-1 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-6 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-7 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-4 {
  list-style-type: none;
}
.lst-kix_4al1ahj1dkpg-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_kiyea4n6luut-5 {
  list-style-type: none;
}
ul.lst-kix_kiyea4n6luut-8 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-1 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-2 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-0 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-1 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-2 {
  list-style-type: none;
}
.lst-kix_4al1ahj1dkpg-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_odro3bhr51u-0 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-8 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-7 {
  list-style-type: none;
}
.lst-kix_4al1ahj1dkpg-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_4al1ahj1dkpg-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_odro3bhr51u-7 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-6 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-8 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-5 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-5 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-4 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-6 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-3 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-3 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-2 {
  list-style-type: none;
}
ul.lst-kix_odro3bhr51u-4 {
  list-style-type: none;
}
ul.lst-kix_86vsfro9kiyi-1 {
  list-style-type: none;
}
.lst-kix_in4a7m9fd50j-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_86vsfro9kiyi-0 {
  list-style-type: none;
}
.lst-kix_86vsfro9kiyi-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_in4a7m9fd50j-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_86vsfro9kiyi-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_86vsfro9kiyi-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_n8cnb5pgmkfl-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_in4a7m9fd50j-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_4al1ahj1dkpg-7 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-8 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-5 {
  list-style-type: none;
}
.lst-kix_in4a7m9fd50j-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_4al1ahj1dkpg-6 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-3 {
  list-style-type: none;
}
ul.lst-kix_4al1ahj1dkpg-4 {
  list-style-type: none;
}
.lst-kix_in4a7m9fd50j-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_t44lp0dxyzcr-6 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-5 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-8 {
  list-style-type: none;
}
ul.lst-kix_t44lp0dxyzcr-7 {
  list-style-type: none;
}
.lst-kix_n8cnb5pgmkfl-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_n8cnb5pgmkfl-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_86vsfro9kiyi-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_n8cnb5pgmkfl-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_n8cnb5pgmkfl-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mhjggt2tubz-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mhjggt2tubz-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mhjggt2tubz-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_eazc2tl81b7y-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_eazc2tl81b7y-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t44lp0dxyzcr-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_eazc2tl81b7y-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_eazc2tl81b7y-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_mhjggt2tubz-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_eazc2tl81b7y-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t44lp0dxyzcr-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mhjggt2tubz-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_itp1gg4p4tc5-7 {
  list-style-type: none;
}
ul.lst-kix_itp1gg4p4tc5-8 {
  list-style-type: none;
}
.lst-kix_eazc2tl81b7y-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t44lp0dxyzcr-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_mhjggt2tubz-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t44lp0dxyzcr-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_mhjggt2tubz-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_eazc2tl81b7y-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t44lp0dxyzcr-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_mhjggt2tubz-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_t44lp0dxyzcr-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_hwkwz0r7i93u-4 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_hwkwz0r7i93u-3 {
  list-style-type: none;
}
ul.lst-kix_hwkwz0r7i93u-2 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_hwkwz0r7i93u-1 {
  list-style-type: none;
}
ul.lst-kix_hwkwz0r7i93u-8 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_hwkwz0r7i93u-7 {
  list-style-type: none;
}
ul.lst-kix_hwkwz0r7i93u-6 {
  list-style-type: none;
}
ul.lst-kix_hwkwz0r7i93u-5 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_odro3bhr51u-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_odro3bhr51u-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_hwkwz0r7i93u-0 {
  list-style-type: none;
}
ul.lst-kix_itp1gg4p4tc5-5 {
  list-style-type: none;
}
ul.lst-kix_itp1gg4p4tc5-6 {
  list-style-type: none;
}
ul.lst-kix_itp1gg4p4tc5-3 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_itp1gg4p4tc5-4 {
  list-style-type: none;
}
.lst-kix_t44lp0dxyzcr-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_t44lp0dxyzcr-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_itp1gg4p4tc5-1 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-2 {
  list-style-type: none;
}
.lst-kix_odro3bhr51u-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_itp1gg4p4tc5-2 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-1 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-0 {
  list-style-type: none;
}
ul.lst-kix_itp1gg4p4tc5-0 {
  list-style-type: none;
}
.lst-kix_t44lp0dxyzcr-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_mhjggt2tubz-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_eazc2tl81b7y-6 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-5 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-4 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-3 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-8 {
  list-style-type: none;
}
ul.lst-kix_eazc2tl81b7y-7 {
  list-style-type: none;
}
.lst-kix_ehm1robkn4n7-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_mhjggt2tubz-2 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-1 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-0 {
  list-style-type: none;
}
.lst-kix_ehm1robkn4n7-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ehm1robkn4n7-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_mhjggt2tubz-8 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-7 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-6 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-5 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-4 {
  list-style-type: none;
}
ul.lst-kix_mhjggt2tubz-3 {
  list-style-type: none;
}
.lst-kix_ehm1robkn4n7-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_ehm1robkn4n7-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_ehm1robkn4n7-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ehm1robkn4n7-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_ehm1robkn4n7-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_ehm1robkn4n7-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_eazc2tl81b7y-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_eazc2tl81b7y-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_4al1ahj1dkpg-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_4al1ahj1dkpg-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_4al1ahj1dkpg-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_4al1ahj1dkpg-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_4al1ahj1dkpg-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_86vsfro9kiyi-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_in4a7m9fd50j-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_ehm1robkn4n7-0 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-1 {
  list-style-type: none;
}
.lst-kix_86vsfro9kiyi-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_ehm1robkn4n7-2 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-3 {
  list-style-type: none;
}
ul.lst-kix_ehm1robkn4n7-4 {
  list-style-type: none;
}
.lst-kix_86vsfro9kiyi-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_in4a7m9fd50j-3 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-4 {
  list-style-type: none;
}
.lst-kix_in4a7m9fd50j-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_in4a7m9fd50j-1 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-2 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-7 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-8 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-5 {
  list-style-type: none;
}
ul.lst-kix_in4a7m9fd50j-6 {
  list-style-type: none;
}
.lst-kix_in4a7m9fd50j-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_in4a7m9fd50j-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_odro3bhr51u-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_n8cnb5pgmkfl-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_in4a7m9fd50j-0 {
  list-style-type: none;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_86vsfro9kiyi-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_n8cnb5pgmkfl-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_n8cnb5pgmkfl-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_86vsfro9kiyi-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_n8cnb5pgmkfl-5 > li:before {
  content: "\0025a0   ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c11 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c25 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.2999999999999998;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c8 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.3;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
  margin: 15px 0;
}
.c0 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  margin-left: 36pt;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c33 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c10 {
  border-right-style: solid;
  padding-top: 0;
  border-top-width: 0;
  border-right-width: 0;
  padding-bottom: 0;
  line-height: 1.15;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0;
}
.c15 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #161519;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c9 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #161519;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c16 {
  border-right-style: solid;
  padding: 6pt 9pt 6pt 9pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c3 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c32 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #737985;
  vertical-align: middle;
  border-right-color: #cbd2d9;
  border-left-width: 0;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c36 {
  border-right-style: solid;
  padding: 4pt 4pt 4pt 4pt;
  border-bottom-color: #cbd2d9;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #cbd2d9;
  vertical-align: middle;
  border-right-color: #737985;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 150.4pt;
  border-top-color: #cbd2d9;
  border-bottom-style: solid;
}
.c14 {
  color: #1a1a1a;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: Arial;
  font-style: normal;
}
.c6 {
  color: #161519;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: Arial;
  font-style: normal;
}
.c17 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c26 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: line-through;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-family: Arial;
  font-style: normal;
}
.c7 {
  color: #161519;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: Arial;
  font-style: normal;
}
.c28 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 26pt;
  font-family: Arial;
  font-style: normal;
}
.c19 {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c35 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial;
  font-style: normal;
}
.c20 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c2 {
  padding-top: 12pt;
  padding-bottom: 0;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c27 {
  text-decoration-skip-ink: none;
  font-size: 10.5pt;
  -webkit-text-decoration-skip: none;
  color: #0075ff;
  text-decoration: underline;
}
.c23 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c29 {
  font-size: 15pt;
  color: #161519;
  font-weight: 700;
}
.c24 {
  background-color: #fff;
  max-width: 800pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c30 {
  font-size: 12pt;
  color: #1a1a1a;
  font-weight: 700;
}
.c18 {
  color: #737985;
  font-size: 10.5pt;
}
.c12 {
  color: #161519;
  font-size: 10.5pt;
}
.c4 {
  color: inherit;
  text-decoration: inherit;
}
.c22 {
  padding: 0;
  margin: 0;
}
.c1 {
  height: 60pt;
}
.c5 {
  height: 44.2pt;
}
.c21 {
  height: 28.5pt;
}
.c37 {
  margin-left: 36pt;
}
.c34 {
  height: 11pt;
}
.c31 {
  height: 75.8pt;
}
.c13 {
  height: 24.8pt;
}
.title {
  padding-top: 0;
  color: #000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0;
  color: #666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
p {
  margin: 0;
  color: #000;
  font-size: 11pt;
  font-family: Arial;
}
h1 {
  padding-top: 20pt;
  color: #000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Arial;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>