<template>
  <div id="wrap">
    <!-- <div class="video-load" v-show="!videoOn">
      <video src="@/assets/video/load-video.mp4" autoplay muted/>
    </div> -->
    <div>
      <Header :initIsBack="isBack" />
      <!-- <main> -->
      <section class="sec type1" id="KeyVisual">
        <div class="container">
            <div class="sec-inner">
              <img
                class="main-text-img"
                src="@/assets/images/main-text-img.png"
                alt=""
              />
              <p class="sec1-text">
                MINT MARBLE enables content created in the virtual world and the real world to be capitalized and expanded into added value in all lives on and off.
              </p>
              <span class="empty"></span>
              <div class="btn-box">
                <button class="type1-btn-pc" @click="modalOpen()">
                  <img src="@/assets/images/play-video-btn.png" alt="">
                </button>
              </div>
            </div>
        </div>
      </section>
      <section class="sec type3">
        <div class="container pc">
          <div class="sec-inner">
            <h2 class="title">Overview</h2>
            <div class="text-box" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" >
              <p class="top-text">MINT MARBLE <br /> METAVERSE</p>
              <p class="mid-text">Introduction to Space Concept beyond the Timeline</p>
              <p class="bottom-text">MINT MARBLE is a revenue-generating social networking service <br />where individual users and businesses can share and sell content <br />such as photos, videos, and games. It is a business platform that <br />can integrate various business fields including offline product <br />sales, online broadcastings, and advertisements.</p>
              <button class="whitepaper-btn" @click="goDocs()">
                <span class="text">White Paper</span>
              </button>
            </div>
          </div>
        </div>
        <div class="mb-container">
          <h2 class="title">Overview</h2>
          <div class="top">
            <p class="top-text">MINT MARBLE <br /> METAVERSE</p>
            <p class="mid-text">Introduction to Space Concept <br /> beyond the Timeline</p>
          </div>
          <span class="empty"></span>
          <div class="bottom">
            <p class="bottom-text">MINT MARBLE is a revenue-generating social networking service where individual users and businesses can share and sell content such as photos, videos, and games. It is a business platform that can integrate various business fields including offline product sales, online broadcastings, and advertisements.</p>
          </div>
          <button class="whitepaper-btn" @click="goDocs()">
            <span class="text">White Paper</span>
          </button>
        </div>
      </section>
      <section class="sec video-gallery">
        <div class="container">
          <div class="sec-inner">
            <div class="text-box" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
              <p class="top-text">Video Gallery</p>
              <p class="mid-text">MINT MARBLE and More</p>
              <p class="bottom-text">Click to view Mint marble Play video</p>
              <button class="play-video-btn" @click="modalOpen()">
              </button>
            </div>
            <div class="img-box" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
              <img class="vg-img" src="@/assets/images/video-img.png" alt="" >
              <button class="vg-play-btn" @click="modalOpen()"></button>
            </div>
          </div>
        </div>
        <div class="mb-gallery">
          <p class="top-text">Video Gallery</p>
          <p class="mid-text">MINT MARBLE and More</p>
          <div class="img-box" data-aos="flip-down" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000" >
            <img class="vg-img" src="@/assets/images/video-img.png" alt="" @click="modalOpen()" >
          </div>
          <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
            <p class="bottom-text"  >Click to view Mint marble Play video</p>
            <button class="play-video-btn" @click="modalOpen()">
              <img src="@/assets/images/play-video-btn-m.png" alt="">
            </button>
          </div>
        </div>
      </section>
      <section class="sec type4" id="Features">
        <div class="container">
          <div class="sec-inner">
            <h2 class="title">Game Feature</h2>
            <h3 class="sub-title">
              Create your own avatar with MINT MARBLE and experience anywhere
              metaverse! Customize your own unique avatar and share your daily
              life with friends anytime, anywhere.
            </h3>
            <div class="pc">
              <div class="content-box no1">
                <div class="left" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="50"  >
                  <p class="top-text">Create it</p>
                  <p class="mid-text">Create Your Brand</p>
                  <p class="bottom-text">Sell, trade, and buy MINT MARBLE assets on the NFT marketplace and monetize your creations. <br /> All transactions go to you, the creator.</p>
                </div>
                <div class="right" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="50"  >
                  <img src="@/assets/images/type4-img1.png" alt="">
                </div>
              </div>
              <div class="content-box no2">
                <div class="right" data-aos="fade-up" data-aos-duration="1600"  data-aos-delay="50"  >
                  <img src="@/assets/images/type4-img2.png" alt="">
                </div>
                <div class="left" data-aos="fade-left" data-aos-duration="1600"  data-aos-delay="50" >
                  <p class="top-text">Customize it</p>
                  <p class="mid-text">Customize your unique avatar</p>
                  <p class="bottom-text">You can make another me in MINT MARBLE through detailed customization with various facial shapes, hairstyles, costumes and accessories.</p>
                </div>
              </div>
              <div class="content-box no3">
                <div class="left" data-aos="fade-right" data-aos-duration="1700"  data-aos-delay="50" >
                  <p class="top-text">Share it</p>
                  <p class="mid-text">Share on In-game SNS</p>
                  <p class="bottom-text">MINT MARBLE integrates various social media into a single platform, shows synchronized posts, and provides an social media function.</p>
                </div>
                <div class="right" data-aos="fade-up" data-aos-duration="1700"  data-aos-delay="50" >
                  <img src="@/assets/images/type4-img3.png" alt="">
                </div>
              </div>
            </div>
            <div class="mb" data-aos="zoom-in-up" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
              <div class="swiper-box3">
                <swiper :options="swiperOptionType3" class="mySwiper">
                  <swiper-slide
                    class="bg-area"
                    v-for="(data, i) in type4List"
                    :key="i"
                  >
                    <p class="top">{{data.text1}}</p>
                    <p class="mid">{{data.text2}}</p>
                    <p class="btm">{{data.text3}}</p>
                    <img :src="data.bgUrl" alt="" />
                  </swiper-slide>
                </swiper>
                <div class="swiper-pagination3" slot="pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sec type5" id="Myroom">
        <div class="container">
          <div class="sec-inner">
            <h2 class="title">My Room</h2>
            <p class="text">
              All users of MINT MARBLE are provided with their own space <br /> that
              can be decorated at will. Through the NFT market, <br /> users can
              purchase wallpaper, furniture, and accessories <br /> that they have made
              themselves. Invite your friends to <br /> your own space and enjoy the
              party together!
            </p>
            <div class="swiper-box pc">
              <swiper :options="swiperOptionType5" class="mySwiper">
                <swiper-slide
                  ><img
                    class="type5-pic1"
                    src="@/assets/images/swiper-img1-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic2"
                    src="@/assets/images/swiper-img2-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic3"
                    src="@/assets/images/swiper-img3-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic4"
                    src="@/assets/images/swiper-img4-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic5"
                    src="@/assets/images/swiper-img5-b.png"
                    alt=""
                /></swiper-slide>
              </swiper>
              <div class="swiper-pagination5" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </div>
            <div class="swiper-box mb" data-aos="zoom-in-down" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
              <swiper :options="swiperOptionType5M" class="mySwiper">
                <swiper-slide
                  ><img
                    class="type5-pic1"
                    src="@/assets/images/swiper-img1-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic2"
                    src="@/assets/images/swiper-img2-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic3"
                    src="@/assets/images/swiper-img3-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic4"
                    src="@/assets/images/swiper-img4-b.png"
                    alt=""
                /></swiper-slide>
                <swiper-slide
                  ><img
                    class="type5-pic5"
                    src="@/assets/images/swiper-img5-b.png"
                    alt=""
                /></swiper-slide>
              </swiper>
              <div class="swiper-pagination5M" slot="pagination"></div>
            </div>
            <div class="item-box pc">
              <h3 class="sub-title">Myroom Items</h3>
              <div class="item-list row" data-aos="zoom-out-right" data-aos-delay="60" data-aos-duration="1400"  >
                <img
                  class="col-3"
                  src="@/assets/images/roon-item1.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item2.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item3.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item4.png"
                  alt=""
                />
              </div>
              <div class="item-list no2 row" data-aos="zoom-out-left" data-aos-delay="80" data-aos-duration="1600">
                <img
                  class="col-3"
                  src="@/assets/images/roon-item5.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item6.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item7.png"
                  alt=""
                />
                <img
                  class="col-3"
                  src="@/assets/images/roon-item8.png"
                  alt=""
                />
              </div>
            </div>
            <div class="item-box mb">
              <h3 class="sub-title">Myroom Items</h3>
              <img src="@/assets/images/type5-itembox-m.png" alt="" data-aos="zoom-in-up" data-aos-delay="60" data-aos-duration="1400" />
            </div>
          </div>
        </div>
      </section>
      <section class="group1">
        <section class="sec type6" id="Market">
          <div class="container">
            <div class="sec-inner">
              <h2 class="title">NFT MARKET</h2>
              <h3 class="sub-title">
                All users can register and sell various objects such as clothes,  <br />
                accessories, and furniture made by themselves in the NFT  <br />
                market. A special collection area is provided to users who have  <br />
                gained popularity in the NFT market.
              </h3>
              <div data-aos="zoom-out-up" data-aos-delay="80" data-aos-duration="1600" >
                <img class="content" src="@/assets/images/type6-img-v2.png" alt=""/>
              </div>
            </div>
          </div>
        </section>
        <section class="sec type7" id="Socialspot">
          <div class="container">
            <div class="sec-inner">
              <h2 class="title">Social Spot</h2>
              <h3 class="sub-title">Make memories with your friends <br /> in the virtual world.</h3>
              <p class="text">
                MINT MARBLE is a social networking system that introduces the concept of <br />
                space by shifting away from the timeline system of other SNS. It provides <br />
                functions that allow various individuals to interact with each other through <br />
                personal and emotional expressions.
              </p>
              <div class="swiper-box pc">
                <swiper :options="swiperOptionType7" class="mySwiper">
                  <swiper-slide
                    ><img
                      class="type7-pic1"
                      src="@/assets/images/swiper7-img1.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic2"
                      src="@/assets/images/swiper7-img2.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic3"
                      src="@/assets/images/swiper7-img3.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic4"
                      src="@/assets/images/swiper7-img4.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic5"
                      src="@/assets/images/swiper7-img5.png"
                      alt=""
                  /></swiper-slide>
                </swiper>
                <div class="swiper-pagination7" slot="pagination"></div>
              </div>
              <div class="swiper-box mb" data-aos="zoom-in-down" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1000">
                <swiper :options="swiperOptionType7M" class="mySwiper">
                  <swiper-slide
                    ><img
                      class="type7-pic1"
                      src="@/assets/images/swiper7-img1.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic2"
                      src="@/assets/images/swiper7-img2.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic3"
                      src="@/assets/images/swiper7-img3.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic4"
                      src="@/assets/images/swiper7-img4.png"
                      alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img
                      class="type7-pic5"
                      src="@/assets/images/swiper7-img5.png"
                      alt=""
                  /></swiper-slide>
                </swiper>
                <div class="swiper-pagination7M" slot="pagination"></div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="group2" >
        <section class="sec type8">
          <div class="container">
            <div class="sec-inner no1" >
              <h2 class="title token">Token</h2>
              <h3 class="sub-title token">MINT MARBLE Token : <br /> MIM</h3>
              <h4 class="h4-title">
                MIM is a token issued based on the Klaytn <br />
                Mainnet used within MINT MARBLE.
              </h4>
              <div>
                <img class="token-img" src="@/assets/images/token-img.png" alt=""  data-aos="zoom-out-up" data-aos-delay="70" data-aos-duration="1400"/>
              </div>
              <div class="text-area" data-aos="zoom-out-down" data-aos-delay="50" data-aos-duration="1200">
                <p>
                  The MIM holder can participate in MINT MARBLE’s ecosystem,
                  contributing to the ecosystem <br />
                  through creative activities and governance participation and
                  receiving MIM as rewards.
                </p>
                <p>
                  The MIM Token is the access key to participating in Meta-Place
                  provided by MINT MARBLE and the <br />
                  key currency to use various utility features on the MINT MARBLE
                  platform to be launched soon.
                </p>
                <p>
                  MIM has an additional utility feature closest to NFT’s use case,
                  an important component that <br />
                  maintains the MINT MARBLE ecosystem, which aims to improve the
                  value and functionality of <br />NFT in the long run.
                </p>
              </div>
            </div>
            <!-- <div class="sec-inner no2">
              <h2 class="title">
                <img src="@/assets/images/type8-title.png" alt="" />
              </h2>
              <h3 class="sub-title">
                <img src="@/assets/images/type8-subtitle.png" alt="" />
              </h3>
              <img class="top" src="@/assets/images/type8-top.png" alt="" />
              <img class="bottom" src="@/assets/images/type8-bottom.png" alt="" />
            </div> -->
          </div>
        </section>
        <section class="sec milestone">
          <div class="container">
            <div class="sec-inner no1 pc">
              <h2 class="title mile">Milestone</h2>
              <div class="content-box row">
                <div class="col-4" v-for="(data, i) in mileData" :key="i"  data-aos="zoom-out-up" data-aos-delay="70" data-aos-duration="1400">
                  <img :src="data.imgUrl" alt=""/>
                </div>
              </div>
            </div>
            <div class="sec-inner no1 mb">
              <h2 class="title mile">Milestone</h2>
              <div class="content-box">
                <swiper :options="swiperOptionTypeMile" class="mySwiper">
                  <swiper-slide
                    ><img src="@/assets/images/mile1-m2.png" alt=""
                  /></swiper-slide>
                  <swiper-slide
                    ><img src="@/assets/images/mile2-m2.png" alt=""
                  /></swiper-slide>
                </swiper>
                <div class="swiper-paginationMile" slot="pagination"></div>
              </div>
            </div>
            <!-- <div class="sec-inner no2">
                      <h2 class="title team"><img src="@/assets/images/team-title.png" alt=""></h2>
                      <div class="content-box row">
                          <div class="col-6 col-lg-3" :class="'box' + (i + 1)"  v-for="(data,i) in teamData" :key="i">
                              <a :href="data.linkedIn" target="_blank"><img class="person" :src="data.imgUrl"></a>
                              <img class="pos" :class="'no' + (i + 1)" :src="data.position">  
                              <p class="text" v-html="data.text1"></p>
                              <p class="text" v-html="data.text2"></p>
                              <p class="text" v-html="data.text3"></p>
                              <p class="text" v-html="data.text4"></p>
                              <p class="text" v-html="data.text5"></p>
                          </div>
                      </div>
                  </div> -->
          </div>
        </section>
      </section>
      <section class="group3">
        <section class="sec type2">
          <div class="container">
            <div class="sec-inner">
              <h2 class="title">Mint marble News</h2>
              <div class="news-box row pc">
                <a
                  class="news-card col-lg-4 col-md-6 col-sm-12"
                  v-for="(data, i) in newsDataList"
                  :href="data.link"
                  :key="i"
                  target="_blank"
                >
                  <div class="news-img-area">
                    <img
                      class="news-img"
                      :src="cdnUrl + data.pcFilename"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <h3 class="small-title">{{ data.name }}</h3>
                    <p class="info">{{ data.desc }}</p>
                    <p class="date">{{ getNewsDate(data.viewDate) }}</p>
                  </div>
                </a>
              </div>
              <div class="news-box mb">
                <div class="swiper-box2">
                  <swiper :options="swiperOptionType2" class="mySwiper">
                    <swiper-slide
                      class="news-box"
                      v-for="(data, i) in newsDataList"
                      :key="i"
                    >
                      <a class="news-card" :href="data.link" target="_blank">
                        <div class="news-img-area">
                          <img
                            class="news-img"
                            :src="cdnUrl + data.mfilename"
                            alt=""
                          />
                        </div>
                        <div class="text">
                          <h3 class="small-title">{{ data.name }}</h3>
                          <p class="info">{{ data.desc }}</p>
                          <p class="date">{{ getNewsDate(data.viewDate) }}</p>
                        </div>
                      </a>
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-pagination2" slot="pagination2"></div>
                </div>
              </div>
              <div class="news-more">
                <a
                  class="more-btn"
                  href="https://medium.com/@mintmarble"
                  target="_blank"
                  >More</a
                >
              </div>
            </div>
          </div>
        </section>
        <section class="sec type9">
          <div class="container top">
            <div class="sec-inner">
              <h2 class="title">Partners</h2>
              <img
                class="partner"
                src="@/assets/images/type9-partner2.png"
                alt=""
                data-aos="fade-in" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1100"
              />
              <p class="email-title">
                <img src="@/assets/images/email-title.png" alt="" />
              </p>
              <div class="pc">
                <div class="before" v-show="submitComplete === false">
                  <div class="email-box">
                    <div class="left">
                      <input
                        class="email-area"
                        type="email"
                        name="email"
                        id="email"
                        v-model="subscribeEmail"
                        placeholder="Enter Your email address"
                        required
                        @input="checkValidateEmail"
                      />
                    </div>
                    <div class="right" @click="submitSign()">
                      <label for="email"
                        ><img
                          class="sign-up"
                          :class="[
                            { valid: isValidEmail && isSign },
                            { on: isValidEmail && isSign },
                          ]"
                          src="@/assets/images/sign-up.png"
                          alt=""
                      /></label>
                    </div>
                  </div>
                  <div class="text-box">
                    <p class="text-area">
                      <i @click="signCheck()" :class="{ on: isSign === true }"></i
                      ><span @click="signCheck()"
                        >Yes, I agree to receive email communications from MINT
                        MARBLE.</span
                      >
                    </p>
                  </div>
                </div>
                
              </div>
              <!-- /pc/ -->
              <div class="mb">
                <div class="before" v-show="submitComplete === false">
                  <input
                    class="email-area"
                    type="email"
                    name="email"
                    id="email"
                    v-model="subscribeEmail"
                    placeholder="Enter Your email address"
                    required
                    @input="checkValidateEmail"
                  />
                  <div class="text-box">
                    <p class="text-area">
                      <i @click="signCheck()" :class="{ on: isSign === true }"></i
                      ><span @click="signCheck()"
                        >Yes, I agree to receive email communications from MINT
                        MARBLE.</span
                      >
                    </p>
                  </div>
                  <label class="label" for="email" @click="submitSign()"
                    ><img
                      class="sign-up"
                      :class="[
                        { valid: isValidEmail && isSign },
                        { on: isValidEmail && isSign },
                      ]"
                      src="@/assets/images/sign-up.png"
                      alt=""
                  /></label>
                </div>
              </div>
              <div class="sns-box">
                <div class="container row">
                  <div class="col-12 text-line">
                      <div class="icon-area pc" >
                          <a class="linktree" href="https://linktr.ee/mintmarble" target="_black"></a>
                          <a class="discord" href="https://discord.com/invite/8sRAVzsE2Y" target="_black"></a>
                          <a class="twitter" href="https://twitter.com/mintmarble_" target="_black"></a>
                          <a class="direct" href="https://t.me/mintmarble_social" target="_black"></a>
                          <a class="reddit" href="https://www.reddit.com/r/mintmarble" target="_black"></a>
                          <a class="sns3" href="https://medium.com/@mintmarble" target="_black"></a>
                      </div>
                      <div class="icon-area mb">
                          <div class="top" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1300">
                              <a class="linktree" href="https://linktr.ee/mintmarble" target="_blank"></a>
                              <a class="discord" href="https://discord.com/invite/8sRAVzsE2Y" target="_blank"></a>
                              <a class="twitter" href="https://twitter.com/mintmarble_" target="_blank"></a>
                              <a class="direct" href="https://t.me/mintmarble_social" target="_blank"></a>
                              <a class="reddit" href="https://www.reddit.com/r/mintmarble" target="_black"></a>
                          </div>
                          <div class="end" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="50" data-aos-duration="1500">
                              <a class="sns3" href="https://medium.com/@mintmarble" target="_blank"></a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Footer />
        </section>
      </section>
      <!-- /video/ -->
      <div id="Pbox" class="player" v-show="isPlay === true">
          <a class="close-btn" @click="modalClose()"></a>
        <div class="play-inner">
          <div class="video-wrap">
            <!-- <iframe width="563px" height="315px" id="video" src="https://www.youtube.com/embed/Q1bMgkYSLgY?loop=1"> </iframe> -->
            <iframe
              id="ytplay"
              title="YouTube video player"
              frameborder="0"
              width="100%"
              height="auto"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <!-- /end video/ -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import dayjs from "dayjs";
// import axios from 'axios'

export default {
  name: "MintMarble",
  components: {
    Header,
    Footer,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      isPlay: false,
      videonOn: false,
      isBack: false,
      subscribeEmail: "",
      isSign: false,
      isValidEmail: false,
      submitComplete: false,

      swiperOptionType2: {
        initialSlide: 0, //시작 index
        slidesPerView: "1", //한 화면에 보여질 슬라이드 수
        spaceBetween: 0, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },

        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        // loop: true,
        speed: 500,
        autoHeight: false,
        
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 300,
          stretch: 0,
          modifier: 0,
          slideShadows: false,
        },
      },
      swiperOptionType3: {
        initialSlide: 0, //시작 index
        slidesPerView: "1", //한 화면에 보여질 슬라이드 수
        spaceBetween: 0, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination3",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        // loop: true,
        speed: 500,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 500,
          stretch: 0,
          modifier: 0,
          slideShadows: false,
        },
      },
      swiperOptionType5: {
        initialSlide: 0, //시작 index
        slidesPerView: "1.90", //한 화면에 보여질 슬라이드 수
        spaceBetween: 250, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination5",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 350,
          stretch: 100,
          modifier: 1,
          slideShadows: false,
        },
      },
      swiperOptionType5M: {
        initialSlide: 2, //시작 index
        slidesPerView: "1", //한 화면에 보여질 슬라이드 수
        spaceBetween: 50, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination5M",
          clickable: true,
        },
        autoplay: {
          // delay: 2000,
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        // speed: 500,
        speed: 500,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 0,
          stretch: 0,
          modifier: 1,
          slideShadows: false,
        },
      },
      swiperOptionType7: {
        initialSlide: 0, //시작 index
        slidesPerView: "1.997", //한 화면에 보여질 슬라이드 수
        spaceBetween: 100, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination7",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 500,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 300,
          stretch: 100,
          modifier: 1,
          slideShadows: false,
        },
      },
      swiperOptionType7M: {
        initialSlide: 0, //시작 index
        slidesPerView: "1", //한 화면에 보여질 슬라이드 수
        spaceBetween: 50, //슬라이드 간 간격
        pagination: {
          el: ".swiper-pagination7M",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 500,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 0,
          stretch: 0,
          modifier: 1,
          slideShadows: false,
        },
      },
      swiperOptionTypeMile: {
        initialSlide: 0, //시작 index
        slidesPerView: "1", //한 화면에 보여질 슬라이드 수
        spaceBetween: 20, //슬라이드 간 간격
        pagination: {
          el: ".swiper-paginationMile",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        // loop: true,
        speed: 500,
        autoHeight: false,
        centeredSlides: true, //active된 슬라이드 가운데 위치
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          depth: 500,
          stretch: 0,
          modifier: 0,
          slideShadows: false,
        },
      },
      type4List: [
        {
          bgUrl: require("@/assets/images/type4-content1-m.png"),
          text1: "Create it",
          text2: "Create Your Brand",
          text3:
            "Sell, trade, and buy MINT MARBLE assets on the NFT marketplace and monetize your creations. All transactions go to you, the creator.",
        },
        {
          bgUrl: require("@/assets/images/type4-content2-m.png"),
          text1: "Customize it",
          text2: "Customize your unique avatar",
          text3:
            "You can make another me in MINT MARBLE through detailed customization with various facial shapes, hairstyles, costumes and accessories",
        },
        {
          bgUrl: require("@/assets/images/type4-content3-m.png"),
          text1: "Share it",
          text2: "Share on In-game SNS",
          text3:
            "MINT MARBLE integrates various social media into a single platform, shows synchronized posts, and provides an social media function.",
        },
      ],
      mileData: [
        {
          imgUrl: require("@/assets/images/mile1-v2.png"),
        },
        {
          imgUrl: require("@/assets/images/mile2-v2.png"),
        },
        {
          imgUrl: require("@/assets/images/mile3-v2.png"),
        },
        {
          imgUrl: require("@/assets/images/mile4-v2.png"),
        },
        {
          imgUrl: require("@/assets/images/mile5-v2.png"),
        },
        {
          imgUrl: require("@/assets/images/mile6-v2.png"),
        },
      ],
      teamData: [
        {
          linkedIn: "https://www.linkedin.com/in/mark-chang-512636241/",
          imgUrl: require("@/assets/images/team1.png"),
          position: require("@/assets/images/team-text1.png"),
          text1: "CEO at Metaware",
          text2: "CEO at Magnis",
          text3: "Head of Publishing at <br> PATI Games",
          text4: "Business Development <br> Manager at Neowiz",
        },
        {
          linkedIn:
            "https://www.linkedin.com/in/%EB%B2%94%EC%84%9D-%EA%B0%95-21947b241/",
          imgUrl: require("@/assets/images/team2.png"),
          position: require("@/assets/images/team-text2.png"),
          text1: "CTO at Metaware",
          text2: "Lead Developmer at <br> Krafton Games",
          text3: "Head of Development at <br> Move Interactive",
          text4: "Head of Development at <br> Appisto Games",
          text5: "Developer at WEMADE",
        },
        {
          linkedIn: "https://www.linkedin.com/in/bugi-hwang-275622139/",
          imgUrl: require("@/assets/images/team3.png"),
          position: require("@/assets/images/team-text3.png"),
          text1: "CDO at Metaware",
          text2: "Head of Design at <br> Gala Lab Corp",
          text3: "Project Director at <br> PATI Games",
          text4: "CEO & Project Director at <br> Appisto Games",
          text5: "Project Director & <br> Art Director at NEOWIZ",
        },
        {
          linkedIn: "https://www.linkedin.com/in/darkart17/",
          imgUrl: require("@/assets/images/team4.png"),
          position: require("@/assets/images/team-text4.png"),
          text1: "Senior Developer at Metaware",
          text2: "CEO at Creamsoft Games",
          text3: "Director at DgFrog",
          text4: "Director at <br> Grigon Entertainment",
          text5: "Manager at <br> Media Bluestone",
        },
      ],
      newsDataList: [],
      show:false,
    };
  },
  created() {
    this.getNewsList();
    // this.fetchData();
  },
  async mounted() {
    await this.showAni();
    var that = this;
    setTimeout(() => {
      if (that.$route.params.section) {
        that.moveSection(that.$route.params.section);
      } else {
        // window.scroll(0,0);
      }
    }, 500);
    window.addEventListener("scroll", this.headerBack);

    this.videoOn = false;
    setTimeout(() => {
      this.videoOn = true;
    }, 3800);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.headerBack);
  },
  methods: {
    //video //
    modalOpen() {
      const _iframe = document.getElementById("ytplay") ;
      // _iframe.src = "https://www.youtube.com/embed/Q1bMgkYSLgY?autoplay=1";
      _iframe.src = "https://www.youtube.com/embed/r6NNP0eGarA?autoplay=1";
      const body = document.querySelector('body');
      body.style.overflowY = 'hidden';
      // _iframe.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

      this.isPlay = true;
    },
    modalClose() {
      this.isPlay = false;
      const _iframe = document.getElementById("ytplay");
      // _iframe.contentWindow?.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      _iframe.src = "about:blank";
      const body = document.querySelector('body');
      body.style.overflowY = 'scroll';
    },
    // /end - video/
    showAni(){
      this.show =true
    },
    headerBack() {
      if (document.documentElement.scrollTop > 0) {
        this.isBack = true;
      } else {
        this.isBack = false;
      }
    },
    moveSection(sectionName) {
      setTimeout(() => {
        var sectionScrollTop = document.getElementById(sectionName).offsetTop;
        // console.log(sectionName + ' : ' + sectionScrollTop);
        window.scroll(0, sectionScrollTop);
      }, 100);
    },
    signCheck() {
      this.isSign = !this.isSign;
    },
    checkValidateEmail() {
      this.isValidEmail = false;

      let filter =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      if (this.subscribeEmail === "") {
        this.isValidEmail = false;
      } else if (!filter.test(this.subscribeEmail)) {
        this.isValidEmail = false;
      } else {
        this.isValidEmail = true;
      }
    },
    submitSign() {
      if (this.isSign && this.isValidEmail) {
        this.getClientIP(
          (ip) => {
            var param = {
              email: this.subscribeEmail,
              state: 1,
              ip: ip,
            };
            // console.log(param);
            this.$Axios
              .post(
                `${this.subscribe_host}/email/subscription`,
                param,
                this.headers
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                  this.submitComplete = true;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    getNewsList() {
      var param = {};
      this.$Axios
        .post(`${this.host}/media`, param, this.headers)
        .then(async (res) => {
          // console.log(res.data)
          if (res.data.returnCode === 0) {
            this.newsDataList = res.data.list;
            // res.data.list.forEach((data, i) => {
            //     data.imgUrl = cdnUrl + (this.isPc() ? data.pcFilename : data.mfilename);
            //     data.date = dayjs(data.createDate).format("MMM DD, YYYY");
            //     if (i < 6)
            //         this.newsDataList.push(data);
            // });
          }
        })
        .catch((error) => {
          this.error(error);
        });
    },
    getNewsDate(date) {
      if (date !== null) return dayjs(date).format("MMM DD, YYYY");
      return "";
    },
    isPc() {
      const ua = window.navigator.userAgent.toString().toLowerCase() || "";
      if (
        ua.match(/linux|windows (nt|98)|macintosh|cros/) &&
        !ua.match(
          /android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/
        )
      ) {
        return true;
      }
      return false;
    },
    goDocs(){
      window.open('https://docs.mintmarble.com/')
    },
    btnText(){
      console.log(123)
    },
  },
};
</script>

<style scoped>
</style>
