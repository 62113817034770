<template>
    <div class="index">
        <div class="header" :class="[{'back' : initIsBack},{'show' : initIsShow}]">
            <div class="container">
                <a @click="moveSection('KeyVisual')" class="logo"></a>
                <div class="menu">
                    <ul class="link-list">
                        <li class="link-item l1"><a @click="moveSection('Features')">Features</a></li>
                        <li class="link-item l2"><a @click="moveSection('Myroom')">My Room</a></li>
                        <li class="link-item l3"><a @click="moveSection('Market')">Market</a></li>
                        <li class="link-item l4"><a @click="moveSection('Socialspot')">Social Spot</a></li>
                        <li class="link-item l5"><a href="https://docs.mintmarble.com/" target="_blank">Docs</a></li>
                    </ul>
                </div>
                <!-- <div class="">
                    <div class="menu df">
                        <div class="dropdown-language">
                            <div class="select">
                                <input class="dropdown" type="checkbox" id="currLang" name="currLang" v-model="foldLang"/>
                                <label for="currLang" class="for-dropdown">
                                    <p><i class="icon-earth"></i> {{currentLanguage}}</p>
                                </label>
                                <div class="section-dropdown">
                                    <p v-for="(item, index) in langs" :key="index" @click="changeLanguage(item)" :class="{'active' : $i18n.locale === item.code}">{{ item.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div id="mb-header-wrap">
            <div class="mb-header" :class="[{'back' : initIsBack},{'white' : foldLang2 ===true }]">
                <div class="mb-header-inner">
                    <a @click="moveSection('KeyVisual')" class="logo mb" :class="{'on': foldLang2}"></a>
                    <input type="checkbox" id="menuicon" v-model="foldLang2" @click="scrollLock()">
                    <label for="menuicon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    <div class="mb-list">
                        <!-- <a @click="moveSection('KeyVisual')" class="logo mb"></a> -->
                        <div>
                            <ul>
                                <li><a @click="moveSection('KeyVisual')">Home</a></li>
                                <li><a @click="moveSection('Features')">Features</a></li>
                                <li><a @click="moveSection('Myroom')">My Room</a></li>
                                <li><a @click="moveSection('Market')">Market</a></li>
                                <li><a @click="moveSection('Socialspot')">Social Spot</a></li>
                                <li><a href="https://docs.mintmarble.com/" target="_blank">Docs</a></li>
                                <!-- <li>
                                    <div class="dropdown-language mb">
                                        <div class="select">
                                            <input class="dropdown" type="checkbox" id="currLang2" name="currLang2" v-model="foldLang" />
                                            <label class="for-dropdown" for="currLang2">
                                            <label for="currLang"  class="for-dropdown">
                                                <p><i class="icon-earth mb"></i>{{currentLanguage}} </p>
                                            </label>
                                            <div class="section-dropdown mb-section-dropdown">
                                                <p v-for="(item, index) in langs" :key="index" @click="changeLanguage(item)" :class="{'active' : item.title === currentLanguage}">{{ item.title }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props:{
        initIsBack: {
            type: Boolean,
            default: false
        },
        initIsShow: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            foldLang: false,
            foldLang2: false,
            currentLanguage: 'ENG',
            langs: [
                {
                    title:'KOR',
                    code:'KO'
                },
                {
                    title:'ENG',
                    code:'EN'
                },
            ]
        }
    },
    created() {

    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        scrollLock(){
            console.log(this.foldLang2)
            if(this.foldLang2 === false){
                const body = document.querySelector('body');
                body.style.overflowY = 'hidden';
            } else {
                const body = document.querySelector('body');
                body.style.overflowY = 'scroll';
            }
        },
        goTop(){
            window.scrollTo(0,0)
        },
        changeLanguage(item){
            if(item.code === this.$i18n.locale){
                return;
                } else {
                this.$i18n.locale = item.code
                this.foldLang =false;
                this.foldLang2 =false;
                this.currentLanguage = item.title;
            }
        },
        moveSection(sectionName){
            this.foldLang =false;
            this.foldLang2 =false;
            const body = document.querySelector('body');
                body.style.overflowY = 'scroll';
            setTimeout(()=>{
                var sectionScrollTop = document.getElementById(sectionName).offsetTop;
                // console.log(sectionName + " : " + sectionScrollTop);
                window.scroll(0, sectionScrollTop);
            }, 100);
        }
    },
}

</script>